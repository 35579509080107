import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ILocation } from 'src/@types/location';
// _mock
// types
// assets
import { countries } from 'src/assets/data';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { updateLocation } from 'src/redux/slices/location';
import * as Yup from 'yup';
import { ICustomer } from '../../../@types/customer';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: (data: any) => void;
  currentLocation?: ILocation;
};

export default function LocationQuickEditForm({
  currentLocation,
  open,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  console.log('USING', currentLocation);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required(
      `${translate('locationCreatePage.requirements.firstName')}`
    ),
    phone: Yup.string().required(
      `${translate('locationCreatePage.requirements.phone')}`
    ),
    address: Yup.string().required(
      `${translate('locationCreatePage.requirements.address')}`
    ),
    city: Yup.string().required(
      `${translate('locationCreatePage.requirements.city')}`
    ),
    country: Yup.string().required(
      `${translate('locationCreatePage.requirements.country')}`
    ),
    zip: Yup.string().required(
      `${translate('locationCreatePage.requirements.zipcode')}`
    ),
    province: Yup.string().required(
      `${translate('locationCreatePage.requirements.province')}`
    ),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentLocation?.name || '',
      phone: currentLocation?.phone || '',
      address: currentLocation?.address?.address1 || '',
      city: currentLocation?.address?.city || '',
      country: currentLocation?.address?.country || '',
      zip: currentLocation?.address?.zip || '',
      province: currentLocation?.address?.province || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation]
  );

  const methods = useForm<ICustomer>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      console.log(data);

      const rs = await updateLocation({
        id: currentLocation?.id as string,
        name: data.name,
        phone: data.phone,
        address: {
          address1: data.address,
          city: data.city,
          country: data.country,
          zip: data.zip,
          province: data.province,
          external_id: currentLocation?.address?.external_id as string,
        },
      } as any);

      onClose(rs);
      enqueueSnackbar(`${translate('locationCreatePage.updateSuccess')}`);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('locationsPage.quickEdit')}`}</DialogTitle>

        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            {`${translate('locationCreatePage.currentlyEditing')} ${
              currentLocation?.name
            }`}
          </Alert>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('locationCreatePage.fields.name')}`}
            />

            <RHFTextField
              name="phone"
              label={`${translate('locationCreatePage.fields.phone')}`}
            />
            <RHFTextField
              name="address"
              label={`${translate('locationCreatePage.fields.address')}`}
            />

            <RHFAutocomplete
              name="country"
              label={`${translate('locationCreatePage.fields.country')}`}
              options={countries.map((country) => country.label)}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {
                const { code, label, phone } = countries.filter(
                  (country) => country.label === option
                )[0];

                if (!label) {
                  return null;
                }

                return (
                  <li {...props} key={label}>
                    <Iconify
                      key={label}
                      icon={`circle-flags:${code.toLowerCase()}`}
                      width={28}
                      sx={{ mr: 1 }}
                    />
                    {label} ({code}) +{phone}
                  </li>
                );
              }}
            />

            <RHFTextField
              name="city"
              label={`${translate('locationCreatePage.fields.city')}`}
            />
            <RHFTextField
              name="zip"
              label={`${translate('locationCreatePage.fields.zipcode')}`}
            />
            <RHFTextField
              name="province"
              label={`${translate('locationCreatePage.fields.province')}`}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {`${translate('cancel')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('update')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
