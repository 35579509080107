import { useTranslation } from 'react-i18next';
// components
import { useSettingsContext } from '../components/settings';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { allLangs, defaultLang } from './config-lang';
import { onlyDate, dateTime } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const { onChangeDirectionByLang } = useSettingsContext();

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable
    ? localStorage.getItem('i18nextLng')
    : '';

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  const formatDate = (date: string | number | Date ) => {
    const dateFormat = currentLang?.dateFormat || 'dd MMM yyyy';
    const lang = currentLang?.value || 'fr';

    return onlyDate(date, dateFormat, lang);
  };

  const formatDateTime = (date: string | number | Date ) => {
    const dateFormat = currentLang?.dateFormat || 'dd MMM yyyy';
    const timeFormat = currentLang?.timeFormat || 'HH:mm:ss';
    const lang = currentLang?.value || 'fr';

    return dateTime(date, dateFormat, timeFormat, lang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs,
    formatDate,
    formatDateTime,
  };
}
