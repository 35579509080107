// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/login',
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  upComingApps: path(ROOTS_DASHBOARD, '/upcoming-apps'),
  apeLinkPage: path(ROOTS_DASHBOARD, '/ape-link'),
  publicationsLinkPage: path(ROOTS_DASHBOARD, '/publications-link'),
  locationsLinkPage: path(ROOTS_DASHBOARD, '/locations-link'),
  applications: {
    root: path(ROOTS_DASHBOARD, '/applications'),
    web: path(ROOTS_DASHBOARD, '/applications/web'),
    sales: path(ROOTS_DASHBOARD, '/applications/sales'),
    finance: path(ROOTS_DASHBOARD, '/applications/finance'),
    inventory: path(ROOTS_DASHBOARD, '/applications/inventory'),
    marketing: path(ROOTS_DASHBOARD, '/applications/marketing'),
    hr: path(ROOTS_DASHBOARD, '/applications/hr'),
    services: path(ROOTS_DASHBOARD, '/applications/services'),
    productivity: path(ROOTS_DASHBOARD, '/applications/productivity'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    //analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  customers: {
    list: path(ROOTS_DASHBOARD, '/customers/list'),
    new: path(ROOTS_DASHBOARD, '/customers/new'),
    view: path(ROOTS_DASHBOARD, '/customers/view'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/customers/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/user/reece-chung/edit'),
  },
  site: {
    root: path(ROOTS_DASHBOARD, '/site'),
    // siteRoot: path(ROOTS_DASHBOARD, '/site'),
    order: path(ROOTS_DASHBOARD, '/site/order'),
  },
  orders: {
    new: path(ROOTS_DASHBOARD, '/orders/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/orders/${id}/edit`),
    ordersList: path(ROOTS_DASHBOARD, '/orders/list'),
    viewOrder: (id: string) => path(ROOTS_DASHBOARD, `/orders/${id}`),
  },
  companies: {
    list: path(ROOTS_DASHBOARD, '/companies/list'),
    new: path(ROOTS_DASHBOARD, '/companies/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/companies/${id}/edit`),
  },
  eCommerce: {
    stocks: path(ROOTS_DASHBOARD, '/products/stocks/list'),
    locations: {
      list: path(ROOTS_DASHBOARD, '/products/locations/list'),
      new: path(ROOTS_DASHBOARD, '/products/locations/new'),
      view: path(ROOTS_DASHBOARD, '/products/locations/view'),
      edit: (id: string) =>
        path(ROOTS_DASHBOARD, `/products/locations/${id}/edit`),
    },
    orderRoot: path(ROOTS_DASHBOARD, '/products/orders'),
    productRoot: path(ROOTS_DASHBOARD, '/products'),
    root: path(ROOTS_DASHBOARD, '/products'),
    //shop: path(ROOTS_DASHBOARD, '/products/shop'),
    productsList: path(ROOTS_DASHBOARD, '/products/list'),
    viewProduct: (name: string) => path(ROOTS_DASHBOARD, `/products/${name}`),
    //checkout: path(ROOTS_DASHBOARD, '/products/checkout'),
    new: path(ROOTS_DASHBOARD, '/products/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/products/edit/${id}`),
    /*demoEdit: path(
      ROOTS_DASHBOARD,
      '/products/product/nike-blazer-low-77-vintage/edit'
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/products/product/nike-air-force-1-ndestrukt'
    ),*/
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'
    ),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      '/blog/post/apply-these-7-secret-techniques-to-improve-event'
    ),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE =
  'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE =
  'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION =
  'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
