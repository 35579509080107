import { WORKERS } from 'src/config-global';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
export type PaymentTerms = {
  due_in_days: number;
  external_id: string;
  id: string;
  name: string;
  type: string;
};

export const getPaymentTerms = async () => {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.get(`${WORKERS.orders}/${siteId}/payment_terms`);

  return response.data.data as PaymentTerms[];
};
