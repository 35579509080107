// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IOrderInvoiceArgs } from 'src/@types/order';
import FormProvider, {
  RHFEditor,
  RHFTextField,
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { useLocales } from 'src/locales';
import { sendInvoice } from 'src/redux/slices/order';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

type Props = {
  orderId: string;
  customerEmail: string;
  userEmail: string;
  open: boolean;
  onClose: VoidFunction;
};

export default function InvoiceDialog({
  customerEmail,
  userEmail,
  open,
  onClose,
  orderId,
}: Props) {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const invoiceSchema = Yup.object().shape({
    to: Yup.string()
      .required(`${translate('customerCreatePage.requirements.email')}`)
      .email(`${translate('customerCreatePage.requirements.validEmail')}`),
    from: Yup.string()
      .required(`${translate('customerCreatePage.requirements.email')}`)
      .email(`${translate('customerCreatePage.requirements.validEmail')}`),
    subject: Yup.string().required(
      `${translate('customerCreatePage.requirements.email')}`
    ),
    custom_message: Yup.string(),
  });

  const defaultValues = useMemo<IOrderInvoiceArgs>(
    () => ({
      to: customerEmail || '',
      from: userEmail,
      subject: `${translate('invoiceDialog.subject')}`,
      custom_message: '',
    }),
    [customerEmail, translate, userEmail]
  );

  const methods = useForm<IOrderInvoiceArgs>({
    resolver: yupResolver(invoiceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: IOrderInvoiceArgs) => {
    try {
      await sendInvoice(orderId, {
        ...data,
        body: (data?.body?.length || 0) < 2 ? undefined : data.body,
      });
      enqueueSnackbar(`${translate('invoiceDialog.invoiceSent')}`);

      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('invoiceDialog.title')}`}</DialogTitle>
        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            {`${translate('invoiceDialog.details')}`}
          </Alert>

          <Grid container>
            <Grid item xs={12} md={12}>
              <RHFTextField
                name="to"
                label={`${translate('invoiceDialog.fields.emailTo')}`}
              />
              <RHFTextField
                disabled={true}
                name="from"
                label={`${translate('invoiceDialog.fields.emailFrom')}`}
                sx={{ mt: '1em' }}
              />
              <RHFTextField
                name="subject"
                label={`${translate('invoiceDialog.fields.emailSubject')}`}
                sx={{ mt: '1em' }}
              />
              <RHFEditor simple name="custom_message" sx={{ mt: '1em' }} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            {`${translate('cancel')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('invoiceDialog.send')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
