import { Navigate, useRoutes } from 'react-router-dom';
import GuestGuard from 'src/auth/GuestGuard';
import StockListPage from 'src/pages/dashboard/StockListPage';
import CompaniesListPage from 'src/pages/dashboard/companies/CompaniesListPage';
import CompanyCreatePage from 'src/pages/dashboard/companies/CompanyCreatePage';
import CompanyEditPage from 'src/pages/dashboard/companies/CompanyEditPage';
import CustomerListPage from 'src/pages/dashboard/customers/CustomerListPage';
import LocationCreatePage from 'src/pages/dashboard/locations/LocationCreatePage';
import LocationEditPage from 'src/pages/dashboard/locations/LocationEditPage';
import LocationListPage from 'src/pages/dashboard/locations/LocationListPage';
import OrderCreatePage from 'src/pages/dashboard/orders/OrderCreatePage';
import OrderEditPage from 'src/pages/dashboard/orders/OrderEditPage';
// auth
import AuthGuard from '../auth/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import EcommerceOrderDetailsPage from '../pages/dashboard/EcommerceOrderDetailsPage';
import EcommerceOrdersListPage from '../pages/dashboard/EcommerceOrdersListPage';
import SiteSelectionPage from '../pages/dashboard/SiteSelectionPage';
import OrderToMdp from '../pages/dashboard/OrderToMdp';
import CustomerCreatePage from '../pages/dashboard/customers/CustomerCreatePage';
import CustomerEditPage from '../pages/dashboard/customers/CustomerEditPage';
import ApplicationsPage from '../pages/dashboard/Applications';
import ApplicationWebPage from '../pages/dashboard/ApplicationWebPage';
import ApplactionSalesPage from '../pages/dashboard/ApplicationSalesPage';
import ApplicationFinancePage from '../pages/dashboard/ApplicationFinancePage';
import ApplicationInventoryPage from '../pages/dashboard/ApplicationInventoryPage';
import ApplicationMarketingPage from '../pages/dashboard/ApplicationMarketingPage';
import ApplicationHrPage from '../pages/dashboard/ApplicationHrPage';
import ApplicationServicesPage from '../pages/dashboard/ApplicationServicesPage';
import ApplicationProductivityPage from '../pages/dashboard/ApplicationProductivityPage';
import UpComingApps from '../pages/dashboard/UpComingApps';
import ApeLinkPage from 'src/pages/dashboard/ApeLinkPage';
//
import {
  ComingSoonPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  EcommerceProductEditPage,
  EcommerceProductListPage,
  GeneralAppPage,
  LoginPage,
  NewPasswordPage,
  Page403,
  Page404,
  Page500,
  ResetPasswordPage,
  UserAccountPage,
  UserCreatePage,
  UserEditPage,
  UserListPage,
  UserProfilePage,
  VerifyCodePage,
} from './elements';
import PublicationsLinkPage from 'src/pages/dashboard/PublicationsLinkPage';
import LocationsLinkPage from 'src/pages/dashboard/LocationLinkPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        /*{
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },*/
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        {
          path: 'products',
          children: [
            { path: '', element: <EcommerceProductListPage /> },
            { path: 'list', element: <EcommerceProductListPage /> },
            { path: ':id', element: <EcommerceProductDetailsPage /> },
            { path: 'edit/:id', element: <EcommerceProductEditPage /> },
            { path: 'new', element: <EcommerceProductCreatePage /> },
            {
              path: 'locations',
              children: [
                { path: 'list', element: <LocationListPage /> },
                { path: 'view', element: <LocationListPage /> },
                { path: ':id/edit', element: <LocationEditPage /> },
                { path: 'new', element: <LocationCreatePage /> },
              ],
            },
            {
              path: 'stocks',
              children: [{ path: 'list', element: <StockListPage /> }],
            },
          ],
        },
        {
          path: 'orders',
          children: [
            { path: 'list', element: <EcommerceOrdersListPage /> },
            { path: ':id', element: <EcommerceOrderDetailsPage /> },
            { path: ':id/edit', element: <OrderEditPage /> },
            { path: 'new', element: <OrderCreatePage /> },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':id/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'customers',
          children: [
            { path: 'list', element: <CustomerListPage /> },
            { path: 'view', element: <CustomerListPage /> },
            { path: ':id/edit', element: <CustomerEditPage /> },
            { path: 'new', element: <CustomerCreatePage /> },
          ],
        },
        {
          path: 'companies',
          children: [
            { path: 'list', element: <CompaniesListPage /> },
            { path: ':id/edit', element: <CompanyEditPage /> },
            { path: 'new', element: <CompanyCreatePage /> },
          ],
        },
        { 
          path: 'applications',
          children: [
            { path: '', element: <ApplicationsPage />},
            { path: 'web', element: <ApplicationWebPage />},
            { path: 'sales', element: <ApplactionSalesPage />},
            { path: 'finance', element: <ApplicationFinancePage />},
            { path: 'inventory', element: <ApplicationInventoryPage />},
            { path: 'marketing', element: <ApplicationMarketingPage />},
            { path: 'hr', element: <ApplicationHrPage />},
            { path: 'services', element: <ApplicationServicesPage />},
            { path: 'productivity', element: <ApplicationProductivityPage />}, 
          ],
        },
        { path: 'upcoming-apps', element: <UpComingApps /> },
        { path: 'ape-link', element: <ApeLinkPage /> },
        { path: 'publications-link', element: <PublicationsLinkPage /> },
        { path: 'locations-link', element: <LocationsLinkPage /> },
        {
          path: 'site',
          children: [
            { path: '', element: <SiteSelectionPage /> },
            { path: 'order', element: <OrderToMdp /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
