import { WORKERS } from '../../config-global';
import axios from '../../utils/axios';
import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { ICompany, ICompanyState } from 'src/@types/company';

const initialState: ICompanyState = {
  isLoading: false,
  error: null,
  companies: [],
  company: null,
  pagination: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.pagination = action.payload.pagination;
      state.companies = action.payload;
    },

    // GET PRODUCT
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },
  },
});

export default slice.reducer;

export function getCompaniesSlice(search: any, limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCompanies(search, limit, page);

      dispatch(slice.actions.getCompaniesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updateCompany = async (data: ICompany) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.put(`${WORKERS.sites}/${siteId}/company?id=${data.id}`, data)
  )?.data?.data;
};

export const createCompany = async (data: ICompany) => {
  const siteId = localStorage.getItem('siteId');

  return (await axios.post(`${WORKERS.sites}/${siteId}/company`, data))?.data
    ?.data;
};

export const deleteCompany = async (id: string) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.delete(`${WORKERS.sites}/${siteId}/company`, {
      params: { id },
    })
  )?.data?.data;
};

export const getCompanies = async (
  search: any,
  limit = 100,
  page = 1
): Promise<Array<ICompany>> => {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.post(
    `${WORKERS.sites}/${siteId}/companies`,
    search,
    {
      params: { limit, page },
    }
  );

  return response.data.data;
};
