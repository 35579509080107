// @mui
import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ILocation } from 'src/@types/location';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useBoolean } from 'src/hooks/useBoolean';
import { useAuthContext } from '../../../auth/useAuthContext';
import ConfirmDialog from '../../../components/confirm-dialog';
// @types
// components
import Iconify from '../../../components/iconify';
import { useLocales } from '../../../locales';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';
import LocationQuickEditForm from './LocationQuickEditForm';

// ----------------------------------------------------------------------

type Props = {
  row: ILocation;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function LocationTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { translate } = useLocales();

  const quickEdit = useBoolean();
  const popover = usePopover();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [_openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [data, setData] = useState<ILocation>(row);

  const { user } = useAuthContext();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const _handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {/* {hasPermission(user, PERMISSIONS.locationDelete) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )} */}
        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.locationEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.locationEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.name || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.locationEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.locationEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.phone || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.locationEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.locationEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.address?.address1
                ? `${data?.address?.address1}, ${data?.address?.city}`
                : 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        {(hasPermission(user, PERMISSIONS.locationEdit) ||
          hasPermission(user, PERMISSIONS.locationDelete)) && (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            {/* <Tooltip
              title={`${translate('locationsPage.quickEdit')}`}
              placement="top"
              arrow
            >
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={quickEdit.onTrue}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip> */}

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {hasPermission(user, PERMISSIONS.locationEdit) && (
        <LocationQuickEditForm
          currentLocation={data}
          open={quickEdit.value}
          onClose={(_data) => {
            if (!_data?.address) {
              quickEdit.onFalse();
              return;
            }

            setData(_data);
            quickEdit.onFalse();
          }}
        />
      )}

      {(hasPermission(user, PERMISSIONS.locationEdit) ||
        hasPermission(user, PERMISSIONS.locationDelete)) && (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          {hasPermission(user, PERMISSIONS.locationEdit) && (
            <MenuItem
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            >
              <Iconify icon="eva:edit-fill" />
              {`${translate('edit')}`}
            </MenuItem>
          )}

          {hasPermission(user, PERMISSIONS.locationDelete) && (
            <MenuItem
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="eva:trash-2-outline" />
              {`${translate('delete')}`}
            </MenuItem>
          )}
        </CustomPopover>
      )}

      {hasPermission(user, PERMISSIONS.locationDelete) && (
        <ConfirmDialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          title={`${translate('delete')}`}
          content={`${translate('sureAction')}`}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onDeleteRow();
                handleCloseConfirm();
              }}
            >
              {`${translate('delete')}`}
            </Button>
          }
        />
      )}
    </>
  );
}
