// @mui
import {
  Card,
  Container,
  Divider,
  IconButton,
  type SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// sections
import type { ILocation } from 'src/@types/location';
import LoadingScreen from 'src/components/loading-screen';
import { getLocationsRaw } from 'src/redux/slices/location';
import { searchProductsByLocation } from 'src/redux/slices/product';
import StockTableRow from 'src/sections/@dashboard/stock/StockTableRow';
import StockTableToolbar from 'src/sections/@dashboard/stock/StockTableToolbar';
import type { IUser } from '../../@types/user';
import { useAuthContext } from '../../auth/useAuthContext';
// _mock_
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { useSettingsContext } from '../../components/settings';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../components/table';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_PAGE } from '../../routes/paths';
import { PERMISSIONS, ROLES, hasPermission } from '../../utils/permissions';
import {StockExportModal} from './StockExportModal';
export default function StockListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const { translate } = useLocales();

  const { themeStretch } = useSettingsContext();

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.stockList)) {
    navigate(PATH_PAGE.page403);
  }

  const TABLE_HEAD = [
    { id: 'name', label: `${translate('product')}`, align: 'left' },
    { id: 'status', label: `${translate('status')}`, align: 'left' },
    hasPermission(user, ROLES.ADMIN)
      ? { id: 'sku', label: 'SKU', align: 'left' }
      : { id: 'ean', label: `${translate('barcode')}`, align: 'left' },
    {
      id: 'stock',
      label: `${translate('productsPage.inventory')}`,
      align: 'left',
    },
    { id: '' },
  ];

  const STOCK_OPTIONS = [
    {
      value: 'in_stock',
      label: `${translate('productsPage.stocks.in_stock')}`,
    },
    {
      value: 'low_stock',
      label: `${translate('productsPage.stocks.low_stock')}`,
    },
    {
      value: 'out_of_stock',
      label: `${translate('productsPage.stocks.out_of_stock')}`,
    },
  ];

  const STATUS_OPTIONS = [
    { value: 'active', label: `${translate('productsPage.status.active')}` },
    {
      value: 'archived',
      label: `${translate('productsPage.status.archived')}`,
    },
    { value: 'draft', label: `${translate('productsPage.status.draft')}` },
  ];

  if (!hasPermission(user as IUser, PERMISSIONS.locationEdit)) {
    TABLE_HEAD.pop();
  }

  const dispatch = useDispatch();

  const { products, isLoading, pagination } = useSelector(
    (state) => state.product
  );

  const [locations, setLocations] = useState<ILocation[]>([]);

  const [filterName, setFilterName] = useState('');
  const [_loading, setLoading] = useState(true);
  const [filterLocation, setFilterLocation] = useState<string>('');
  const [stockFilter, setStockFilter] = useState<string[]>([]);
  const [statusFilter, setFilterStatus] = useState<string[]>([]);

  const [_openConfirm, setOpenConfirm] = useState(false);

  const [currentQuery, setCurrentQuery] = useState<any>({});

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      searchProductsByLocation(
        filterLocation,
        currentQuery,
        Number(event.target.value),
        page || 1
      )
    );
    setRowsPerPage(Number(event.target.value));
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      searchProductsByLocation(
        filterLocation,
        currentQuery,
        rowsPerPage,
        newPage
      )
    );
    setPage(newPage || 1);
  };

  const onStatusChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const status = typeof value === 'string' ? value.split(',') : value;
    setFilterStatus(status);
    onSearch(undefined, undefined, status);
  };

  const onStockChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    const stock = typeof value === 'string' ? value.split(',') : value;
    setStockFilter(stock);

    onSearch(undefined, stock);
  };

  useEffect(() => {
    const _locations = async () => {
      const allLocations = await getLocationsRaw({});
      setLocations(allLocations);

      dispatch(
        searchProductsByLocation(
          allLocations?.[0]?.id,
          currentQuery,
          rowsPerPage,
          page || 1
        )
      );
      setLoading(false);
      setFilterLocation(allLocations?.[0]?.id);
    };

    if (!locations?.length) {
      _locations();
    }
  }, [dispatch, setPage, currentQuery, rowsPerPage, page]);

  const _dataInPage = products?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isFiltered = filterName !== '';

  const isNotFound =
    !products?.length &&
    (filterName !== undefined ||
      filterLocation !== undefined ||
      stockFilter?.length > 0 ||
      statusFilter?.length > 0);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const onLocationChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const locations = typeof value === 'string' ? value.split(',') : value;

    setFilterLocation(locations[0]);

    onSearch(locations[0]);
  };

  const handleResetFilter = () => {
    setCurrentQuery({})
    setPage(0);
    setFilterName('');
    setFilterStatus([]);
    setStockFilter([]);
    
    const query = {};
    
    dispatch(
      searchProductsByLocation(filterLocation, query, rowsPerPage, page || 1)
    );
  };

  const onSearch = async (
    location?: string,
    stockFilterOverride?: string[],
    statusFilterOverride?: string[]
  ) => {
    setLoading(true);

    const _stockFilter = stockFilterOverride || stockFilter;
    const _statusFilter = statusFilterOverride || statusFilter;

    console.log(_stockFilter);

    const _or = [];

    if (_stockFilter.includes('in_stock')) {
      _or.push({
        available: {
          _gte: 11,
        },
      });
    }

    if (_stockFilter?.includes('low_stock')) {
      _or.push({
        available: {
          _lte: 10,
          _gt: _statusFilter?.includes('out_of_stock') ? -1 : 0,
        },
      });
    }

    if (
      _stockFilter?.includes('out_of_stock') &&
      !_stockFilter.includes('low_stock')
    ) {
      _or.push({
        available: {
          _eq: 0,
        },
      });
    }

    if (!filterName?.length && currentQuery?._or?.length) {
      delete currentQuery._or;
    }

    if (!_stockFilter?.length && currentQuery?.available) {
      delete currentQuery.available;
    }

    const query = {
      ...currentQuery,
      ...(_statusFilter.length
        ? {
            status: {
              _in: _statusFilter,
            },
          }
        : {
            status: undefined,
          }),
      ...(filterName.toLowerCase().length
        ? {
            _and: [
              {
                _or: [
                  {
                    title: {
                      _ilike: `%${filterName.toLowerCase()}%`,
                    },
                  },
                  {
                    variants: {
                      barcode: {
                        _eq: filterName.toLowerCase(),
                      },
                    },
                  },
                  {
                    variants: {
                      sku: {
                        _eq: filterName.toLowerCase(),
                      },
                    },
                  },
                ],
              },
              _or.length
                ? {
                    _or,
                  }
                : {},
            ],
          }
        : { _or }),
    };

    if (query._or?.length === 0) {
      delete query._or;
    }

    setPage(0);
    setCurrentQuery(query);

    dispatch(
      searchProductsByLocation(
        location || filterLocation,
        query,
        rowsPerPage,
        1
      )
    );
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title> {`${translate('stockListPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        
        <StockExportModal filterLocation={filterLocation} query={currentQuery}/> 
        

        <Card>
          <Divider />

          {!isLoading && (
            <StockTableToolbar
              isFiltered={isFiltered}
              filterLocation={filterLocation}
              locationOptions={locations}
              onLocationChange={onLocationChange}
              filterName={filterName}
              statusOptions={STATUS_OPTIONS}
              stockOptions={STOCK_OPTIONS}
              filterStatus={statusFilter}
              filterStock={stockFilter}
              onStatusChange={onStatusChange}
              onStockChange={onStockChange}
              onSearch={() => onSearch()}
              onFilterChange={(event) => setFilterName(event.target.value)}
              onResetFilter={handleResetFilter}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={products?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  products.map((row) => row.id)
                )
              }
              action={
                <Tooltip title={`${translate('delete')}`}>
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                {isLoading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={products?.length}
                      numSelected={selected.length}
                    />

                    <TableBody>
                      {(isLoading ? [] : products || []).map((row) => (
                        <StockTableRow
                          key={row.id}
                          row={row}
                          location={filterLocation}
                        />
                      ))}

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </>
                )}
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || products?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
