// utils

// ----------------------------------------------------------------------

import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { ILocation, ILocationLinks, ILocationState } from 'src/@types/location';
import { WORKERS } from '../../config-global';
import axios from '../../utils/axios';

const initialState: ILocationState = {
  isLoading: false,
  error: null,
  locations: [],
  location: null,
  pagination: null,
};

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getLocationsSuccess(state, action) {
      state.isLoading = false;
      state.pagination = action.payload.pagination;
      state.locations = action.payload;
    },

    // GET PRODUCT
    getLocationSuccess(state, action) {
      state.isLoading = false;
      state.location = action.payload;
    },
  },
});

export default slice.reducer;

export function getLocations(search: any, limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getLocationsRaw(search, limit, page);

      dispatch(slice.actions.getLocationsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updateLocation = async (data: ILocation) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.put(`${WORKERS.sites}/${siteId}/location?id=${data.id}`, data)
  )?.data?.data;
};

export const createLocation = async (data: ILocation) => {
  const siteId = localStorage.getItem('siteId');

  return (await axios.post(`${WORKERS.sites}/${siteId}/location`, data))?.data
    ?.data;
};

export const deleteLocation = async (id: string) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.delete(`${WORKERS.sites}/${siteId}/location`, {
      params: { id },
    })
  )?.data?.data;
};

export const getLocationsRaw = async (
  search: any,
  limit = 100,
  page = 1
): Promise<Array<ILocation>> => {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.post(
    `${WORKERS.sites}/${siteId}/locations`,
    search,
    {
      params: { limit, page },
    }
  );

  return response.data.data;
};

export const getLocationTypes = async () => {
  const siteId = localStorage.getItem('siteId');

  return (await axios.get(`${WORKERS.products}/${siteId}/location-types`))?.data
    ?.data;
};

export const getLocationLinks = async () => {
  const siteId = localStorage.getItem('siteId');

  return (await axios.get(`${WORKERS.products}/${siteId}/location-links`))?.data
    ?.data;
};

export const createLocationLinks = async (data: ILocationLinks) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.post(`${WORKERS.products}/${siteId}/location-links`, data)
  )?.data?.data;
};

export const deleteLocationLinks = async (id: string) => {
  const siteId = localStorage.getItem('siteId');

  return await axios.delete(
    `${WORKERS.products}/${siteId}/location-links?id=${id}`
  );
};
