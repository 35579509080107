// @mui
import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useBoolean } from 'src/hooks/useBoolean';
import { useAuthContext } from '../../../auth/useAuthContext';
import ConfirmDialog from '../../../components/confirm-dialog';
// @types
// components
import Iconify from '../../../components/iconify';
import { useLocales } from '../../../locales';
import CompanyQuickEditForm from './CompanyQuickEditForm';
import { PERMISSIONS, hasPermission } from 'src/utils/permissions';
import { ICompany } from 'src/@types/company';

// ----------------------------------------------------------------------

type Props = {
  row: ICompany;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  apeLink: { id: string; apeCode: string }[];
};

export default function CompanyTableRow({
  row,
  selected,
  onEditRow,
  onDeleteRow,
  apeLink,
}: Props) {
  const { translate } = useLocales();

  const quickEdit = useBoolean();
  const popover = usePopover();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [_openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [data, setData] = useState<ICompany>(row);

  const { user } = useAuthContext();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.companyEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.companyEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.name || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.companyEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.companyEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.email || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.companyEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.companyEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.siret || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.companyEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.companyEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.tva || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.companyEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.companyEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.rcs || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        {(hasPermission(user, PERMISSIONS.companyEdit) ||
          hasPermission(user, PERMISSIONS.companyDelete)) && (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            {/* <Tooltip title={`${translate('quickEdit')}`} placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={quickEdit.onTrue}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip> */}

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {hasPermission(user, PERMISSIONS.companyEdit) && (
        <CompanyQuickEditForm
          currentCompany={data}
          open={quickEdit.value}
          onClose={(_data) => {
            if (!_data?.name) {
              quickEdit.onFalse();
              return;
            }

            setData(_data);
            quickEdit.onFalse();
          }}
          apeLink={apeLink}
        />
      )}

      {(hasPermission(user, PERMISSIONS.companyEdit) ||
        hasPermission(user, PERMISSIONS.companyDelete)) && (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          {hasPermission(user, PERMISSIONS.companyEdit) && (
            <MenuItem
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            >
              <Iconify icon="eva:edit-fill" />
              {`${translate('edit')}`}
            </MenuItem>
          )}
          {hasPermission(user, PERMISSIONS.companyDelete) && (
            <MenuItem
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="eva:trash-2-outline" />
              {`${translate('delete')}`}
            </MenuItem>
          )}
        </CustomPopover>
      )}

      {hasPermission(user, PERMISSIONS.companyDelete) && (
        <ConfirmDialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          title={`${translate('delete')}`}
          content={`${translate('sureAction')}`}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onDeleteRow();
                handleCloseConfirm();
              }}
            >
              {`${translate('delete')}`}
            </Button>
          }
        />
      )}
    </>
  );
}
