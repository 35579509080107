// @mui
import LoadingButton from '@mui/lab/LoadingButton';
// _mock
// types
// assets
// components
import { Divider, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { IProduct, IVariant } from 'src/@types/product';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { updateProduct } from 'src/redux/slices/product';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: (data: any) => void;
  currentProduct?: IProduct;
  currentLocation?: string;
};

export default function StockQuickEditForm({
  currentProduct,
  open,
  onClose,
  currentLocation,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  console.log(currentProduct, currentLocation);

  const variants = (
    JSON.parse(JSON.stringify(currentProduct?.variants || [])) as IVariant[]
  ).map((v) => {
    (v as any).inventories = currentProduct?.product_inventories
      ?.filter((e) => String(v.inventory_item_id) === String(e.external_id))
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.location_external_id]: curr,
        }),
        {} as any
      );

    return v;
  });

  console.log(variants);

  const methods = useForm<any>({
    defaultValues: {
      variants,
    },
  });

  if (!methods.getValues()?.variants?.length && variants?.length) {
    methods.setValue('variants', variants);
  }

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'variants',
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const handleChangeQuantity = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number,
      locationExternalId: string
    ) => {
      setValue(
        `variants[${index}].inventories[${locationExternalId}].available` as any,
        Number(event.target.value)
      );
    },
    [setValue]
  );

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const finalData = {
        title: currentProduct?.title,
        ...data,
      };
      console.log('dd', finalData);

      await updateProduct(currentProduct?.id as string, finalData);

      const total = data.variants.reduce(
        (memo: number, acc: any) =>
          memo +
          Object.values(acc.inventories).reduce<number>(
            (m, a: any) => m + a.available,
            0
          ),
        0
      );

      onClose(total);
      enqueueSnackbar(`${translate('customerCreatePage.updateSuccess')}`);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('customersPage.quickEdit')}`}</DialogTitle>

        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            {`${translate('customersEditPage.currentlyEditing')} ${
              currentProduct?.title
            }`}
          </Alert>
          <Stack
            divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
            spacing={3}
          >
            {fields.map((item: any, index) => (
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                {Object.values(item?.inventories || [])?.filter(
                  (i: any) => i.location?.id === currentLocation
                )?.length ? (
                  Object.values(item?.inventories || [])
                    ?.filter((i: any) => i.location?.id === currentLocation)
                    .map((inventory: any) => (
                      <Stack spacing={1}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: 'text.secondary' }}
                        >
                          SKU: {item?.sku}
                        </Typography>

                        <RHFTextField
                          name={`variants[${index}].inventories[${inventory.location_external_id}].available`}
                          onChange={(event) =>
                            handleChangeQuantity(
                              event,
                              index,
                              inventory.location_external_id
                            )
                          }
                          label={`${translate('quantity')}`}
                          placeholder="0"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            type: 'number',
                          }}
                        />
                      </Stack>
                    ))
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.secondary' }}
                    >
                      {`${translate('stockListPage.noLongerExist')}`}
                    </Typography>
                  </>
                )}
              </Box>
            ))}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {`${translate('cancel')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('update')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
