import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ICompany } from 'src/@types/company';
// _mock
// types
// assets
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
// components
import { useSnackbar } from 'src/components/snackbar';
import { updateCompany } from 'src/redux/slices/company';
import * as Yup from 'yup';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: (data: any) => void;
  currentCompany?: ICompany;
  apeLink: { id: string; apeCode: string }[];
};

export default function CompanyQuickEditForm({
  currentCompany,
  open,
  onClose,
  apeLink,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  // console.log(apeLink);

  const companySchema = Yup.object().shape({
    name: Yup.string().required(
      `${translate('companyCreatePage.requirements.name')}`
    ),
    email: Yup.string().required(
      `${translate('companyCreatePage.requirements.email')}`
    ),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentCompany?.name || '',
      email: currentCompany?.email || '',
      siret: currentCompany?.siret || '',
      tva: currentCompany?.tva || '',
      rcs: currentCompany?.rcs || '',
      ape_link: currentCompany?.apeLink?.apeCode || '',
    }),
    [currentCompany]
  );

  const methods = useForm<ICompany>({
    resolver: yupResolver(companySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      // console.log(data);

      const rs = await updateCompany({
        id: currentCompany?.id as string,
        name: data.name,
        email: data.email,
        siret: data.siret,
        tva: data.tva,
        rcs: data.rcs,
        ape_link: data.ape_link ? data.ape_link.value : null,
      });

      onClose(rs);
      enqueueSnackbar(`${translate('companyCreatePage.updateSuccess')}`);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('quickEdit')}`}</DialogTitle>

        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
            {`${translate('companyListPage.currentlyEditing')} ${
              currentCompany?.name
            }`}
          </Alert>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('companyCreatePage.fields.name')}`}
            />

            <RHFTextField
              name="email"
              label={`${translate('companyCreatePage.fields.email')}`}
            />
            <RHFTextField
              name="siret"
              label={`${translate('companyCreatePage.fields.siret')}`}
            />
            <RHFTextField
              name="tva"
              label={`${translate('companyCreatePage.fields.tva')}`}
            />

            <RHFTextField
              name="rcs"
              label={`${translate('companyCreatePage.fields.rcs')}`}
            />
            <RHFAutocomplete
              name="ape_link"
              label={`${translate('companyCreatePage.fields.apeLink')}`}
              placeholder={`+ ${translate('companyCreatePage.fields.apeLink')}`}
              freeSolo
              options={apeLink.map((link) => ({
                label: link.apeCode,
                value: link.apeCode,
              }))}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {`${translate('cancel')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('update')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
