import { useLocales } from '../../locales';
import ComingSoonPage from '../ComingSoonPage';

// ----------------------------------------------------------------------

export default function OrderToMdp() {
  const { translate } = useLocales();

  return (
    <>
      <ComingSoonPage></ComingSoonPage>
    </>
  );
}
