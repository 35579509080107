// @mui
import { Typography } from '@mui/material';
// hooks
// _mock
// components
// assets
import { useLocales } from '../../locales';
import ComingSoonPage from '../ComingSoonPage';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import { Container, Grid } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import Button from '@mui/material/Button';

import { PATH_DASHBOARD } from 'src/routes/paths';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

export default function SiteSelectionPage() {
  const { translate } = useLocales();

  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> {`${translate('siteSelectionPage.helmet')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={1}>
            <Card
              sx={{
                padding: '40px',
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h4">{`${translate(
                  'siteSelectionPage.questionAction'
                )}`}</Typography>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
                sx={{ paddingBlockStart: '20px' }}
              >
                <Grid item>
                  <Button
                    href="https://www.flashvideofilm.fr/"
                    variant="contained"
                    target="blank"
                  >
                    {`${translate('siteSelectionPage.goWebsite')}`}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    component={Link}
                    variant="contained"
                    to={PATH_DASHBOARD.site.order}
                  >
                    {`${translate('siteSelectionPage.goMdpOrder')}`}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <ComingSoonPage></ComingSoonPage> */}
    </>
  );
}
