// routes
// @types
// components
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import _, { isArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  IAvailableFulfillment,
  ICustomer,
  IDiscount,
  IFulfillmentLine,
  IOrder,
  IPaymentTerm,
  IReturnableFulfillments,
  IShippingLine,
} from 'src/@types/order';
import { IProduct } from 'src/@types/product';
import { useAuthContext } from 'src/auth/useAuthContext';
import Iconify from 'src/components/iconify';
import {
  cancelFulfillment,
  closeOrCancelReturn,
  createOrder,
  getFulfillments,
  getReturnables,
  updateOrder,
} from 'src/redux/slices/order';
import { searchProductsRaw } from 'src/redux/slices/product';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fCurrency } from 'src/utils/formatNumber';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from '../../../components/hook-form';
import Image from '../../../components/image';
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';
import CustomerQuickEditForm from '../customer/CustomerQuickEditForm';
import CancelDialog, { IRefundLine } from './CancelDialog';
import CustomProductDialog from './CustomProductDialog';
import CustomerListDialog from './CustomerListDialog';
import DelayedPaymentDialog from './DelayedPaymentDialog';
import DeleteDialog from './DeleteDialog';
import DiscountDialog from './DiscountDialog';
import FulfillmentDialog from './FulfillmentDialog';
import InvoiceDialog from './InvoiceDialog';
import ProductsListDialog from './ProductsListDialog';
import ReserveInventoryDialog from './ReserveInventoryDialog';
import ReturnDialog from './ReturnDialog';
import ShippingDialog from './ShippingDialog';
import { IUser } from 'src/@types/user';

type Props = {
  isEdit?: boolean;
  currentOrder?: IOrder;
};

export type CustomProduct = {
  quantity?: number;
  amount?: number;
  price?: number;
  grams?: number;
  external_id?: string;
  fulfillment?: IAvailableFulfillment;
  fulfillmentLine?: IFulfillmentLine;
} & IProduct;

export const ORDER_TAGS = [
  'osp: En Attente Du Fournisseur',
  'osp: En Cours de préparation',
  'osp: Arrivage En Cours',
  'osp: En Attente De Regroupement',
  'osp: Précommande',
  'à expédier',
];

export function OrderNewEditForm({ isEdit, currentOrder }: Props) {
  // console.log('curr', currentOrder);

  const [productSearchName, setProductSearchName] = useState('');
  const [loader, setLoader] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [openCustomProductDialog, setOpenCustomProductDialog] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openFulfillmentDialog, setOpenFulfillmentDialog] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<CustomProduct[]>();
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [openShippingDialog, setOpenShippingDialog] = useState(false);
  const [openReserveDialog, setOpenReserveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openDelayedPaymentDialog, setOpenDelayedPaymentDialog] =
    useState(false);
  const [cancelAmount, setCancelAmount] = useState<number | undefined>(
    undefined
  );
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [customer, setCustomer] = useState<ICustomer | undefined>(undefined);
  const [returnableFulfillments, setReturnableFulfillments] =
    useState<IReturnableFulfillments>([]);
  const [_availableFulfillments, setAvailableFulfillments] = useState<
    IAvailableFulfillment[]
  >([]);
  const [paymentTerms, setPaymentTerms] = useState<IPaymentTerm | undefined>(
    currentOrder?.payment_terms
  );
  const [reservedInventoryUntil, setReservedInventoryUntil] = useState<
    string | undefined
  >(currentOrder?.reserve_inventory_until?.split('T')[0]);
  const [discount, setDiscount] = useState<IDiscount | undefined>(
    currentOrder?.applied_discount
  );
  const [shippingLine, setShippingLine] = useState<IShippingLine | undefined>(
    currentOrder?.shipping_line
  );
  const [products, setProducts] = useState<CustomProduct[]>([]);

  useEffect(() => {
    const load = async () => {
      const returnableFulfillments = currentOrder?.external_id
        ? await getReturnables(currentOrder?.external_id)
        : [];
      const availableFulfillments = currentOrder?.external_id
        ? await getFulfillments(currentOrder?.external_id)
        : [];

      setReturnableFulfillments(returnableFulfillments);
      setAvailableFulfillments(availableFulfillments);
      if (currentOrder) {
        setCustomer(currentOrder.customer);

        const products = currentOrder.order_lines.map((line) => ({
          ...line.product,
          quantity: line.quantity,
          amount: line.amount,
          external_id: line.external_id,
        })) as any;

        // console.log('BEFORE', products);

        const unflattenArray =
          (() => {
            if (
              availableFulfillments?.filter((e) => e.status === 'closed')
                .length === availableFulfillments?.length &&
              currentOrder?.order_fulfillments?.length === 0
            ) {
              return products;
            }
            if (availableFulfillments?.length) {
              const productsArray = [];
              // console.log('dddd', availableFulfillments);
              for (const fulfillment of availableFulfillments) {
                const ffOrderItems = fulfillment.line_items || [];

                const fulfillments = (
                  currentOrder.order_fulfillments || []
                ).filter(
                  (f) => Number(f.order_fulfillment_id) === fulfillment.id
                );
                const matchingProducts = products.filter((p: any) =>
                  ffOrderItems.find(
                    (e) => String(p.external_id) === String(e.line_item_id)
                  )
                );

                // console.log(
                //   'ON THIS FULFILLMENT',
                //   fulfillment,
                //   fulfillments?.length
                // );

                if (
                  fulfillments?.length === 0 &&
                  fulfillment.status === 'closed'
                ) {
                  continue;
                }

                const remainings = [];
                const fulfilled = [];
                const returned = [];

                for (const product of matchingProducts) {
                  if (currentOrder?.status === 'fulfilled') {
                    remainings.push({
                      ...product,
                      quantity: ffOrderItems.find(
                        (e) =>
                          String(product.external_id) === String(e.line_item_id)
                      )?.quantity,
                      fulfillment,
                      toFulfill: false,
                    });

                    continue;
                  }

                  // console.log(fulfillments);

                  const fulfilledItem = fulfillments.find((e) =>
                    e.lines.find(
                      (l) =>
                        String(l.lineItemId) === String(product.external_id)
                    )
                  );

                  const orderItem = ffOrderItems.find(
                    (e) =>
                      String(product.external_id) === String(e.line_item_id)
                  );

                  const alreadyFulfilledItems = fulfillments.filter((e) =>
                    e.lines.find(
                      (l) =>
                        String(l.lineItemId) === String(product.external_id)
                    )
                  );

                  const fulfilledQuantity = alreadyFulfilledItems.reduce(
                    (total, item) =>
                      total +
                      item.lines
                        .filter(
                          (e) =>
                            String(e.lineItemId) === String(product.external_id)
                        )
                        .reduce((total, item) => total + item.quantity, 0),
                    0
                  );

                  if (
                    alreadyFulfilledItems.length === 0 &&
                    fulfillment.status === 'closed'
                  ) {
                    continue;
                  }

                  //console.log("FULFILLMENTS", fulfillments);
                  const mappedFulfillments = [];

                  for (const toMapFulfillment of fulfillments) {
                    const existingReturn = currentOrder.returns.find(
                      (r) =>
                        r.fulfillment_id === toMapFulfillment.fulfillment_id &&
                        r.lines.find(
                          (l) => l.lineItemId === product.external_id
                        )
                    );

                    const quantity = toMapFulfillment.lines.find(
                      (l) =>
                        String(l.lineItemId) === String(product.external_id)
                    )?.quantity;

                    if (!quantity) {
                      continue;
                    }

                    const mappedData = {
                      ...product,
                      quantity,
                      fulfillment,
                      fulfillmentLine: {
                        ...toMapFulfillment,
                        fulfilled_items: toMapFulfillment.lines,
                      },
                      toFulfill: false,
                    };

                    if (!existingReturn) {
                      mappedFulfillments.push(mappedData);
                    } else {
                      returned.push({
                        ...mappedData,
                        returnStatus: existingReturn.status,
                        returnExternalId: existingReturn.external_id,
                      });
                    }
                  }

                  // console.log(
                  //   'FFQ',
                  //   orderItem?.fulfillable_quantity,
                  //   fulfilledQuantity
                  // );
                  if (
                    fulfilledQuantity > 0 &&
                    fulfilledQuantity === orderItem?.fulfillable_quantity
                  ) {
                    // console.log(currentOrder.returns, product, fulfillment);
                    const existingReturn = currentOrder.returns.find(
                      (r) =>
                        r.lines.find(
                          (l) => l.lineItemId === product.external_id
                        ) &&
                        alreadyFulfilledItems.find(
                          (e) => e.fulfillment_id === r.fulfillment_id
                        )
                    );

                    if (product.quantity > fulfilledQuantity) {
                      remainings.push({
                        ...product,
                        quantity: product.quantity - fulfilledQuantity,
                        fulfillment,
                        toFulfill: true,
                      });
                    }

                    // console.log('existingReturn', existingReturn);
                    if (!existingReturn) {
                      fulfilled.push(...mappedFulfillments);

                      continue;
                    }

                    const returnedQuantity =
                      existingReturn.lines.find(
                        (l) => l.lineItemId === product.external_id
                      )?.quantity || 0;

                    if (fulfilledQuantity === returnedQuantity) {
                      const remainingQuantity =
                        (orderItem?.fulfillable_quantity || 0) -
                        fulfilledQuantity;
                      if (remainingQuantity === 0) {
                        continue;
                      }

                      returned.push({
                        ...product,
                        fulfillment: {
                          ...fulfilledItem,
                          fulfilled_items: fulfilledItem!.lines,
                        },
                        quantity: remainingQuantity,
                        toFulfill: false,
                        returnStatus: existingReturn.status,
                        returnExternalId: existingReturn.external_id,
                      });
                      continue;
                    }

                    returned.push({
                      ...product,
                      quantity: returnedQuantity,
                      fulfillment: {
                        ...fulfilledItem,
                        fulfilled_items: fulfilledItem!.lines,
                      },
                      toFulfill: false,
                      returnStatus: existingReturn.status,
                      returnExternalId: existingReturn.external_id,
                    });

                    fulfilled.push({
                      ...product,
                      quantity:
                        (orderItem?.fulfillable_quantity || 0) -
                        returnedQuantity,
                      fulfillment: {
                        ...fulfilledItem,
                        fulfilled_items: fulfilledItem!.lines,
                      },
                      toFulfill: false,
                    });

                    continue;
                  }

                  // console.log('fulfilledItem', fulfilledItem);
                  // console.log('orderItem', orderItem);
                  // console.log(
                  //   'alreadyFulfilledItems',
                  //   alreadyFulfilledItems,
                  //   fulfillment.status
                  // );

                  // console.log('fulfilledQuantity', fulfilledQuantity);

                  // console.log('GG', orderItem?.fulfillable_quantity || 0);
                  const fulfillableQuantity =
                    orderItem?.fulfillable_quantity || 0;

                  if (fulfillableQuantity > 0) {
                    remainings.push({
                      ...product,
                      quantity: fulfillableQuantity - fulfilledQuantity,
                      fulfillment,
                      toFulfill: true,
                    });
                  }

                  fulfilled.push(...mappedFulfillments);
                }

                productsArray.push(
                  remainings,
                  ...Object.values(
                    _.groupBy(
                      fulfilled,
                      (f) => f?.fulfillmentLine?.fulfillment_id
                    )
                  ),
                  ...Object.values(
                    _.groupBy(returned, (f) => f?.returnExternalId)
                  )
                );
              }

              // console.log('allFulfilled', productsArray);

              return productsArray.filter((e) => e.length);
            }
            return products;
          })() || [];

        // console.log('UFA', unflattenArray);

        setProducts(unflattenArray);
      }
    };

    load();
  }, [currentOrder]);

  // console.log('products', products);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { translate } = useLocales();

  const isOwnClient = currentOrder
    ? user?.role === 'ADMIN' ||
      user?.company === currentOrder?.customer?.company
    : true;

  // console.log('ISOWN', isOwnClient);

  const customerAddress =
    customer?.addresses?.find?.((address) => address.default) ||
    customer?.addresses?.[0];

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = useMemo(
    () =>
      ({
        status: currentOrder?.status,
        tags: (currentOrder?.tags || []).map((e) => e.trim()),
      } as IOrder),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrder]
  );

  const methods = useForm<IOrder>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const addProduct = (product: CustomProduct) => {
    setProducts([...products, product]);
  };

  const isCancelled = (order?: IOrder) =>
    order?.status === 'cancelled' || order?.status === 'refunded';

  const searchProduct = async (searchName: string) => {
    setLoader(true);

    const data = await searchProductsRaw(
      {
        status: {
          _eq: 'active',
        },
        ...(searchName.toLowerCase().length
          ? {
              _or: [
                {
                  title: {
                    _ilike: `%${searchName.toLowerCase()}%`,
                  },
                },
                {
                  variants: {
                    barcode: {
                      _eq: searchName.toLowerCase(),
                    },
                  },
                },
                {
                  variants: {
                    sku: {
                      _eq: searchName.toLowerCase(),
                    },
                  },
                },
              ],
            }
          : {}),
      },
      10,
      1,
      true
    );

    // console.log('DATA', data);

    setLoader(false);

    setSearchedProducts(data.data);
  };

  const changeProductQuantity = (product: CustomProduct, quantity: number) => {
    // console.log(products);
    const newProducts = products.flatMap((p) => {
      if (
        !p.external_id
          ? p.title === product.title
          : p.external_id === product.external_id
      ) {
        return {
          ...p,
          quantity: quantity === 0 ? undefined : quantity,
        };
      }

      return p;
    });

    setProducts(newProducts);
  };

  const changeProductTotal = (product: CustomProduct, amount: number) => {
    const newProducts = products.flatMap((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          amount: amount === 0 ? undefined : amount,
        };
      }

      return p;
    });

    setProducts(newProducts);
  };

  const removeProduct = (product: CustomProduct) => {
    setProducts(products.filter((p) => p.external_id !== product.external_id));
  };

  const deleteOrder = async (_data: IOrder) => {
    await updateOrder(currentOrder?.id!, {
      status: `${currentOrder?.status === 'draft' ? 'draft_' : ''}delete`,
    });

    enqueueSnackbar(`${translate('productsCreatePage.deleteSuccess')}`);
    navigate(PATH_DASHBOARD.orders.ordersList);
  };

  const changeTags = async (tags: string[]) => {
    if (currentOrder?.id) {
      updateOrder(currentOrder.id, { tags });

      setValue('tags', tags);

      enqueueSnackbar(
        !isEdit
          ? `${translate('productsCreatePage.createSuccess')}`
          : `${translate('productsCreatePage.updateSuccess')}`
      );
    }
  };

  const onStatus = async (
    data: IOrder,
    status: 'fulfilled' | 'pending' | 'cancelled' | 'draft' | 'complete'
  ) => {
    return onSubmit({
      ...data,
      status,
    });
  };

  const draftOrder = async (data: IOrder) => {
    return onStatus(data, 'draft');
  };

  const onComplete = async (data: IOrder) => {
    return onStatus(data, 'complete');
  };

  const onPay = async (data: IOrder) => {
    return onStatus(data, 'paid' as any);
  };

  const onCancel = async (data: IOrder) => {
    return onStatus(data, 'cancel' as any);
  };

  const onSubmit = async (data: IOrder) => {
    const address = {
      first_name: customer?.first_name,
      last_name: customer?.last_name,
      address1: customerAddress?.address1,
      phone: customer?.phone,
      city: customerAddress?.city,
      province: customerAddress?.province,
      country: customerAddress?.country,
      zip: customerAddress?.zip,
    };

    const order = {
      line_items: products.flatMap((product) => ({
        ...(product.variants?.[0]?.external_id
          ? { variant_id: product.variants[0].external_id }
          : {
              id: product.external_id,
              title: product.title,
              vendor: product.vendor,
              grams: product.grams || 0,
              taxable: (product as any).taxable || true,
            }),
        quantity: product.quantity || 0,
        price: Number(
          product.variants?.[0]?.price || product.amount || product.price || 0
        ),
      })),
      customer: {
        first_name: customer?.first_name,
        last_name: customer?.last_name,
        email: customer?.email,
      },
      cancel_amount: cancelAmount,
      tags: data.tags,
      reserve_inventory_until: reservedInventoryUntil,
      shipping_line: shippingLine,
      applied_discount: discount,
      billing_address: address,
      shipping_address: address,
      email: customer?.email,
      paid: paymentTerms ? false : true,
      status: data.status || currentOrder?.status || 'draft',
      payment_terms: paymentTerms
        ? {
            issued_at:
              paymentTerms?.payment_terms_type === 'net'
                ? paymentTerms?.payment_schedule?.issued_at
                : undefined,
            due_at:
              paymentTerms?.payment_terms_type === 'fixed'
                ? paymentTerms?.payment_schedule?.due_at
                : undefined,
            payment_terms_template_id: paymentTerms?.payment_terms_template_id,
          }
        : undefined,
    };

    // console.log('WILL UPDATE', order);
    try {
      if (isEdit && currentOrder?.id) {
        await updateOrder(currentOrder.id, order);

        if (order.status === 'complete') {
          navigate(PATH_DASHBOARD.orders.ordersList);
        } else {
          window.location.reload();
        }
      } else {
        const result = await createOrder(order);

        navigate(PATH_DASHBOARD.orders.edit(result.id));
      }
    } catch {
      enqueueSnackbar(`${translate('productsCreatePage.createError')}`);
    }

    try {
      enqueueSnackbar(
        !isEdit
          ? `${translate('productsCreatePage.createSuccess')}`
          : `${translate('productsCreatePage.updateSuccess')}`
      );
    } catch (error) {
      console.error(error);
    }
  };

  // console.log('currentOrder', currentOrder);

  const computeTotalPrice = (applyDiscount = false, applyShipping = false) => {
    const total = products.flat().reduce((total, product) => {
      return (
        total +
        (Number(
          product.variants?.[0]?.price || product.amount || product.price
        ) || 0) *
          (product.quantity || 0)
      );
    }, 0);

    const totalWithoutDiscount: number = applyDiscount
      ? Number(total) - Number(computeDiscountAmount(discount))
      : total;
    const totalWithoutShipping: number = applyShipping
      ? Number(totalWithoutDiscount) +
        Number(applyShipping ? shippingLine?.price || 0 : 0)
      : totalWithoutDiscount;

    if (totalWithoutShipping < 0) {
      return 0;
    }

    return totalWithoutShipping;
  };

  const computeDiscountAmount = (discount: IDiscount | undefined) => {
    if (!discount) {
      return 0;
    }

    if (discount.value_type === 'fixed_amount') {
      return discount.amount as number;
    }

    return (computeTotalPrice(false, false) * (discount.value || 1)) / 100;
  };

  const computeTaxes = () => {
    return computeTotalPrice(true, true) * 0.2;
  };

  const calculateShippingPrice = (
    amount: number,
    computeTotalPrice: number
  ) => {
    const result = parseFloat((amount - computeTotalPrice).toFixed(2));

    return result > 0 ? fCurrency(result) : '-';
  };

  return (
    <FormProvider methods={methods} preventKeyPress={true}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Grid container spacing={1}>
                  <Grid item md={8}>
                    <Typography variant="h6" sx={{ color: 'text.disabled' }}>
                      {`${translate('orderCreatePage.productHeading')}`}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    {(currentOrder?.status === 'draft' || !currentOrder) && (
                      <>
                        <Button
                          sx={{ flexShrink: 0, float: 'right' }}
                          startIcon={<Iconify icon="eva:file-add-outline" />}
                          onClick={() => setOpenCustomProductDialog(true)}
                        >
                          {`${translate('orderCreatePage.addCustomProduct')}`}
                        </Button>

                        <CustomProductDialog
                          onClose={() => setOpenCustomProductDialog(false)}
                          onSelect={(product: IProduct | undefined) => {
                            if (product) {
                              // console.log('product', product);
                              addProduct(product);
                            }
                          }}
                          open={openCustomProductDialog}
                        />
                      </>
                    )}
                  </Grid>
                  {(!isEdit || currentOrder?.status === 'draft') && (
                    <Grid item md={12}>
                      <Stack
                        spacing={1}
                        alignItems="center"
                        direction={{
                          xs: 'column',
                          sm: 'row',
                        }}
                        sx={{ px: 2.5, py: 3 }}
                      >
                        <TextField
                          fullWidth
                          id="product-search-name"
                          value={productSearchName}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setProductSearchName(event.target.value)}
                          label={`${translate(
                            'orderCreatePage.searchProduct'
                          )}`}
                          placeholder={`${translate(
                            'orderCreatePage.searchProductPlaceholder'
                          )}`}
                          onKeyUp={async (key) => {
                            if (
                              key.code !== 'Enter' &&
                              key.code !== 'NumpadEnter'
                            )
                              return;

                            if (
                              productSearchName === '' ||
                              (productSearchName?.length || 0) === 0
                            )
                              return;

                            await searchProduct(productSearchName);

                            setOpenProductDialog(true);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify
                                  icon="eva:search-fill"
                                  sx={{ color: 'text.disabled' }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {productSearchName !== '' &&
                          (productSearchName?.length || 0) > 0 && (
                            <Button
                              sx={{ flexShrink: 0 }}
                              disabled={loader}
                              startIcon={
                                <Iconify
                                  icon={
                                    customer ? 'eva:edit-fill' : 'eva:plus-fill'
                                  }
                                />
                              }
                              onClick={async () => {
                                await searchProduct(productSearchName);

                                setOpenProductDialog(true);
                              }}
                            >
                              {`${translate('add')}`}
                            </Button>
                          )}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item md={12}>
                    {products?.length > 0 && (
                      <Box sx={{ p: 3 }}>
                        <Typography
                          variant="h6"
                          sx={{ color: 'text.disabled', mb: 3 }}
                        >
                          {`${translate('orderCreatePage.details')}`}
                        </Typography>

                        <Stack
                          divider={
                            <Divider flexItem sx={{ borderStyle: 'dashed' }} />
                          }
                          spacing={3}
                        >
                          {products.flatMap((subProducts) => (
                            <>
                              {isArray(subProducts) &&
                                subProducts[0]?.returnExternalId && (
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    sx={{
                                      my: 0.8,
                                      color: '#05c6d5',
                                      fontWeight: 'fontWeightBold',
                                    }}
                                  >
                                    {subProducts[0].returnStatus === 'opened'
                                      ? `${translate('pendingReturn')}`
                                      : `${translate('returned')}`}
                                  </Typography>
                                )}
                              {isArray(subProducts) &&
                                subProducts[0]?.fulfillment &&
                                !subProducts[0].toFulfill &&
                                !subProducts[0]?.returnExternalId && (
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    sx={{
                                      my: 0.8,
                                      color: '#23d02a',
                                      fontWeight: 'fontWeightBold',
                                    }}
                                  >
                                    {!subProducts[0].toFulfill &&
                                    subProducts[0].fulfillmentLine
                                      .tracking_url ? (
                                      <Link
                                        href={
                                          subProducts[0].fulfillmentLine
                                            .tracking_url
                                        }
                                        target="_blank"
                                      >
                                        {`${translate('fulfilled')}${
                                          subProducts[0].fulfillmentLine
                                            .tracking_number
                                            ? ` - ${translate('tracking')}: #${
                                                subProducts[0].fulfillmentLine
                                                  .tracking_number
                                              }`
                                            : ''
                                        }`}
                                      </Link>
                                    ) : (
                                      `${translate('fulfilled')} ${
                                        subProducts[0].fulfillmentLine
                                          .tracking_number
                                          ? subProducts[0].fulfillmentLine
                                              .tracking_number
                                          : ''
                                      }`
                                    )}
                                  </Typography>
                                )}
                              {(isArray(subProducts)
                                ? subProducts
                                : [subProducts]
                              ).map((product, index) => (
                                <>
                                  <Stack
                                    key={product.id}
                                    alignItems="flex-end"
                                    spacing={1.5}
                                  >
                                    <Stack
                                      direction={{ xs: 'column', md: 'row' }}
                                      alignItems="center"
                                      spacing={2}
                                      sx={{ width: 1 }}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item md={1}>
                                          {product?.images?.[0]?.src ||
                                          product?.image?.src ? (
                                            <Image
                                              disabledEffect
                                              visibleByDefault
                                              alt={product.title}
                                              src={
                                                product?.images?.[0]?.src ||
                                                product?.image?.src
                                              }
                                              sx={{
                                                borderRadius: 1.5,
                                                width: 48,
                                                height: 48,
                                              }}
                                            />
                                          ) : (
                                            <Iconify
                                              icon="eva:file-remove-outline"
                                              sx={{
                                                width: 48,
                                                height: 48,
                                                borderRadius: 1.5,
                                                mr: '1em',
                                              }}
                                            />
                                          )}
                                        </Grid>
                                        <Grid item md={7}>
                                          {hasPermission(
                                            user as IUser,
                                            PERMISSIONS.productEdit
                                          ) ? (
                                            <Link
                                              href={PATH_DASHBOARD.eCommerce.edit(
                                                product.id
                                              )}
                                            >
                                              <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{
                                                  my: 0.5,
                                                  color: 'primary',
                                                  fontWeight:
                                                    'fontWeightMedium',
                                                }}
                                              >
                                                {product.title}
                                              </Typography>
                                            </Link>
                                          ) : (
                                            <Typography
                                              variant="body1"
                                              component="div"
                                              sx={{
                                                my: 0.5,
                                                color: 'black',
                                                fontWeight: 'fontWeightMedium',
                                              }}
                                            >
                                              {product.title}
                                            </Typography>
                                          )}
                                          <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{
                                              my: 0.5,
                                              color: 'black',
                                            }}
                                          >
                                            {product.variants?.[0]?.sku
                                              ? `SKU: ${product.variants[0].sku} - `
                                              : ''}
                                            {product.variants?.[0]?.barcode
                                              ? `Barcode: ${product.variants[0].barcode}`
                                              : ''}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={2}>
                                          <RHFTextField
                                            size="small"
                                            name={`products[${index}].quantity`}
                                            label={`${translate('quantity')}`}
                                            value={product.quantity}
                                            placeholder="0.00"
                                            disabled={
                                              currentOrder
                                                ? currentOrder?.status !==
                                                  'draft'
                                                : false
                                            }
                                            onChange={(event) =>
                                              changeProductQuantity(
                                                product,
                                                Number(event.target.value)
                                              )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                              type: 'number',
                                            }}
                                            sx={{ maxWidth: { md: 96 } }}
                                          />
                                        </Grid>
                                        <Grid item md={2}>
                                          <RHFTextField
                                            disabled={true}
                                            size="small"
                                            name={`products[${index}].total`}
                                            label="Total"
                                            placeholder="0.00"
                                            value={
                                              Number(
                                                product.variants?.[0]?.price ||
                                                  product.amount ||
                                                  product.price
                                              ) * (product.quantity || 0) || 0
                                            }
                                            onChange={(event) =>
                                              changeProductTotal(
                                                product,
                                                Number(event.target.value)
                                              )
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="start">
                                                  €
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{ maxWidth: { md: 96 } }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {(currentOrder?.status === 'draft' ||
                                      !currentOrder) && (
                                      <Button
                                        size="small"
                                        color="error"
                                        startIcon={
                                          <Iconify icon="eva:trash-2-outline" />
                                        }
                                        onClick={() => removeProduct(product)}
                                      >
                                        {`${translate('remove')}`}
                                      </Button>
                                    )}
                                  </Stack>
                                </>
                              ))}
                              {currentOrder?.status !== 'draft' &&
                                isArray(subProducts) &&
                                subProducts[0].toFulfill &&
                                isCancelled(currentOrder) && (
                                  <>
                                    <Button
                                      size="small"
                                      color="info"
                                      startIcon={
                                        <Iconify icon="eva:car-outline" />
                                      }
                                      onClick={() => {
                                        setOpenFulfillmentDialog(true);

                                        setSelectedProducts(subProducts);
                                      }}
                                    >
                                      {`${translate('fulfill')}`}
                                    </Button>
                                    <FulfillmentDialog
                                      fulfill={() => {
                                        window.location.reload();
                                      }}
                                      products={selectedProducts || []}
                                      onClose={() => {
                                        setOpenFulfillmentDialog(false);
                                      }}
                                      open={openFulfillmentDialog}
                                    />
                                  </>
                                )}
                              {currentOrder?.status !== 'draft' &&
                                isArray(subProducts) &&
                                !subProducts[0].toFulfill &&
                                subProducts[0]?.fulfillment?.status !==
                                  'open' &&
                                !subProducts[0].returnStatus &&
                                subProducts[0].fulfillmentLine.fulfilled_items
                                  .length > 0 && (
                                  <>
                                    <Button
                                      size="small"
                                      color="error"
                                      startIcon={
                                        <Iconify icon="eva:car-outline" />
                                      }
                                      onClick={async () => {
                                        await cancelFulfillment(
                                          subProducts[0]?.fulfillment?.id
                                        );
                                        window.location.reload();
                                      }}
                                    >
                                      {`${translate('cancelFulfillment')}`}
                                    </Button>
                                  </>
                                )}
                              {currentOrder?.status !== 'draft' &&
                                isArray(subProducts) &&
                                !subProducts[0].toFulfill &&
                                subProducts[0].fulfillment?.status !== 'open' &&
                                !subProducts[0].returnStatus &&
                                returnableFulfillments.find(
                                  (e) =>
                                    e.fulfillmentId ===
                                    subProducts[0].fulfillmentLine
                                      .fulfillment_id
                                )?.fulfillmentId && (
                                  <>
                                    <Button
                                      size="small"
                                      color="warning"
                                      startIcon={
                                        <Iconify icon="eva:car-outline" />
                                      }
                                      onClick={() => {
                                        setOpenReturnDialog(true);
                                        setSelectedProducts(subProducts);
                                      }}
                                    >
                                      {`${translate('createReturn')}`}
                                    </Button>
                                    <ReturnDialog
                                      onReturn={() => {
                                        window.location.reload();
                                      }}
                                      order={currentOrder!}
                                      products={selectedProducts || []}
                                      returnableFulfillments={
                                        returnableFulfillments
                                      }
                                      onClose={() => {
                                        setOpenReturnDialog(false);
                                      }}
                                      open={openReturnDialog}
                                    />
                                  </>
                                )}
                              {currentOrder?.status !== 'draft' &&
                                isArray(subProducts) &&
                                !subProducts[0].toFulfill &&
                                subProducts[0]?.fulfillment?.status !==
                                  'open' &&
                                subProducts[0]?.returnExternalId &&
                                subProducts[0]?.returnStatus === 'opened' && (
                                  <>
                                    <Button
                                      size="small"
                                      color="error"
                                      startIcon={
                                        <Iconify icon="eva:car-outline" />
                                      }
                                      onClick={async () => {
                                        await closeOrCancelReturn(
                                          subProducts[0]?.returnExternalId,
                                          'cancel'
                                        );

                                        window.location.reload();
                                      }}
                                    >
                                      {`${translate('cancelReturn')}`}
                                    </Button>
                                    <Button
                                      size="small"
                                      color="info"
                                      startIcon={
                                        <Iconify icon="eva:car-outline" />
                                      }
                                      onClick={async () => {
                                        await closeOrCancelReturn(
                                          subProducts[0]?.returnExternalId,
                                          'close'
                                        );

                                        window.location.reload();
                                      }}
                                    >
                                      {`${translate('closeReturn')}`}
                                    </Button>
                                  </>
                                )}
                              {currentOrder?.status !== 'draft' &&
                                isArray(subProducts) &&
                                !subProducts[0].toFulfill &&
                                subProducts[0]?.fulfillment?.status !==
                                  'open' &&
                                subProducts[0]?.returnExternalId &&
                                subProducts[0]?.returnStatus === 'closed' && (
                                  <>
                                    <Button
                                      size="small"
                                      color="error"
                                      startIcon={
                                        <Iconify icon="eva:car-outline" />
                                      }
                                      onClick={async () => {
                                        await closeOrCancelReturn(
                                          subProducts[0]?.returnExternalId,
                                          'reopen'
                                        );

                                        window.location.reload();
                                      }}
                                    >
                                      {`${translate('reOpenReturn')}`}
                                    </Button>
                                  </>
                                )}
                            </>
                          ))}
                        </Stack>

                        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

                        {(currentOrder?.status === 'draft' ||
                          !currentOrder) && (
                          <>
                            <Button
                              sx={{ flexShrink: 0 }}
                              startIcon={
                                <Iconify
                                  icon={
                                    customer ? 'eva:edit-fill' : 'eva:plus-fill'
                                  }
                                />
                              }
                              onClick={() => setOpenDiscountDialog(true)}
                            >
                              {`${translate(
                                discount
                                  ? 'orderCreatePage.editDiscount'
                                  : 'orderCreatePage.addDiscount'
                              )}`}
                            </Button>
                            <DiscountDialog
                              open={openDiscountDialog}
                              discount={discount}
                              onClose={() => setOpenDiscountDialog(false)}
                              onSelect={(data: IDiscount | undefined) => {
                                setDiscount(
                                  data?.amount === 0 ? undefined : data
                                );
                              }}
                            />
                          </>
                        )}

                        {customerAddress &&
                          (currentOrder?.status === 'draft' ||
                            !currentOrder) && (
                            <>
                              <Button
                                sx={{ flexShrink: 0 }}
                                startIcon={
                                  <Iconify
                                    icon={
                                      customer
                                        ? 'eva:edit-fill'
                                        : 'eva:plus-fill'
                                    }
                                  />
                                }
                                onClick={() => setOpenShippingDialog(true)}
                              >
                                {`${translate(
                                  shippingLine?.title
                                    ? 'orderCreatePage.editShipping'
                                    : 'orderCreatePage.addShipping'
                                )}`}
                              </Button>
                              <ShippingDialog
                                open={openShippingDialog}
                                products={products.flatMap(
                                  (p) => p.variants?.[0] || p
                                )}
                                shippingAddress={customerAddress}
                                shippingLine={shippingLine}
                                onClose={() => setOpenShippingDialog(false)}
                                onSelect={(data: IShippingLine | undefined) => {
                                  setShippingLine(data);
                                }}
                              />
                            </>
                          )}

                        <Stack spacing={2} sx={{ mt: 3 }}>
                          <Stack direction="row" justifyContent="flex-end">
                            <Typography>
                              {`${translate('orderCreatePage.subTotal')}`} :
                            </Typography>
                            <Typography sx={{ textAlign: 'right', width: 120 }}>
                              {fCurrency(computeTotalPrice(false, false)) ||
                                '-'}
                            </Typography>
                          </Stack>

                          <Stack direction="row" justifyContent="flex-end">
                            <Typography>
                              {`${translate('discount')}`} :
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: 'right',
                                width: 120,
                                ...(discount ? { color: 'red' } : {}),
                              }}
                            >
                              {discount
                                ? `- ${fCurrency(
                                    computeDiscountAmount(discount)
                                  )}`
                                : '-'}
                            </Typography>
                          </Stack>

                          <Stack direction="row" justifyContent="flex-end">
                            <Typography>
                              {`${translate('orderCreatePage.shipping')}`} :
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: 'right',
                                width: 120,
                                ...(shippingLine?.title
                                  ? { color: 'orange' }
                                  : {}),
                              }}
                            >
                              {currentOrder
                                ? `${calculateShippingPrice(
                                    currentOrder?.amount ?? 0,
                                    computeTotalPrice(false, false)
                                  )}`
                                : `${
                                    shippingLine?.title
                                      ? `+ ${fCurrency(shippingLine?.price)}`
                                      : '-'
                                  }`}
                            </Typography>
                          </Stack>

                          <Stack direction="row" justifyContent="flex-end">
                            <Typography>
                              {`${translate('orderCreatePage.taxes')}`} :
                            </Typography>
                            <Typography sx={{ textAlign: 'right', width: 120 }}>
                              {currentOrder
                                ? `${fCurrency(currentOrder?.taxes || 0)}`
                                : `${fCurrency(computeTaxes())}`}
                            </Typography>
                          </Stack>

                          <Stack direction="row" justifyContent="flex-end">
                            <Typography variant="h6">
                              {`${translate('orderCreatePage.totalPrice')}`} :
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ textAlign: 'right', width: 120 }}
                            >
                              {currentOrder
                                ? `${fCurrency(currentOrder.amount)}`
                                : `${fCurrency(computeTotalPrice(true, true))}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <ProductsListDialog
                  open={openProductDialog}
                  searchName={productSearchName}
                  products={searchedProducts}
                  onClose={() => {
                    setSearchedProducts([]);
                    setOpenProductDialog(false);
                  }}
                  onSelect={(product: IProduct | undefined) => {
                    if (product) {
                      addProduct({
                        ...product,
                        quantity: 1,
                      });
                    }

                    setProductSearchName('');

                    setOpenProductDialog(false);
                  }}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h6">
                    {`${translate('customer')}`}
                  </Typography>

                  <Button
                    size="small"
                    disabled={!isOwnClient}
                    startIcon={
                      <Iconify
                        icon={customer ? 'eva:edit-fill' : 'eva:plus-fill'}
                      />
                    }
                    onClick={() => setOpenCustomerDialog(true)}
                  >
                    {`${translate(customer ? 'edit' : 'add')}`}
                  </Button>

                  <CustomerListDialog
                    open={openCustomerDialog}
                    onClose={() => setOpenCustomerDialog(false)}
                    onSelect={(customer: ICustomer | undefined) => {
                      setCustomer(customer);
                      setOpenCustomerDialog(false);
                    }}
                  />
                </Stack>
                {customer && (
                  <>
                    <Stack
                      spacing={0.5}
                      alignItems="flex-start"
                      sx={{ p: 3, typography: 'body2' }}
                    >
                      <Typography variant="subtitle2">
                        {customer.first_name} {customer.last_name}
                      </Typography>

                      <Box sx={{ color: 'text.secondary' }}>
                        {customer.email}
                      </Box>
                    </Stack>

                    <Divider sx={{ borderStyle: 'dashed' }} />

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 1, pt: 2 }}
                    >
                      <Typography variant="h6" sx={{ pt: 1 }}>
                        {`${translate('address')}`}
                      </Typography>

                      <Button
                        size="small"
                        disabled={!isOwnClient}
                        startIcon={
                          <Iconify
                            icon={customer ? 'eva:edit-fill' : 'eva:plus-fill'}
                          />
                        }
                        onClick={() => setOpenAddressDialog(true)}
                      >
                        {`${translate('edit')}`}
                      </Button>

                      <CustomerQuickEditForm
                        key={customer.id}
                        open={openAddressDialog}
                        currentCustomer={customer}
                        onClose={(customer?: ICustomer) => {
                          if (customer) {
                            setCustomer(customer);
                          }

                          setOpenAddressDialog(false);
                        }}
                      />
                    </Stack>

                    <Stack
                      spacing={0.5}
                      alignItems="flex-start"
                      sx={{ p: 3, typography: 'body2' }}
                    >
                      <Typography variant="subtitle2">
                        {customerAddress
                          ? `${customerAddress.address1} ${
                              customerAddress?.address2 || ''
                            }`
                          : `${translate('no_address')}`}
                      </Typography>

                      {customerAddress && (
                        <Box sx={{ color: 'text.secondary' }}>
                          {customerAddress.city}, {customerAddress.zip},{' '}
                          {customerAddress.country}
                        </Box>
                      )}
                      {customer.phone && (
                        <Box
                          component="span"
                          sx={{
                            color: 'text.secondary',
                            width: 120,
                            flexShrink: 0,
                          }}
                        >
                          {customer.phone}
                        </Box>
                      )}
                    </Stack>
                  </>
                )}
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mb: 1, pt: 2 }}
                  >
                    <Typography variant="h6">
                      {`${translate('payment')}`}
                    </Typography>

                    <Button
                      size="small"
                      startIcon={
                        <Iconify
                          icon={
                            paymentTerms ? 'eva:edit-fill' : 'eva:plus-fill'
                          }
                        />
                      }
                      onClick={() => setOpenDelayedPaymentDialog(true)}
                    >
                      {`${translate(paymentTerms ? 'edit' : 'add')}`}
                    </Button>

                    <DelayedPaymentDialog
                      open={openDelayedPaymentDialog}
                      currentPaymentTerm={
                        paymentTerms || currentOrder?.payment_terms
                      }
                      onClose={() => setOpenDelayedPaymentDialog(false)}
                      onSelect={(data) => {
                        setPaymentTerms(data);
                      }}
                    />
                  </Stack>
                  {paymentTerms && (
                    <>
                      <Stack
                        spacing={0.5}
                        alignItems="flex-start"
                        sx={{ p: 3, typography: 'body2' }}
                      >
                        {paymentTerms.payment_terms_type === 'net' && (
                          <>
                            <Typography variant="subtitle2">{`${translate(
                              'orderCreatePage.paymentTerms.net'
                            )} ${paymentTerms.due_in_days} ${translate(
                              'orderCreatePage.paymentTerms.days'
                            )}`}</Typography>
                            <Box sx={{ color: 'text.secondary' }}>
                              {`${translate(
                                'orderCreatePage.paymentTerms.issued_at'
                              )}`}{' '}
                              {paymentTerms.payment_schedule.issued_at}
                            </Box>
                            <Box sx={{ color: 'text.secondary' }}>
                              {`${translate(
                                'orderCreatePage.paymentTerms.due_at'
                              )}`}{' '}
                              {paymentTerms.payment_schedule.due_at}
                            </Box>
                          </>
                        )}
                        {paymentTerms.payment_terms_type === 'fixed' && (
                          <Typography variant="subtitle2">
                            {`${translate(
                              'orderCreatePage.paymentTerms.payment_on'
                            )}`}{' '}
                            {paymentTerms.payment_schedule.due_at}
                          </Typography>
                        )}
                        {paymentTerms.payment_terms_type === 'fulfillment' && (
                          <Typography variant="subtitle2">{`${translate(
                            'orderCreatePage.paymentTerms.fulfillment'
                          )}`}</Typography>
                        )}
                        {paymentTerms.payment_terms_type === 'receipt' && (
                          <Typography variant="subtitle2">{`${translate(
                            'orderCreatePage.paymentTerms.receipt'
                          )}`}</Typography>
                        )}
                      </Stack>
                    </>
                  )}
                </Stack>
                {(currentOrder?.status === 'draft' || !currentOrder) && (
                  <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Stack spacing={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 1, pt: 2 }}
                      >
                        <Typography variant="h6">
                          {`${translate(
                            'orderCreatePage.reservedInventory.title'
                          )}`}
                        </Typography>

                        <Button
                          size="small"
                          startIcon={
                            <Iconify
                              icon={
                                paymentTerms ? 'eva:edit-fill' : 'eva:plus-fill'
                              }
                            />
                          }
                          onClick={() => setOpenReserveDialog(true)}
                        >
                          {`${translate(
                            reservedInventoryUntil ? 'edit' : 'add'
                          )}`}
                        </Button>

                        <ReserveInventoryDialog
                          open={openReserveDialog}
                          currentDate={reservedInventoryUntil}
                          onClose={() => setOpenReserveDialog(false)}
                          onSelect={(data) => {
                            setReservedInventoryUntil(data);
                          }}
                        />
                      </Stack>
                      {reservedInventoryUntil && (
                        <Stack
                          spacing={0.5}
                          alignItems="flex-start"
                          sx={{ p: 3, typography: 'body2' }}
                        >
                          <Typography variant="subtitle2">
                            {`${translate(
                              'orderCreatePage.reservedInventory.reservedInventoryUntil'
                            )}`}{' '}
                            {reservedInventoryUntil}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </>
                )}
                {currentOrder?.status !== 'draft' && currentOrder && (
                  <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Stack spacing={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 1, pt: 2 }}
                      >
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h6">
                              {`${translate('orderCreatePage.tags')}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} sx={{ p: 3 }}>
                            <RHFAutocomplete
                              name="tags"
                              label={`${translate(
                                'orderCreatePage.fields.tags'
                              )}`}
                              multiple
                              freeSolo
                              limitTags={2}
                              options={ORDER_TAGS.map((tag) =>
                                tag.replace('osp:', '').trim()
                              )}
                              onChange={(_e, values) => {
                                const mappedValues = ORDER_TAGS.filter(
                                  (e) =>
                                    values.find((v) =>
                                      e.toLowerCase().includes(v.toLowerCase())
                                    ) !== undefined
                                );

                                changeTags(_.uniq(mappedValues));
                              }}
                              renderTags={(
                                value: readonly string[],
                                getTagProps
                              ) =>
                                value.map((option: string, index: number) => (
                                  <Chip
                                    {...getTagProps({ index })}
                                    size="small"
                                    variant="soft"
                                    label={option}
                                    key={option}
                                  />
                                ))
                              }
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          </Card>
          {customer && (
            <>
              {isEdit &&
                currentOrder?.status === 'draft' &&
                !paymentTerms &&
                products.length > 0 && (
                  <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={2}
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      color="info"
                      size="large"
                      variant="contained"
                      onClick={() => setOpenInvoiceDialog(true)}
                    >
                      {`${translate('orderCreatePage.sendInvoice')}`}
                    </LoadingButton>
                  </Stack>
                )}
              {isEdit &&
                currentOrder?.status === 'draft' &&
                products.length > 0 && (
                  <>
                    <Stack
                      justifyContent="center"
                      direction="row"
                      spacing={2}
                      sx={{ mt: 3 }}
                    >
                      <LoadingButton
                        color="info"
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                      >
                        {`${translate('orderCreatePage.update')}`}
                      </LoadingButton>
                    </Stack>
                    <Stack
                      justifyContent="center"
                      direction="row"
                      spacing={2}
                      sx={{ mt: 3 }}
                    >
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        onClick={handleSubmit(onComplete)}
                      >
                        {`${translate(
                          paymentTerms
                            ? 'orderCreatePage.completeOrder'
                            : 'orderCreatePage.markAsPaid'
                        )}`}
                      </LoadingButton>
                    </Stack>
                  </>
                )}
              {isEdit &&
                !currentOrder?.paid &&
                currentOrder?.status !== 'draft' &&
                !isCancelled(currentOrder) &&
                products.length > 0 && (
                  <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={2}
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={handleSubmit(onPay)}
                    >
                      {`${translate('orderCreatePage.markAsPaid')}`}
                    </LoadingButton>
                  </Stack>
                )}
              {isEdit &&
                currentOrder?.status !== 'draft' &&
                !isCancelled(currentOrder) &&
                products.length > 0 && (
                  /*(currentOrder?.order_fulfillments?.filter(e => e.status === 'closed')?.length || 0) === 0 &&*/ <Stack
                    justifyContent="center"
                    direction="row"
                    spacing={2}
                    sx={{ mt: 3 }}
                  >
                    <Button
                      color="error"
                      size="large"
                      variant="contained"
                      onClick={() => {
                        setOpenCancelDialog(true);
                      }}
                    >
                      {`${translate('orderCreatePage.cancel')}`}
                    </Button>
                  </Stack>
                )}

              <Stack
                justifyContent="center"
                direction="row"
                spacing={2}
                sx={{ mt: 3 }}
              >
                {hasPermission(user, PERMISSIONS.orderDelete) && isEdit && (
                  <div>
                    <Button
                      color="error"
                      size="large"
                      variant="contained"
                      onClick={() => setOpenDeleteDialog(true)}
                    >
                      {`${translate('delete')}`}
                    </Button>
                    <DeleteDialog
                      open={openDeleteDialog}
                      onClose={() => {
                        setOpenDeleteDialog(false);
                      }}
                      onDelete={() => {
                        deleteOrder(currentOrder!);
                        setOpenDeleteDialog(false);
                      }}
                    />
                  </div>
                )}
                {!isEdit && products.length > 0 && (
                  <LoadingButton
                    size="large"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={handleSubmit(draftOrder)}
                  >
                    {`${translate('orderCreatePage.createOrder')}`}
                  </LoadingButton>
                )}
              </Stack>
              <InvoiceDialog
                key={customer.id}
                open={openInvoiceDialog}
                orderId={currentOrder?.id!}
                customerEmail={customer?.email}
                userEmail={'contact@flashvideofilm.fr'}
                onClose={() => {
                  setOpenInvoiceDialog(false);
                }}
              />

              <CancelDialog
                open={openCancelDialog}
                onClose={() => {
                  setOpenCancelDialog(false);
                }}
                data={currentOrder?.amount || 0}
                onSelect={(data: IRefundLine | undefined) => {
                  setCancelAmount(data?.cancel_amount);

                  handleSubmit(onCancel)();
                  // console.log('Cancel Amount : ', data?.cancel_amount);
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
