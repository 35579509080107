// @mui
import {
  Autocomplete,
  Box,
  Card,
  IconButton,
  Modal,
  Stack,
  TableBody,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
// _mock_
// components
import Iconify from 'src/components/iconify/Iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from 'src/components/table';
// sections
// routes
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { ROLES } from 'src/utils/permissions';

import {
  Button,
  Container,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/useAuthContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import {
  createLocationLinks,
  deleteLocationLinks,
  getLocationLinks,
  getLocationTypes,
  getLocationsRaw,
} from 'src/redux/slices/location';
import { ILocation, ILocationLinks, ILocationTypes } from 'src/@types/location';

export default function LocationLinkPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openAddLocationType, setOpenAddLocationType] = React.useState(false);
  const handleOpenAddLocationType = () => setOpenAddLocationType(true);
  const handleCloseAddLocationType = () => setOpenAddLocationType(false);

  const [indexSelectedRow, setIndexSelectedRow] = useState<number>();
  const [locationLink, setLocationLink] = useState<ILocation | null>(null);

  const [openAddLocationsLink, setOpenLocationsLink] = React.useState(false);
  const handleOpenLocationsLink = () => setOpenLocationsLink(true);
  const handleCloseLocationsLink = () => {
    setLocationLink(null);
    setIndexSelectedRow(undefined);
    setOpenLocationsLink(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [locations, setLocations] = useState<ILocation[]>([]);
  const [locationsLink, setLocationsLink] = useState<ILocationLinks[]>([]);
  const [locationsType, setLocationsType] = useState<ILocationTypes[]>([]);

  const [selectedLocationType, setSelectedLocationType] =
    useState<ILocationTypes | null>(null);

  useEffect(() => {
    const fetchLocationsType = async () => {
      setLocationsType(await getLocationTypes());
    };

    const fetchLocations = async () => {
      setLocations(await getLocationsRaw({}, 1000, 1));
    };

    const fetchLocationsLink = async () => {
      setLocationsLink(await getLocationLinks());
    };

    fetchLocationsType();
    fetchLocations();
    fetchLocationsLink();
  }, []);

  const TABLE_HEAD = [
    {
      id: 'locationsType',
      label: `${translate('locationLinkPage.fields.locationsType')}`,
      align: 'left',
    },
    {
      id: 'locations',
      label: `${translate('locationLinkPage.fields.locations')}`,
      align: 'left',
    },
    {
      id: 'delete',
      align: 'right',
    },
  ];

  const { companies, pagination } = useSelector((state) => state.company);

  const isNotFound = !companies?.length;

  const handleOpenChangeLocationsLink = useCallback((index: number) => {
    setIndexSelectedRow(index);
    handleOpenLocationsLink();
  }, []);

  const handleOpenConfirm = (index: number) => {
    setIndexSelectedRow(index);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // dispatch(getCompaniesSlice(currentQuery, rowsPerPage, newPage + 1 || 1));
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(
    //   getCompaniesSlice(currentQuery, Number(event.target.value), page + 1 || 1)
    // );
    setRowsPerPage(Number(event.target.value));
  };

  const navigate = useNavigate();

  if (ROLES.ADMIN.label !== user?.role) {
    navigate(PATH_PAGE.page403);
  }

  const handleAddLocationType = () => {
    if (selectedLocationType) {
      const isDuplicate = locationsLink.some(
        (link) => link.locationTypeId === selectedLocationType.id
      );

      if (!isDuplicate) {
        const newLocationsLink: ILocationLinks = {
          locationTypeId: selectedLocationType.id,
          locationExternalId: '',
        };

        setLocationsLink((prevLocationsLink) => [
          ...prevLocationsLink,
          newLocationsLink,
        ]);
        handleCloseAddLocationType();
        setIsEdit(true);
      } else {
        alert('Cet emplacement types est déjà présent.');
      }
    }
  };

  const handleLocationChange = () => {
    if (typeof indexSelectedRow !== 'undefined') {
      const updatedLocationsLink = [...locationsLink];

      updatedLocationsLink[indexSelectedRow] = {
        ...updatedLocationsLink[indexSelectedRow],
        locationExternalId: locationLink?.external_id || '',
      };

      setLocationsLink(updatedLocationsLink);
    }

    setIndexSelectedRow(undefined);
    setLocationLink(null);
    handleCloseLocationsLink();
    setIsEdit(true);
  };

  const findLocationTypeNameById = (id: string): string | undefined => {
    const locationType = locationsType.find((pub) => pub.id === id);
    return locationType ? locationType.name : undefined;
  };

  const findLocationNameById = (id: string): string | undefined => {
    const location = locations.find((pub) => pub.external_id === id);
    return location ? location.name : undefined;
  };

  useEffect(() => {
    if (indexSelectedRow !== undefined) {
      const selectedLocationId =
        locationsLink[indexSelectedRow]?.locationExternalId || '';
      const selectedLocation = locations.filter((location) =>
        selectedLocationId.includes(location.external_id)
      );
      setLocationLink(selectedLocation[0]);
    }
  }, [indexSelectedRow, locationsLink]);

  // console.log('LocationLink', locationLink);

  const onSubmit = async (data: any) => {
    try {
      const pushData = data.map((item: any) => ({
        locationTypeId: item.locationTypeId,
        locationExternalId: item.locationExternalId,
        id: item.id || undefined,
        siteId: item.siteId || undefined,
      }));

      // console.log(pushData);

      await createLocationLinks(pushData);

      enqueueSnackbar(`${translate('locationLinkPage.updateSuccess')}`);

      window.location.reload();
      // navigate(PATH_DASHBOARD.companies.list);
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async (id: string) => {
    try {
      await deleteLocationLinks(id);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleDeleteLocationLink = async () => {
    if (indexSelectedRow !== undefined && locationsLink[indexSelectedRow]) {
      const deletedId = locationsLink[indexSelectedRow].id;
      if (deletedId) {
        const deleteSuccess = await onDelete(deletedId);
        if (deleteSuccess) {
          setLocationsLink((prevLocationsLink) =>
            prevLocationsLink.filter((_, i) => i !== indexSelectedRow)
          );
          setIndexSelectedRow(undefined);
          setOpenConfirm(false);
        }
      } else {
        setLocationsLink((prevLocationsLink) =>
          prevLocationsLink.filter((_, i) => i !== indexSelectedRow)
        );
        setIndexSelectedRow(undefined);
        setOpenConfirm(false);
      }
    }
  };

  // console.log(locationsLink);

  return (
    <>
      <Helmet>
        <title> {`${translate('locationLinkPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('locationLinkPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('locationLinkPage.breadcrumbs')}` },
            { name: `${translate('list')}` },
          ]}
          action={
            <Button
              onClick={handleOpenAddLocationType}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {`${translate('locationLinkPage.addButton')}`}
            </Button>
          }
        />

        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={locationsLink.length}
                  numSelected={selected.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      companies.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {locationsLink.length > 0 ? (
                    locationsLink.map((link, index) => (
                      <TableRow
                        key={index}
                        sx={{ position: 'relative', zIndex: '999' }}
                      >
                        <TableCell>
                          {findLocationTypeNameById(link.locationTypeId)}
                        </TableCell>
                        <TableCell>
                          {findLocationNameById(link.locationExternalId)}
                          <Button
                            sx={{ ml: '6px' }}
                            onClick={() => {
                              handleOpenChangeLocationsLink(index);
                            }}
                          >
                            {link.locationExternalId.length >= 1
                              ? `${translate('locationLinkPage.edit')}`
                              : `${translate('locationLinkPage.add')}`}
                          </Button>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            color="error"
                            onClick={() => {
                              handleOpenConfirm(index);
                            }}
                          >
                            <Iconify icon="eva:trash-2-outline" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableNoData isNotFound={isNotFound} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || locationsLink?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>

        <Stack sx={{ marginTop: '10px' }}>
          <Button
            disabled={!isEdit}
            variant="contained"
            onClick={() => {
              onSubmit(locationsLink);
            }}
          >
            {`${translate('locationLinkPage.save')}`}
          </Button>
        </Stack>
      </Container>

      <Modal
        open={openAddLocationType}
        onClose={handleCloseAddLocationType}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4">{`${translate(
              'locationLinkPage.addModalTitle'
            )}`}</Typography>

            <Autocomplete
              disablePortal
              id="locations-type"
              options={locationsType}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_event, newValue) => {
                setSelectedLocationType(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={`${translate('locations')}`} />
              )}
            />

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button onClick={handleCloseAddLocationType} size="large">
                {`${translate('locationLinkPage.cancel')}`}
              </Button>
              <Button
                onClick={handleAddLocationType}
                variant="contained"
                size="large"
              >
                {`${translate('locationLinkPage.add')}`}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openAddLocationsLink}
        onClose={handleCloseLocationsLink}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4">{`${translate(
              'locationLinkPage.linkModalTitle'
            )}`}</Typography>

            <Autocomplete
              disablePortal
              id="location-link"
              options={locations}
              value={locationLink}
              onChange={(_event, newValue) => {
                setLocationLink(newValue);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${translate('locationLinkPage.fields.locations')}`}
                />
              )}
            />

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button onClick={handleCloseLocationsLink} size="large">
                {`${translate('locationLinkPage.cancel')}`}
              </Button>
              <Button
                onClick={handleLocationChange}
                variant="contained"
                size="large"
              >
                {`${translate('locationLinkPage.confirm')}`}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('locationLinkPage.deleteMessage')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteLocationLink}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
