import { Helmet } from 'react-helmet-async';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify/Iconify';
import { Stack, padding } from '@mui/system';
import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Icon } from '@iconify/react';

export default function AppPage() {
  const { translate } = useLocales();

  type IconifyIcon = string;

  interface CardData {
    icon: IconifyIcon;
    title: string;
    description: string;
  }

  const cards: CardData[] = [
    {
      icon: 'mdi:post-outline', // Icône représentant un article de blog ou un post
      title: `${translate('upComingAppsPage.web.blogApp.title')}`,
      description: `${translate('upComingAppsPage.web.blogApp.description')}`,
    },
    {
      icon: 'mdi:forum-outline', // Icône représentant un forum de discussion
      title: `${translate('upComingAppsPage.web.forumApp.title')}`,
      description: `${translate('upComingAppsPage.web.forumApp.description')}`,
    },
  ];

  const cardsVente: CardData[] = [];

  const cardsFinances: CardData[] = [
    {
      icon: 'mdi:receipt', // Icône représentant une facture
      title: `${translate('upComingAppsPage.finance.facturing.title')}`,
      description: `${translate(
        'upComingAppsPage.finance.facturing.description'
      )}`,
    },
    {
      icon: 'mdi:table', // Icône représentant une feuille de calcul
      title: `${translate('upComingAppsPage.finance.spreadsheet.title')}`,
      description: `${translate(
        'upComingAppsPage.finance.spreadsheet.description'
      )}`,
    },
  ];

  const cardsInvFab: CardData[] = [
  ];

  const cardsMarketing: CardData[] = [
  ];

  const cardsServices: CardData[] = [
  ];

  const cardsProd: CardData[] = [
    {
      icon: 'mdi:thumb-up-outline', // Icône représentant une validation ou une approbation
      title: `${translate('upComingAppsPage.productivity.validation.title')}`,
      description: `${translate(
        'upComingAppsPage.productivity.validation.description'
      )}`,
    },
    {
      icon: 'mdi:import*', // Icône représentant une action d'importation
      title: `${translate('upComingAppsPage.productivity.import.title')}`,
      description: `${translate(
        'upComingAppsPage.productivity.import.description'
      )}`,
    },
  ];

  return (
    <>
      <Helmet>
        <title> {`${translate('upComingAppsPage.title')}`} </title>
      </Helmet>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Typography
            variant="h3"
            paragraph
            align="center"
            sx={{ marginTop: '70px', marginBottom: '100px' }}
          >
            {`${translate('upComingAppsPage.appUpCommingList')}`}
          </Typography>

          <Grid container spacing={12} sx={{ maxWidth: '1000px' }}>
            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.web.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.web.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cards.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.sale.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.sale.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsVente.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.finance.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.finance.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsFinances.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.inventory.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.inventory.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsInvFab.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            {/* <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.marketing.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.marketing.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsMarketing.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.services.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.services.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsServices.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.productivity.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.productivity.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsProd.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <div>
                          <Icon
                            icon={card.icon}
                            width={32}
                            style={{ marginRight: 16 }}
                          />
                        </div>
                        <div>
                          <Typography variant="h6">{card.title}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {card.description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Typography variant="h4" sx={{ marginTop: '80px' }} align="center">
            {`${translate('upComingAppsPage.footer.line1')}`} <br />
            {`${translate('upComingAppsPage.footer.line2')}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
