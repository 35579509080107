// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { fulfillOrder } from 'src/redux/slices/order';
import * as Yup from 'yup';
import Image from '../../../components/image';
import { CustomProduct } from './OrderNewEditForm';

// ----------------------------------------------------------------------

type ISchema = {
  trackingNumber: string;
  trackingUrl: string;
};

type Props = {
  open: boolean;
  products: CustomProduct[];
  onClose: VoidFunction;
  fulfill: VoidFunction;
};

export default function FulfillmentDialog({
  onClose,
  open,
  products,
  fulfill,
}: Props) {
  const { translate } = useLocales();

  const [localProducts, setLocalProducts] = useState<
    (CustomProduct & { maxQuantity: number })[]
  >((products || []).map((p) => ({ ...p, maxQuantity: p.quantity || 0 })));

  const schema = Yup.object().shape({
    trackingNumber: Yup.string(),
    trackingUrl: Yup.string(),
  });

  useEffect(() => {
    setLocalProducts(
      (products || []).map((p) => ({ ...p, maxQuantity: p.quantity || 0 }))
    );
  }, [products, setLocalProducts]);

  const defaultValues = useMemo<ISchema>(
    () => ({
      trackingNumber: '',
      trackingUrl: '',
    }),
    []
  );

  const methods = useForm<ISchema>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const changeProductQuantity = (product: CustomProduct, quantity: number) => {
    const newProducts = localProducts.flatMap((p) => {
      if (p.external_id === product.external_id) {
        return {
          ...p,
          quantity:
            quantity === 0
              ? undefined
              : quantity > p.maxQuantity
                ? p.maxQuantity
                : quantity,
        };
      }

      return p;
    });

    setLocalProducts(newProducts);
  };

  const onSubmit = handleSubmit(async (data: ISchema) => {
    const fulfillment = localProducts[0].fulfillment;

    console.log('fulfillment', fulfillment);
    if (!fulfillment) return;

    console.log(localProducts);

    await fulfillOrder(fulfillment.order_id, {
      fulfillmentOrderId: fulfillment.id,
      trackingNumber: data.trackingNumber,
      trackingUrl: data.trackingUrl,
      lines: localProducts
        .filter((l) => (l.quantity || 0) > 0)
        .map((p) => ({
          id: String(
            fulfillment.line_items.find(
              (i) => String(i.line_item_id) === String(p.external_id)
            )?.id || ''
          ),
          quantity: p.quantity || 0,
          lineItemId: String(
            fulfillment.line_items.find(
              (i) => String(i.line_item_id) === String(p.external_id)
            )?.line_item_id || ''
          ),
        })),
    });

    onClose();
    fulfill();
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('fulfillmentDialog.title')}`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              {localProducts.map((product, index) => (
                <>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems="center"
                    spacing={2}
                    sx={{ width: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={1}>
                        {product?.images?.[0]?.src || product?.image?.src ? (
                          <Image
                            disabledEffect
                            visibleByDefault
                            alt={product.title}
                            src={
                              product?.images?.[0]?.src || product?.image?.src
                            }
                            sx={{ borderRadius: 1.5, width: 48, height: 48 }}
                          />
                        ) : (
                          <Iconify
                            icon="eva:file-remove-outline"
                            sx={{
                              width: 48,
                              height: 48,
                              borderRadius: 1.5,
                              mr: '2em',
                            }}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        md={8}
                        sx={{
                          ml: '1em',
                          mt: product.variants[0]?.sku ? '-.5em' : '.5em',
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{
                            my: 0.5,
                            color: 'info.main',
                            fontWeight: 'fontWeightMedium',
                          }}
                        >
                          {product.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{
                            my: 0.5,
                            color: 'info.main',
                            fontWeight: 'fontWeightMedium',
                          }}
                        >
                          {product.variants?.[0]?.sku
                            ? `SKU: ${product.variants[0].sku} - `
                            : ''}
                          {product.variants?.[0]?.barcode
                            ? `Barcode: ${product.variants[0].barcode}`
                            : ''}
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <RHFTextField
                          size="small"
                          name={`products[${index}].quantity`}
                          label={`${translate('quantity')}`}
                          value={product.quantity}
                          placeholder="0"
                          onChange={(event) =>
                            changeProductQuantity(
                              product,
                              Number(event.target.value)
                            )
                          }
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            type: 'number',
                            endAdornment: (
                              <InputAdornment position="start">
                                /{product.maxQuantity}
                              </InputAdornment>
                            ),
                          }}
                          sx={{ maxWidth: { md: 96 } }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              ))}
            </Grid>

            <Grid item xs={12} md={12} sx={{ mt: '2em' }}>
              <RHFTextField
                name="trackingNumber"
                label={`${translate('fulfillmentDialog.trackingNumber')}`}
                sx={{ mt: '1em' }}
              />
              <RHFTextField
                name="trackingUrl"
                label={`${translate('fulfillmentDialog.trackingUrl')}`}
                sx={{ mt: '1em' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            {`${translate('cancel')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('fulfill')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
