import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import OrderDetailsView from 'src/sections/order/order-details-view';
import { IOrder } from '../../@types/order';
import { IUser } from '../../@types/user';
import { useAuthContext } from '../../auth/useAuthContext';
import { useLocales } from '../../locales';
import { getOrderById } from '../../redux/slices/order';
import { PATH_PAGE } from '../../routes/paths';
import { PERMISSIONS, hasPermission } from '../../utils/permissions';

export default function EcommerceOrderDetailsPage() {
  const params = useParams();
  const { translate } = useLocales();

  const { id } = params;

  const [order, setOrder] = useState<IOrder | null>(null);

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.orderList)) {
    navigate(PATH_PAGE.page403);
  }

  useEffect(() => {
    if (id) {
      const getOrder = async (id: string) => {
        const order = await getOrderById(id);

        console.log(order);
        setOrder(order);
      };

      getOrder(id as string);
    }
  }, [setOrder, id]);

  return (
    <>
      <Button
        color="inherit"
        startIcon={<Iconify width={16} icon={'eva:arrow-ios-back-fill'} />}
        sx={{
          height: 40,
          flexShrink: 0,
          borderRadius: 0,
          typography: 'overline',
          color: 'text.disabled',
          justifyContent: 'space-between',
          p: (theme) => theme.spacing(1, 1.5, 1, 2.5),
        }}
        onClick={() => navigate(-1)}
      >
        {`${translate('backward')}`}
      </Button>
      <Helmet>
        <title>
          {' '}
          {`${translate('orderDetailsPage.title')}${
            order?.external_id ? `#${order?.external_id}` : ''
          }`}{' '}
        </title>
      </Helmet>

      {order && <OrderDetailsView order={order} />}
    </>
  );
}
