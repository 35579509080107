// @mui
import { Button, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { IUser } from '../../../@types/user';
import { useAuthContext } from '../../../auth/useAuthContext';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// components
import { useSettingsContext } from '../../../components/settings';
// sections
import { useLocales } from '../../../locales';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import CustomerNewEditForm from '../../../sections/@dashboard/customer/CustomerNewEditForm';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';

// ----------------------------------------------------------------------

export default function CustomerCreatePage() {
  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.customerCreate)) {
    navigate(PATH_PAGE.page403);
  }

  return (
    <>
      <Button
        color="inherit"
        startIcon={<Iconify width={16} icon={'eva:arrow-ios-back-fill'} />}
        sx={{
          height: 40,
          flexShrink: 0,
          borderRadius: 0,
          typography: 'overline',
          color: 'text.disabled',
          justifyContent: 'space-between',
          p: (theme) => theme.spacing(1, 1.5, 1, 2.5),
        }}
        onClick={() => navigate(-1)}
      >
        {`${translate('backward')}`}
      </Button>
      <Helmet>
        <title> {`${translate('customerCreatePage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('customerCreatePage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('customersPage.navTitle')}`,
              href: PATH_DASHBOARD.customers.list,
            },
            { name: `${translate('customersPage.newCustomer')}` },
          ]}
        />
        <CustomerNewEditForm />
      </Container>
    </>
  );
}
