// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAddress } from 'src/@types/address';
import { ILocation } from 'src/@types/location';
import { IShippingLine } from 'src/@types/order';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { getLocationsRaw } from 'src/redux/slices/location';
import {
  ShippingZone,
  getShippingZones,
} from 'src/redux/slices/shipping_zones';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  products: { weight: number; weight_unit: string }[];
  shippingAddress: IAddress;
  shippingLine?: IShippingLine;
  onSelect: (shippingRate: IShippingLine | undefined) => void;
};

export default function ShippingDialog({
  open,
  onClose,
  onSelect,
  products,
  shippingAddress,
  shippingLine,
}: Props) {
  const [shippingZones, setShippingZones] = useState<ShippingZone[]>([]);
  const [collectionLocations, setCollectionLocations] = useState<ILocation[]>(
    []
  );
  const [type, setType] = useState<string>(shippingLine?.type || 'fixed_rate');
  const { translate } = useLocales();

  const shippingSchema = Yup.object().shape({
    type: Yup.string().required(
      `${translate('shippingDialog.requirements.shippingType')}`
    ),
    title: Yup.string().required(
      `${translate('shippingDialog.requirements.title')}`
    ),
    price: Yup.string().required(
      `${translate('shippingDialog.requirements.price')}`
    ),
  });

  const findShippingRate = () => {
    const totalWeight = products.reduce(
      (acc, product) =>
        acc +
        (product?.weight || 0) / (product?.weight_unit === 'kg' ? 1 : 1000),
      0
    );

    const noFoundZone = shippingZones?.find((sz) =>
      sz.shipping_countries?.find((sc) => sc.code === '*')
    );

    const matchingShippingZone = shippingZones?.find((sz) => {
      const shippingCountry = sz.shipping_countries?.find(
        (sc) =>
          sc.name.toLowerCase() === shippingAddress?.country?.toLowerCase()
      );

      return !!shippingCountry;
    });

    const shippingZone = matchingShippingZone || noFoundZone;

    const shippingRate = shippingZone?.shipping_rates?.find(
      (sr) =>
        (sr.weight_low || 0) >= totalWeight &&
        totalWeight <= (sr.weight_high || 0)
    );

    return shippingRate;
  };

  const initValues = (type: string, locations?: ILocation[]) => {
    switch (type) {
      case 'fixed_rate': {
        const shippingRate = findShippingRate();

        if (shippingRate) {
          setValue('title', shippingRate.name);
          setValue('price', Number(shippingRate.price));
          setValue('custom', true);
          setValue('handle', undefined);
        }
        break;
      }
      case 'collection': {
        const collection = locations || collectionLocations;
        const firstCollection = collection[0];
        const location = shippingLine?.handle
          ? collection.find(
              (e) => e.collection_handle === shippingLine.handle
            ) || firstCollection
          : firstCollection;
        selectCollectionStore(location);
        break;
      }
    }
  };

  useEffect(() => {
    const loadShippingZone = async () => {
      const data = await getShippingZones();

      setShippingZones(data);
    };

    const loadCollections = async () => {
      const locations = await getLocationsRaw({
        collection_handle: {
          _is_null: false,
        },
      });

      setCollectionLocations(locations);

      return locations;
    };

    const load = async () => {
      await loadCollections();
      await loadShippingZone();
    };

    load();
  }, [type]);

  useEffect(() => {
    if (shippingZones.length && collectionLocations.length) {
      initValues(type);
    }
  }, [shippingZones, collectionLocations]);

  const defaultValues = useMemo<IShippingLine>(
    () => ({
      title: shippingLine?.title || '',
      price: shippingLine?.price || 0,
      type: shippingLine?.type || 'fixed_rate',
      custom: !!shippingLine?.handle,
      handle: shippingLine?.handle,
    }),
    [shippingLine]
  );

  const methods = useForm<IShippingLine>({
    resolver: yupResolver(shippingSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = handleSubmit(async (data: IShippingLine) => {
    console.log('SUBMIT', data);
    onSelect({
      ...data,
    });
    onClose();
  });

  const selectCollectionStore = (location: ILocation) => {
    console.log('SET VALUE', location);
    setValue('collectionStore' as any, location.id);
    setValue('title', location!.name);
    setValue('price', 0);
    setValue('custom', false);
    setValue('handle', location!.collection_handle);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('shippingDialog.title')}`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <RHFSelect
                native
                name="type"
                label={`${translate('shippingDialog.shippingType')}`}
                sx={{ mt: '1em' }}
                onChange={(e) => {
                  const type = e.target.value as any;

                  setType(type);
                  setValue('type', type);
                  switch (type) {
                    case 'fixed_rate': {
                      const shippingRate = findShippingRate();

                      if (shippingRate) {
                        setValue('title', shippingRate.name);
                        setValue('price', Number(shippingRate.price));
                        setValue('custom', true);
                        setValue('handle', undefined);
                      }
                      break;
                    }
                    case 'collection': {
                      selectCollectionStore(collectionLocations[0]);
                      break;
                    }
                    default: {
                      setValue('title', '');
                      setValue('price', 0);
                      setValue('custom', true);
                      setValue('handle', undefined);
                    }
                  }
                }}
              >
                <option value="" />
                {['fixed_rate', 'collection', 'custom'].map((status) => (
                  <option key={status} value={status}>
                    {`${translate(`shippingDialog.types.${status}`)}`}
                  </option>
                ))}
              </RHFSelect>

              {type === 'fixed_rate' && (
                <>
                  <RHFTextField
                    size="small"
                    name="title"
                    disabled={true}
                    value={findShippingRate()?.name || ''}
                    label={`${translate('shippingDialog.title')}`}
                    placeholder="Colissimo..."
                    sx={{ mt: '1em' }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <RHFTextField
                    size="small"
                    name="price"
                    disabled={true}
                    value={findShippingRate()?.price || ''}
                    label={`${translate('shippingDialog.price')}`}
                    placeholder="0.00"
                    sx={{ mt: '1em' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
              {type === 'collection' && (
                <>
                  <RHFSelect
                    native
                    name="collectionStore"
                    label={`${translate('shippingDialog.collectionStore')}`}
                    sx={{ mt: '1em' }}
                    onChange={(e) => {
                      const type = e.target.value as any;

                      const location = collectionLocations.find(
                        (e) => e.id === type
                      );

                      if (location) {
                        selectCollectionStore(location!);
                      }
                    }}
                  >
                    <option value="" />
                    {collectionLocations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {`${location.name} - ${location.address?.address1} ${location.address?.city}, ${location.address?.zip}, ${location.address?.country}`}
                      </option>
                    ))}
                  </RHFSelect>
                </>
              )}
              {type === 'custom' && (
                <>
                  <RHFTextField
                    size="small"
                    name="title"
                    label={`${translate('shippingDialog.title')}`}
                    placeholder="Colissimo..."
                    sx={{ mt: '1em' }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <RHFTextField
                    size="small"
                    name="price"
                    label={`${translate('shippingDialog.price')}`}
                    placeholder="0.00"
                    sx={{ mt: '1em' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      type: 'number',
                      endAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            {`${translate('cancel')}`}
          </Button>

          {shippingLine && (
            <Button
              variant="soft"
              color="error"
              onClick={() => {
                onSelect(undefined);
                onClose();
              }}
            >
              {`${translate('delete')}`}
            </Button>
          )}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('add')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
