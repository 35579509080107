import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ILocation } from 'src/@types/location';
import Iconify from 'src/components/iconify';
import { createLocation, updateLocation } from 'src/redux/slices/location';
import * as Yup from 'yup';
import { ICustomer } from '../../../@types/customer';
import { countries } from '../../../assets/data';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from '../../../components/hook-form';
// @types
// assets
// components
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';
// utils
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  currentLocation?: ILocation;
};

export default function LocationNewEditForm({
  isEdit = false,
  currentLocation,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required(
      `${translate('locationCreatePage.requirements.name')}`
    ),
    phone: Yup.string().required(
      `${translate('locationCreatePage.requirements.phone')}`
    ),
    address: Yup.string().required(
      `${translate('locationCreatePage.requirements.address')}`
    ),
    city: Yup.string().required(
      `${translate('locationCreatePage.requirements.city')}`
    ),
    country: Yup.string().required(
      `${translate('locationCreatePage.requirements.country')}`
    ),
    province: Yup.string().required(
      `${translate('locationCreatePage.requirements.province')}`
    ),
    zip: Yup.string().required(
      `${translate('locationCreatePage.requirements.zipcode')}`
    ),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentLocation?.name || '',
      phone: currentLocation?.phone || '',
      address: currentLocation?.address?.address1 || '',
      city: currentLocation?.address?.city || '',
      country: currentLocation?.address?.country || '',
      zip: currentLocation?.address?.zip || '',
      province: currentLocation?.address?.province || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation]
  );

  const methods = useForm<ICustomer>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentLocation) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentLocation]);

  const onSubmit = async (data: any) => {
    try {
      const pushData: any = {
        id: currentLocation?.id as string,
        name: data.name,
        phone: data.phone,
        address: {
          address1: data.address,
          city: data.city,
          country: data.country,
          zip: data.zip,
          province: data.province,
          external_id: currentLocation?.address?.external_id as string,
        },
      };

      if (isEdit) {
        await updateLocation(pushData);
      } else {
        await createLocation(pushData);
      }

      reset();
      enqueueSnackbar(
        !isEdit
          ? `${translate('locationCreatePage.createSuccess')}`
          : `${translate('locationCreatePage.updateSuccess')}`
      );
      navigate(PATH_DASHBOARD.eCommerce.locations.list);
    } catch (error) {
      enqueueSnackbar(
          `${translate('locationCreatePage.error')}`
      );
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="name"
                label={`${translate('locationCreatePage.fields.name')}`}
              />

              <RHFTextField
                name="phone"
                label={`${translate('locationCreatePage.fields.phone')}`}
              />
              <RHFTextField
                name="address"
                label={`${translate('locationCreatePage.fields.address')}`}
              />

              <RHFAutocomplete
                name="country"
                label={`${translate('locationCreatePage.fields.country')}`}
                options={countries.map((country) => country.label)}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code}) +{phone}
                    </li>
                  );
                }}
              />

              <RHFTextField
                name="city"
                label={`${translate('locationCreatePage.fields.city')}`}
              />
              <RHFTextField
                name="zip"
                label={`${translate('locationCreatePage.fields.zipcode')}`}
              />
              <RHFTextField
                name="province"
                label={`${translate('locationCreatePage.fields.province')}`}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {!isEdit ? `${translate('create')}` : `${translate('update')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
