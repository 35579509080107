// @mui
import {
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
// components
import { SelectChangeEvent } from '@mui/material/Select';
import { ILocation } from 'src/@types/location';
import Iconify from '../../../components/iconify';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  filterLocation: string;
  filterStatus: string[];
  filterStock: string[];
  isFiltered: boolean;
  locationOptions: ILocation[];
  statusOptions: {
    value: string;
    label: string;
  }[];
  stockOptions: {
    value: string;
    label: string;
  }[];
  onSearch: VoidFunction;
  onResetFilter: VoidFunction;
  onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLocationChange: (event: SelectChangeEvent<string[]>) => void;
  onStatusChange: (event: SelectChangeEvent<string[]>) => void;
  onStockChange: (event: SelectChangeEvent<string[]>) => void;
};

export default function StockTableToolbar({
  filterName,
  onFilterChange,
  filterLocation,
  locationOptions,
  onLocationChange,
  onSearch,
  onResetFilter,
  isFiltered,
  onStatusChange,
  onStockChange,
  filterStatus,
  filterStock,
  stockOptions,
  statusOptions,
}: Props) {
  const { translate } = useLocales();
  return (
    <Stack
      spacing={3}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <FormControl
        sx={{
          width: { xs: 1, md: 540 },
        }}
      >
        <InputLabel
          sx={{ '&.Mui-focused': { color: 'text.primary' } }}
        >{`${translate('location')}`}</InputLabel>
        <Select
          value={[filterLocation]}
          onChange={onLocationChange}
          input={<OutlinedInput label={`${translate('location')}`} />}
        >
          {locationOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          width: { xs: 1, md: 240 },
        }}
      >
        <InputLabel
          sx={{ '&.Mui-focused': { color: 'text.primary' } }}
        >{`${translate('status')}`}</InputLabel>
        <Select
          multiple
          value={filterStatus}
          onChange={onStatusChange}
          input={<OutlinedInput label={`${translate('status')}`} />}
          renderValue={(selected) =>
            selected
              .map((value) => translate(`productsPage.status.${value}`))
              .join(', ')
          }
        >
          {statusOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                p: 0,
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              <Checkbox
                disableRipple
                size="small"
                checked={filterStatus.includes(option.value)}
              />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          width: { xs: 1, md: 240 },
        }}
      >
        <InputLabel
          sx={{ '&.Mui-focused': { color: 'text.primary' } }}
        >{`${translate('stock')}`}</InputLabel>
        <Select
          multiple
          value={filterStock}
          onChange={onStockChange}
          input={<OutlinedInput label={`${translate('stock')}`} />}
          renderValue={(selected) =>
            selected
              .map((value) => translate(`productsPage.stocks.${value}`))
              .join(', ')
          }
        >
          {stockOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                p: 0,
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              <Checkbox
                disableRipple
                size="small"
                checked={filterStock.includes(option.value)}
              />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterChange}
        onKeyUp={(key) =>
          key.code === 'Enter' || key.code === 'NumpadEnter' ? onSearch() : null
        }
        placeholder={`${translate('searchBar.search')}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          {`${translate('searchBar.clear')}`}
        </Button>
      )}

      <Button
        sx={{ flexShrink: 0 }}
        onClick={onSearch}
        startIcon={<Iconify icon="eva:search-fill" />}
      >
        {`${translate('searchBar.searchButton')}`}
      </Button>
    </Stack>
  );
}
