import { WORKERS } from '../../config-global';
import axios from '../../utils/axios';

export async function getProductsChannels() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/publications`
  );

  return response.data.data;
}

export async function getProductsChannelsTypes() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/publication-types`
  );

  return response.data.data;
}

export async function getAllProductsChannelsTypesLinks() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/publication-type-links`
  );

  return response.data.data;
}

export const createProductsChannelsTypesLink = async (data: any) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.post(
      `${WORKERS.products}/${siteId}/publication-type-links`,
      data
    )
  )?.data?.data;
};

export const deleteProductsChannelsTypesLink = async (id: string) => {
  const siteId = localStorage.getItem('siteId');

  return await axios.delete(
    `${WORKERS.products}/${siteId}/publication-type-links?id=${id}`
  );
};
