import { IApeCodes, IApeLink } from 'src/@types/ape';
import { WORKERS } from '../../config-global';
import axios from '../../utils/axios';

export const getApeCodes = async (): Promise<Array<IApeCodes>> => {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.get(`${WORKERS.products}/${siteId}/ape-codes`);

  return response.data.data;
};

export const createApeLink = async (data: IApeLink) => {
  const siteId = localStorage.getItem('siteId');

  return (await axios.post(`${WORKERS.products}/${siteId}/ape-link`, data))
    ?.data?.data;
};

export const deleteApeLink = async (apeId: string) => {
  const siteId = localStorage.getItem('siteId');

  return await axios.delete(
    `${WORKERS.products}/${siteId}/ape-link?id=${apeId}`
  );
};

export const getApeLink = async (): Promise<Array<IApeLink>> => {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.get(`${WORKERS.products}/${siteId}/ape-links`);

  return response.data.data;
};
