import IconButton from '@mui/material/IconButton';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
// utils
// components
import Label from 'src/components/label';
import { fDateTime } from 'src/utils/formatTime';
import { useLocales } from '../../locales';
// routes
import { RouterLink } from '../../routes/components';

// ----------------------------------------------------------------------

type Props = {
  status: string;
  backLink: string;
  orderNumber: string;
  createdAt: Date;
};

export default function OrderDetailsToolbar({
  backLink,
  createdAt,
  orderNumber,
  status,
}: Props) {
  const { translate } = useLocales();
  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          <IconButton component={RouterLink} href={backLink}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4">
                {' '}
                {`${translate('order')}`} #{orderNumber}{' '}
              </Typography>
              <Label
                variant="soft"
                color={
                  (status === 'fulfilled' && 'success') ||
                  (status === 'pending' && 'warning') ||
                  (status === 'cancelled' && 'error') ||
                  'default'
                }
              >
                {`${translate(`statuses.${status}`)}`}
              </Label>
            </Stack>

            <Typography variant="body2" sx={{ color: 'text.disabled' }}>
              {fDateTime(createdAt)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
