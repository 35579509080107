// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IProduct } from 'src/@types/product';
import { useAuthContext } from 'src/auth/useAuthContext';
import FormProvider, {
  RHFCheckbox,
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSelect: (data: IProduct | undefined) => void;
};

export default function CustomProductDialog({
  onClose,
  open,
  onSelect,
}: Props) {
  const { translate } = useLocales();
  const { user } = useAuthContext();

  const customProductSchema = Yup.object().shape({
    title: Yup.string().required(
      `${translate('customProductDialog.requirements.title')}`
    ),
    price: Yup.number().required(
      `${translate('customProductDialog.requirements.price')}`
    ),
    quantity: Yup.number().required(
      `${translate('customProductDialog.requirements.quantity')}`
    ),
    taxable: Yup.boolean(),
    grams: Yup.number(),
  });

  console.log('user', user);

  const defaultValues = useMemo<Partial<IProduct>>(
    () => ({
      title: '',
      price: 0,
      quantity: 1,
      taxable: true,
      vendor: user?.metadata?.editors?.[0],
      grams: 0,
    }),
    []
  );

  const methods = useForm<Partial<IProduct>>({
    resolver: yupResolver(customProductSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data: Partial<IProduct>) => {
    onSelect({
      weight: (data as any).grams,
      weight_unit: 'g',
      ...data,
    } as IProduct);
    reset();
    onClose();
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('customProductDialog.title')}`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <RHFTextField
                name="title"
                label={`${translate('customProductDialog.fields.title')}`}
                sx={{ mt: '1em' }}
              />
              <RHFTextField
                size="small"
                name="price"
                label={`${translate('customProductDialog.fields.price')}`}
                placeholder="0.00"
                sx={{ mt: '1em' }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  type: 'number',
                }}
              />
              <RHFTextField
                size="small"
                name="quantity"
                label={`${translate('customProductDialog.fields.quantity')}`}
                placeholder="0"
                sx={{ mt: '1em' }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  type: 'number',
                }}
              />

              <RHFCheckbox
                name="taxable"
                label={`${translate('customProductDialog.fields.taxable')}`}
                sx={{ mt: '1em' }}
              />

              <RHFTextField
                size="small"
                name="grams"
                label={`${translate('customProductDialog.fields.grams')}`}
                placeholder="0.00"
                sx={{ mt: '1em' }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            {`${translate('cancel')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('add')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
