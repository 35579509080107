// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IDiscount } from 'src/@types/order';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  discount: IDiscount | undefined;
  onSelect: (data: IDiscount | undefined) => void;
};

export default function DiscountDialog({
  discount,
  onClose,
  open,
  onSelect,
}: Props) {
  const { translate } = useLocales();

  const discountSchema = Yup.object().shape({
    description: Yup.string(),
    amount: Yup.number().required(
      `${translate('discountDialog.requirements.value')}`
    ),
    value_type: Yup.string().required(
      `${translate('discountDialog.requirements.discountType')}`
    ),
  });

  const defaultValues = useMemo<IDiscount>(
    () => ({
      description: discount?.description || '',
      amount:
        (discount?.value_type === 'fixed_amount'
          ? discount?.amount
          : discount?.value) || 0,
      value: discount?.value || 0,
      title: 'Custom',
      value_type: discount?.value_type || 'fixed_amount',
    }),
    [
      discount?.amount,
      discount?.description,
      discount?.value,
      discount?.value_type,
    ]
  );

  const methods = useForm<IDiscount>({
    resolver: yupResolver(discountSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data: IDiscount) => {
    onSelect({
      ...data,
      value: data.amount,
    });
    onClose();
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('discountDialog.title')}`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <RHFSelect
                native
                name="value_type"
                label={`${translate('discountDialog.discountType')}`}
                sx={{ mt: '1em' }}
              >
                <option value="" />
                {['fixed_amount', 'percentage'].map((status) => (
                  <option key={status} value={status}>
                    {`${translate(`discountDialog.types.${status}`)}`}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField
                size="small"
                name="amount"
                label={`${translate('discountDialog.value')}`}
                placeholder="0.00"
                sx={{ mt: '1em' }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  type: 'number',
                }}
              />
              <RHFTextField
                name="description"
                label={`${translate('discountDialog.description')}`}
                sx={{ mt: '1em' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            {`${translate('cancel')}`}
          </Button>

          {discount && (
            <Button
              variant="soft"
              color="error"
              onClick={() => {
                onSelect(undefined);
                onClose();
                reset({
                  amount: 0,
                  value: 0,
                  description: '',
                });
              }}
            >
              {`${translate('delete')}`}
            </Button>
          )}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate(discount ? 'update' : 'add')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
