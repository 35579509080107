// @mui
import { Box, Stack, Tooltip, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// routes
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

import { useLocales } from 'src/locales';
import LogoIcon from 'src/components/logo-icon/LogoIcon';
// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  const { translate } = useLocales();

  return (
    <LoginLayout>
      <Stack
        sx={{ backgroundColor: 'white', padding: 6, borderRadius: '16px' }}
      >
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h4">{`${translate('login')}`}</Typography>
            <LogoIcon sx={{ width: '64px', margin: '0' }} />
          </Stack>

          {/*<Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack>*/}

          {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
        </Stack>
        <AuthLoginForm />
      </Stack>
    </LoginLayout>
  );
}
