import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Box, CardActionArea, Grid, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify/Iconify';
import { Stack, padding } from '@mui/system';
import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Icon } from '@iconify/react';
import { createOdooURL } from '../../routes/odooURL';

export default function ApplicationsPage() {
  const { translate } = useLocales();

  type IconifyIcon = string;

  interface CardData {
    icon: IconifyIcon;
    title: string;
    description: string;
    url?: string;
  }

  const cardsWeb: CardData[] = [
    {
      icon: 'mdi:chat-outline', // Icône représentant une discussion en direct
      title: `${translate('upComingAppsPage.web.liveChatApp.title')}`,
      description: `${translate(
        'upComingAppsPage.web.liveChatApp.description'
      )}`,
      url: createOdooURL('live'),
    },
    // {
    //   icon: 'mdi:post-outline', // Icône représentant un article de blog ou un post
    //   title: `${translate('upComingAppsPage.web.blogApp.title')}`,
    //   description: `${translate('upComingAppsPage.web.blogApp.description')}`,
    // },
    // {
    //   icon: 'mdi:forum-outline', // Icône représentant un forum de discussion
    //   title: `${translate('upComingAppsPage.web.forumApp.title')}`,
    //   description: `${translate('upComingAppsPage.web.forumApp.description')}`,
    // },
  ];

  const cardsVente: CardData[] = [
    {
      icon: 'mdi:account-group', // Icône représentant des utilisateurs ou des clients
      title: `${translate('upComingAppsPage.sale.CRM.title')}`,
      description: `${translate('upComingAppsPage.sale.CRM.description')}`,
      url: createOdooURL('crm'),
    },
    {
      icon: 'mdi:cash-register', // Icône représentant une caisse enregistreuse ou des transactions financières
      title: `${translate('upComingAppsPage.sale.sales.title')}`,
      description: `${translate('upComingAppsPage.sale.sales.description')}`,
      url: createOdooURL('sales'),
    },
    {
      icon: 'mdi:store', // Icône représentant un magasin ou un point de vente
      title: `${translate('upComingAppsPage.sale.POS.title')}`,
      description: `${translate('upComingAppsPage.sale.POS.description')}`,
      url: createOdooURL('pos'),
    },
    {
      icon: 'mdi:credit-card-multiple', // Icône représentant des abonnements ou des paiements récurrents
      title: `${translate('upComingAppsPage.sale.subscription.title')}`,
      description: `${translate(
        'upComingAppsPage.sale.subscription.description'
      )}`,
      url: createOdooURL('subscription'),
    },
    {
      icon: 'mdi:truck-delivery', // Icône représentant des livraisons
      title: `${translate('upComingAppsPage.sale.rental.title')}`,
      description: `${translate('upComingAppsPage.sale.rental.description')}`,
      url: createOdooURL('rental'),
    },
  ];

  const cardsFinances: CardData[] = [
    {
      icon: 'mdi:book-account', // Icône représentant la comptabilité
      title: `${translate('upComingAppsPage.finance.accounting.title')}`,
      description: `${translate(
        'upComingAppsPage.finance.accounting.description'
      )}`,
      url: createOdooURL('accounting'),
    },
    // {
    //   icon: 'mdi:receipt', // Icône représentant une facture
    //   title: `${translate('upComingAppsPage.finance.facturing.title')}`,
    //   description: `${translate(
    //     'upComingAppsPage.finance.facturing.description'
    //   )}`,
    //   url: createOdooURL('facturing'),
    // },
    {
      icon: 'mdi:currency-usd', // Icône représentant de l'argent ou des transactions financières
      title: `${translate('upComingAppsPage.finance.expenses.title')}`,
      description: `${translate(
        'upComingAppsPage.finance.expenses.description'
      )}`,
      url: createOdooURL('expenses'),
    },
    {
      icon: 'mdi:folder', // Icône représentant des documents
      title: `${translate('upComingAppsPage.finance.document.title')}`,
      description: `${translate(
        'upComingAppsPage.finance.document.description'
      )}`,
      url: createOdooURL('documents'),
    },
    // {
    //   icon: 'mdi:table', // Icône représentant une feuille de calcul
    //   title: 'Feuille de calcul',
    //   description: 'Document sous feuille de calcul',
    // },
    {
      icon: 'mdi:signature', // Icône représentant une signature
      title: `${translate('upComingAppsPage.finance.sign.title')}`,
      description: `${translate(
        'upComingAppsPage.finance.sign.description'
      )}`,
      url: createOdooURL('signature'),
    },
  ];

  const cardsInvFab: CardData[] = [
    {
      icon: 'mdi:factory', // Icône représentant la fabrication ou l'industrie
      title: `${translate('upComingAppsPage.inventory.manufacturing.title')}`,
      description: `${translate(
        'upComingAppsPage.inventory.manufacturing.description'
      )}`,
      url: createOdooURL('manufacturing'),
    },
    {
      icon: 'mdi:package-variant', // Icône représentant un inventaire ou des stocks
      title: `${translate('upComingAppsPage.inventory.stock.title')}`,
      description: `${translate(
        'upComingAppsPage.inventory.stock.description'
      )}`,
      url: createOdooURL('stock'),
    },
    {
      icon: 'mdi:timeline-clock', // Icône représentant un processus ou un cycle de vie
      title: 'PLM',
      description: 'Gestion du cycle de vie d’un produit',
      url: createOdooURL('plm'),
    },
    {
      icon: 'mdi:cart-outline', // Icône représentant des achats ou des commandes
      title: `${translate('upComingAppsPage.inventory.purchase.title')}`,
      description: `${translate(
        'upComingAppsPage.inventory.purchase.description'
      )}`,
      url: createOdooURL('purchase'),
    },
    {
      icon: 'mdi:wrench', // Icône représentant la maintenance ou la réparation
      title: `${translate('upComingAppsPage.inventory.maintenance.title')}`,
      description: `${translate(
        'upComingAppsPage.inventory.maintenance.description'
      )}`,
      url: createOdooURL('maintenance'),
    },
    {
      icon: 'mdi:check-circle-outline', // Icône représentant le contrôle qualité
      title: `${translate('upComingAppsPage.inventory.quality.title')}`,
      description: `${translate(
        'upComingAppsPage.inventory.quality.description'
      )}`,
      url: createOdooURL('quality'),
    },
  ];

  const cardsRH: CardData[] = [
    {
      icon: 'mdi:account-group', // Icône représentant un groupe d'employés
      title: `${translate('upComingAppsPage.hr.employee.title')}`,
      description: `${translate('upComingAppsPage.hr.employee.description')}`,
      url: createOdooURL('employees'),
    },
    {
      icon: 'mdi:account-search', // Icône représentant une loupe pour la recherche
      title: `${translate('upComingAppsPage.hr.recruitment.title')}`,
      description: `${translate(
        'upComingAppsPage.hr.recruitment.description'
      )}`,
      url: createOdooURL('recruitment'),
    },
    {
      icon: 'mdi:calendar-clock', // Icône représentant une horloge et un calendrier pour les congés
      title: `${translate('upComingAppsPage.hr.leaves.title')}`,
      description: `${translate('upComingAppsPage.hr.leaves.description')}`,
      url: createOdooURL('leaves'),
    },
  ];

  const cardsMarketing: CardData[] = [
    {
      icon: 'mdi:email-outline', // Icône représentant une enveloppe pour les emails
      title: `${translate('upComingAppsPage.marketing.email.title')}`,
      description: `${translate(
        'upComingAppsPage.marketing.email.description'
      )}`,
      url: createOdooURL('email'),
    },
    {
      icon: 'mdi:rocket', // Icône représentant une fusée pour l'automatisation marketing
      title: `${translate('upComingAppsPage.marketing.automation.title')}`,
      description: `${translate(
        'upComingAppsPage.marketing.automation.description'
      )}`,
      url: createOdooURL('automation'),
    },
    {
      icon: 'mdi:calendar-star', // Icône représentant un calendrier pour les événements
      title: `${translate('upComingAppsPage.marketing.event.title')}`,
      description: `${translate(
        'upComingAppsPage.marketing.event.description'
      )}`,
      url: createOdooURL('event'),
    },
    {
      icon: 'mdi:poll', // Icône représentant un sondage ou une enquête
      title: `${translate('upComingAppsPage.marketing.poll.title')}`,
      description: `${translate(
        'upComingAppsPage.marketing.poll.description'
      )}`,
      url: createOdooURL('poll'),
    },
  ];

  const cardsServices: CardData[] = [
    {
      icon: 'mdi:briefcase-outline', // Icône représentant un projet ou une affaire
      title: `${translate('upComingAppsPage.services.project.title')}`,
      description: `${translate(
        'upComingAppsPage.services.project.description'
      )}`,
      url: createOdooURL('project'),
    },
    {
      icon: 'mdi:timer', // Icône représentant une minuterie pour la feuille de temps
      title: `${translate('upComingAppsPage.services.timeSheet.title')}`,
      description: `${translate(
        'upComingAppsPage.services.timeSheet.description'
      )}`,
      url: createOdooURL('timesheet'),
    },
    {
      icon: 'mdi:hammer-screwdriver', // Icône représentant des outils pour les services sur site
      title: `${translate('upComingAppsPage.services.onSite.title')}`,
      description: `${translate(
        'upComingAppsPage.services.onSite.description'
      )}`,
      url: createOdooURL('on_site'),
    },
    {
      icon: 'mdi:account-multiple-check-outline', // Icône représentant une assistance ou un support client
      title: `${translate('upComingAppsPage.services.support.title')}`,
      description: `${translate(
        'upComingAppsPage.services.support.description'
      )}`,
      url: createOdooURL('support'),
    },
    {
      icon: 'mdi:calendar-clock', // Icône représentant une planification ou un emploi du temps
      title: `${translate('upComingAppsPage.services.schedule.title')}`,
      description: `${translate(
        'upComingAppsPage.services.schedule.description'
      )}`,
      url: createOdooURL('schedule'),
    },
    {
      icon: 'mdi:clock-time-four-outline', // Icône représentant un horaire ou un rendez-vous
      title: `${translate('upComingAppsPage.services.rendezvous.title')}`,
      description: `${translate(
        'upComingAppsPage.services.rendezvous.description'
      )}`,
      url: createOdooURL('rendezvous'),
    },
  ];

  const cardsProd: CardData[] = [
    {
      icon: 'mdi:forum-outline', // Icône représentant une discussion ou un forum
      title: `${translate('upComingAppsPage.productivity.chat.title')}`,
      description: `${translate(
        'upComingAppsPage.productivity.chat.description'
      )}`,
      url: createOdooURL('chat'),
    },
    //     {
    //       icon: 'mdi:thumb-up-outline', // Icône représentant une validation ou une approbation
    //       title: 'Validations',
    //       description: 'Gérer et valider des demandes d’approbation',
    //     },
    //     {
    //       icon: 'mdi:import', // Icône représentant une action d'importation
    //       title: 'Importations',
    //       description: 'Importez vos catalogues et les créer en un éclair',
    //     },
  ];

  return (
    <>
      <Helmet>
        <title> {`${translate('upComingAppsPage.title')}`} </title>
      </Helmet>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Typography
            variant="h3"
            paragraph
            align="center"
            sx={{ marginTop: '70px', marginBottom: '100px' }}
          >
            {`${translate('upComingAppsPage.appList')}`}
          </Typography>

          <Grid container spacing={12} sx={{ maxWidth: '1000px' }}>
            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.web.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.web.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsWeb.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.sale.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.sale.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsVente.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.finance.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.finance.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsFinances.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.inventory.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.inventory.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsInvFab.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.hr.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.hr.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsRH.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.marketing.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.marketing.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsMarketing.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.services.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.services.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsServices.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.productivity.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.productivity.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsProd.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardActionArea
                        component="a"
                        href={card.url}
                        target="_bank"
                      >
                        <CardContent
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <Icon
                              icon={card.icon}
                              width={32}
                              style={{ marginRight: 16 }}
                            />
                          </div>
                          <div>
                            <Typography variant="h6">{card.title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {card.description}
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Typography variant="h4" sx={{ marginTop: '80px' }} align="center">
            {`${translate('upComingAppsPage.footer.line1')}`} <br />
            {`${translate('upComingAppsPage.footer.line2')}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
