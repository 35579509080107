import { sign } from "crypto";
import { scheduler } from "timers/promises";

export function createOdooURL(path: string) {

    const PATH_ODOO: {[key: string]: {
        menu_id: number;
        action_id: number
    }} = {
        live: {
            menu_id: 869,
            action_id: 1324
        },
        crm: {
            menu_id: 370,
            action_id: 563
        },
        sales: {
            menu_id: 272,
            action_id: 475
        },
        pos: {
            menu_id: 440,
            action_id: 677
        },
        subscription: {
            menu_id: 536,
            action_id: 809
        },
        rental: {
            menu_id: 559,
            action_id: 833
        },
        accounting: {
            menu_id: 174,
            action_id: 276
        },
        facturing: {
            menu_id: 113,
            action_id: 242
        },
        expenses: {
            menu_id: 733,
            action_id: 1105
        },
        documents: {
            menu_id: 113,
            action_id: 242
        },
        signature: {
            menu_id: 113,
            action_id: 242
        },
        manufacturing: {
            menu_id: 113,
            action_id: 242
        },
        stock: {
            menu_id: 224,
            action_id: 433
        },
        plm: {
            menu_id: 113,
            action_id: 242
        },
        purchase: {
            menu_id: 421,
            action_id: 646
        },
        maintenance: {
            menu_id: 423,
            action_id: 661
        },
        quality: {
            menu_id: 113,
            action_id: 242
        },
        employees: {
            menu_id: 611,
            action_id: 921
        },
        recruitment: {
            menu_id: 823,
            action_id: 1263
        },
        email: {
            menu_id: 76,
            action_id: 118
        },
        automation: {
            menu_id: 76,
            action_id: 118
        },
        event: {
            menu_id: 76,
            action_id: 118
        },
        poll: {
            menu_id: 76,
            action_id: 118
        },
        leaves: {
            menu_id: 789,
            action_id: 1200
        },
        project: {
            menu_id: 494,
            action_id: 739
        },
        timesheet: {
            menu_id: 76,
            action_id: 118
        },
        on_site: {
            menu_id: 76,
            action_id: 118
        },
        support: {
            menu_id: 76,
            action_id: 118
        },
        schedule: {
            menu_id: 76,
            action_id: 118
        },
        rendezvous: {
            menu_id: 76,
            action_id: 118
        },
        chat: {
            menu_id: 76,
            action_id: 118
        },
    };

    const pathConfig = PATH_ODOO[path];

    return `https://crm.mdp-distri.com/web#menu_id=${pathConfig.menu_id}&action_id=${pathConfig.action_id}`;
    return `https://crm.mdp-distri.com/web#menu_id=${pathConfig.menu_id}&action_id=${pathConfig.action_id}`;
}
