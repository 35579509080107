import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import * as Yup from 'yup';
import { ICustomer } from '../../../@types/customer';
import { countries } from '../../../assets/data';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from '../../../components/hook-form';
// @types
// assets
// components
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';
import { createCustomer, updateCustomer } from '../../../redux/slices/customer';
// utils
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  currentCustomer?: ICustomer;
};

export default function CustomerNewEditForm({
  isEdit = false,
  currentCustomer,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required(
      `${translate('customerCreatePage.requirements.firstName')}`
    ),
    last_name: Yup.string().required(
      `${translate('customerCreatePage.requirements.lastName')}`
    ),
    email: Yup.string()
      .required(`${translate('customerCreatePage.requirements.email')}`)
      .email(`${translate('customerCreatePage.requirements.validEmail')}`),
    phone: Yup.string().required(
      `${translate('customerCreatePage.requirements.phone')}`
    ),
    address: Yup.string().required(
      `${translate('customerCreatePage.requirements.address')}`
    ),
    city: Yup.string().required(
      `${translate('customerCreatePage.requirements.city')}`
    ),
    country: Yup.string().required(
      `${translate('customerCreatePage.requirements.country')}`
    ),
    province: Yup.string().required(
      `${translate('customerCreatePage.requirements.province')}`
    ),
    zip: Yup.string().required(
      `${translate('customerCreatePage.requirements.zipcode')}`
    ),
  });
  const address =
    currentCustomer?.addresses.find((address) => address.default) ||
    currentCustomer?.addresses?.[0];

  const defaultValues = useMemo(
    () => ({
      first_name: currentCustomer?.first_name || '',
      last_name: currentCustomer?.last_name || '',
      email: currentCustomer?.email || '',
      phone: currentCustomer?.phone || '',
      address: address?.address1 || '',
      city: address?.city || '',
      country: address?.country || '',
      province: address?.province || '',
      zip: address?.zip || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCustomer]
  );

  const methods = useForm<ICustomer>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentCustomer) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentCustomer]);

  const onSubmit = async (data: any) => {
    try {
      const pushData: any = {
        id: currentCustomer?.id as string,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        addresses: [
          {
            address1: data.address,
            city: data.city,
            country: data.country,
            zip: data.zip,
            province: data.province,
            default: isEdit ? address?.default : true,
            external_id: address?.external_id as string,
          },
        ],
      };

      let error = false;
      if (isEdit) {
        await updateCustomer(pushData);
      } else {
        try {
          await createCustomer(pushData);
        } catch (_e) {
          error = true;
          enqueueSnackbar(`${translate('customerCreatePage.createFailed')}`, {
            variant: 'error',
          });
        }
      }

      if (!error) {
        reset();
        enqueueSnackbar(
          !isEdit
            ? `${translate('customerCreatePage.createSuccess')}`
            : `${translate('customerCreatePage.updateSuccess')}`
        );
        navigate(PATH_DASHBOARD.customers.list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="first_name"
                label={`${translate('customerCreatePage.fields.firstName')}`}
              />
              <RHFTextField
                name="last_name"
                label={`${translate('customerCreatePage.fields.lastName')}`}
              />

              <RHFTextField
                name="email"
                label={`${translate('customerCreatePage.fields.email')}`}
              />
              <RHFTextField
                name="phone"
                label={`${translate('customerCreatePage.fields.phone')}`}
              />
              <RHFTextField
                name="address"
                label={`${translate('customerCreatePage.fields.address')}`}
              />

              <RHFAutocomplete
                name="country"
                label={`${translate('customerCreatePage.fields.country')}`}
                options={countries.map((country) => country.label)}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => {
                  const { code, label, phone } = countries.filter(
                    (country) => country.label === option
                  )[0];

                  if (!label) {
                    return null;
                  }

                  return (
                    <li {...props} key={label}>
                      <Iconify
                        key={label}
                        icon={`circle-flags:${code.toLowerCase()}`}
                        width={28}
                        sx={{ mr: 1 }}
                      />
                      {label} ({code}) +{phone}
                    </li>
                  );
                }}
              />

              <RHFTextField
                name="city"
                label={`${translate('customerCreatePage.fields.city')}`}
              />
              <RHFTextField
                name="zip"
                label={`${translate('customerCreatePage.fields.zipcode')}`}
              />
              <RHFTextField
                name="province"
                label={`${translate('customerCreatePage.fields.province')}`}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {!isEdit ? `${translate('create')}` : `${translate('update')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
