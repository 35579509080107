// @mui
import {
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from '@mui/material';
// sections
import { useTheme } from '@mui/material/styles';
import { paramCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import OrderAnalytic from 'src/sections/@dashboard/e-commerce/OrderAnalytic';
import { IOrder, IOrdersAnalytics } from '../../@types/order';
import { IUser } from '../../@types/user';
import { useAuthContext } from '../../auth/useAuthContext';
// @types
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import { useSettingsContext } from '../../components/settings';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from '../../components/table';
import { useLocales } from '../../locales';
import {
  ordersAnalytics,
  refreshOrders,
  searchOrders,
} from '../../redux/slices/order';
// redux
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
import OrderTableRow from '../../sections/@dashboard/e-commerce/list/OrderTableRow';
import OrderTableToolbar from '../../sections/@dashboard/e-commerce/list/OrderTableToolbar';
import { PERMISSIONS, ROLES, hasPermission } from '../../utils/permissions';

function useInterval(callback: any, delay: number) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      (savedCallback as any).current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function EcommerceOrdersListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    onSelectAllRows,
    onChangeDense,
  } = useTable({
    defaultOrderBy: 'createdAt',
  });

  const theme = useTheme();
  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();

  const TABLE_HEAD = [
    { id: 'order_number', label: `${translate('order')}`, align: 'left' },
    { id: 'customer', label: `${translate('customer')}`, align: 'left' },
    { id: 'createdAt', label: `${translate('createdAt')}`, align: 'left' },
    { id: 'quantity', label: `${translate('quantity')}`, align: 'center' },
    {
      id: 'status',
      label: `${translate('status')}`,
      align: 'center',
      width: 180,
    },
    { id: 'paid', label: `${translate('paid')}`, align: 'center', width: 180 },
    { id: 'amount', label: `${translate('amount')}`, align: 'right' },
    { id: 'shipping', label: `${translate('shipping')}`, align: 'center' },
  ];

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useAuthContext();

  if (!hasPermission(user as IUser, PERMISSIONS.orderList)) {
    navigate(PATH_PAGE.page403);
  }

  const { orders, isLoading, pagination } = useSelector((state) => state.order);

  const [tableData, setTableData] = useState<IOrder[]>([]);

  const [currentQuery, setCurrentQuery] = useState<any>({
    status: {
      _neq: 'draft',
    },
  });

  // console.log('CQ', currentQuery);

  const [filterName, setFilterName] = useState('');

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const [creationDate, setCreationDate] = useState<number>(+new Date());

  const [analytics, setAnalytics] = useState<IOrdersAnalytics | undefined>(
    undefined
  );

  const isFiltered =
    filterStatus !== 'all' ||
    filterName !== '' ||
    !!filterStartDate ||
    !!filterEndDate;

  const TABS = [
    {
      value: 'all',
      label: `${translate('all')}`,
      color: 'info',
      count: analytics?.totalOrders || 0,
    },
    {
      value: 'fulfilled',
      label: `${translate('ordersPage.ordersHeading.fulfilled')}`,
      color: 'success',
      count: analytics?.totalOrdersFulFilled || 0,
    },
    {
      value: 'others',
      label: `${translate('ordersPage.ordersHeading.other')}`,
      color: 'warning',
      count: analytics?.totalOrdersOthers || 0,
    },
    {
      value: 'draft',
      label: `${translate('ordersPage.ordersHeading.draft')}`,
      color: 'error',
      count: analytics?.totalOrdersDraft || 0,
    },
  ] as const;

  // console.log(TABS);
  // console.log(analytics);

  const ORDER_TAGS = [
    {
      value: 'osp: En Attente Du Fournisseur',
      label: `${translate('waiting_for_supplier')}`,
    },
    {
      value: 'osp: En Cours de préparation',
      label: `${translate('in_preparation')}`,
    },
    {
      value: 'osp: Arrivage En Cours',
      label: `${translate('arrival_in_progress')}`,
    },
    {
      value: 'osp: En Attente De Regroupement',
      label: `${translate('waiting_for_regrouping')}`,
    },
    {
      value: 'osp: Précommande',
      label: `${translate('pre_order')}`,
    },
    {
      value: 'à expédier',
      label: `${translate('to_ship')}`,
    },
  ];

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      searchOrders(currentQuery, Number(event.target.value), page + 1 || 1)
    );
    setRowsPerPage(Number(event.target.value));
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(searchOrders(currentQuery, rowsPerPage, newPage + 1 || 1));
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(searchOrders(currentQuery, rowsPerPage, page + 1 || 1));
  }, [currentQuery, dispatch, page, rowsPerPage]);

  useInterval(() => {
    // console.log('PAGE REF', page);
    dispatch(refreshOrders(currentQuery, rowsPerPage, page + 1 || 1));
  }, 1000);

  useEffect(() => {
    setTableData(orders);

    const compute = async () => {
      setAnalytics(await ordersAnalytics(currentQuery));
    };

    if (!analytics) {
      compute();
    }
  }, [orders, analytics, setAnalytics, currentQuery]);

  const denseHeight = dense ? 60 : 80;

  const isNotFound =
    (!orders?.length && !!filterName) || (!isLoading && !orders?.length);

  const handleTagsFilter = (tags: string[]) => {
    setPage(0);
    setTagsFilter(tags);

    const query = currentQuery || {};

    query.tags = {
      _contains: tags,
    };

    setCurrentQuery(query);

    dispatch(searchOrders(query, rowsPerPage, page + 1 || 1));
  };

  const handleFilterName = () => {
    setPage(0);

    const query = currentQuery || {};

    query._or = [
      {
        order_number: {
          _ilike: `%${filterName}%`,
        },
      },
      {
        order_lines: {
          product: {
            title: {
              _ilike: `%${filterName}%`,
            },
          },
        },
      },
      {
        customer: {
          email: {
            _ilike: `%${filterName}%`,
          },
        },
      },
      {
        customer: {
          first_name: {
            _ilike: `%${filterName}%`,
          },
        },
      },
      {
        customer: {
          last_name: {
            _ilike: `%${filterName}%`,
          },
        },
      },
      {
        customer: {
          first_name: {
            _in: filterName.split(' '),
          },
        },
      },
      {
        customer: {
          last_name: {
            _in: filterName.split(' '),
          },
        },
      },
    ];
    setCurrentQuery(query);

    dispatch(searchOrders(query, rowsPerPage, page + 1 || 1));
  };

  const handleFilterStatus = async (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);

    const query = currentQuery || {};

    switch (newValue) {
      case 'all':
        query.status = {
          _neq: 'draft',
        };
        break;
      case 'fulfilled':
        query.status = {
          _eq: 'fulfilled',
        };
        break;
      case 'others':
        query.status = {
          _nin: ['fulfilled', 'draft'],
        };
        break;
      case 'draft':
        query.status = {
          _eq: 'draft',
        };
    }

    setCurrentQuery(query);

    dispatch(searchOrders(query, rowsPerPage, page + 1 || 1));

    setFilterStatus(newValue);
  };

  const handleDateChange = async (
    type: 'startDate' | 'endDate',
    newValue: Date | null
  ) => {
    setPage(0);

    const query = currentQuery || {};

    if (type === 'startDate') {
      const date = newValue ? new Date(newValue) : undefined;
      if (date) {
        date.setDate(date.getDate() - 1);
      }

      query.created_at = date
        ? {
            _gt: `${date?.getFullYear()}-${
              date?.getMonth() + 1
            }-${date?.getDate()}T23:59:59.999Z`,
          }
        : undefined;
    } else if (type === 'endDate') {
      const date = newValue ? new Date(newValue) : undefined;
      if (date) {
        date.setDate(date.getDate() + 1);
      }
      query.created_at = date
        ? {
            ...query.created_at,
            _lt: `${date?.getFullYear()}-${
              date?.getMonth() + 1
            }-${date?.getDate()}T00:00:00.000Z`,
          }
        : undefined;
    }

    console.log(query);
    setCurrentQuery(query);

    dispatch(searchOrders(query, rowsPerPage, page + 1 || 1));

    if (type === 'startDate') {
      setFilterStartDate(newValue);
    } else if (type === 'endDate') {
      setFilterEndDate(newValue);
    }
  };

  const handleResetFilter = () => {
    setPage(0);

    setFilterName('');
    setFilterStatus('all');
    setFilterEndDate(null);
    setFilterStartDate(null);
    setTagsFilter([]);

    const query = currentQuery || {};
    query.created_at = undefined;
    query._or = undefined;

    dispatch(searchOrders(query, rowsPerPage, page + 1 || 1));
  };

  const handleViewRow = (id: string) => {
    navigate(PATH_DASHBOARD.orders.edit(paramCase(id)));
  };

  return (
    <>
      <Helmet>
        <title> {`${translate('ordersPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('ordersPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('ecommerce')}`,
            },
            {
              name: `${translate('order')}s`,
            },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.orders.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              disabled={
                !hasPermission(user, PERMISSIONS.orderCreate) ||
                user?.role === ROLES.CLIENT.label
              }
            >
              {`${translate('ordersPage.newOrder')}`}
            </Button>
          }
        />

        <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: 'dashed' }}
                />
              }
              sx={{ py: 2 }}
            >
              <OrderAnalytic
                title={`${translate('ordersPage.ordersHeading.total')}`}
                total={analytics?.totalOrders || 0}
                percent={100}
                price={analytics?.totalPrice || 0}
                icon="ic:round-receipt"
                color={theme.palette.info.main}
              />

              <OrderAnalytic
                title={`${translate('ordersPage.ordersHeading.fulfilled')}`}
                total={analytics?.totalOrdersFulFilled || 0}
                percent={analytics?.ordersFulFilledPercent || 0}
                price={analytics?.totalPriceFulFilled || 0}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
              />

              <OrderAnalytic
                title={`${translate('ordersPage.ordersHeading.other')}`}
                total={analytics?.totalOrdersOthers || 0}
                percent={analytics?.ordersOthersPercent || 0}
                price={analytics?.totalPriceOthers || 0}
                icon="eva:clock-fill"
                color={theme.palette.warning.main}
              />

              <OrderAnalytic
                title={`${translate('ordersPage.ordersHeading.draft')}`}
                total={analytics?.totalOrdersDraft || 0}
                percent={analytics?.ordersDraftPercent || 0}
                price={analytics?.totalPriceDraft || 0}
                icon="eva:clock-fill"
                color={theme.palette.error.main}
              />
            </Stack>
          </Scrollbar>
        </Card>

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={
                  <Label color={tab.color} sx={{ mr: 1 }}>
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <Divider />

          <OrderTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterEndDate={filterEndDate}
            onFilterName={(event) => setFilterName(event.target.value)}
            onSearch={() => handleFilterName()}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            tagsFilter={tagsFilter}
            tagsOptions={ORDER_TAGS}
            onTagFilter={(tags) => handleTagsFilter(tags)}
            onFilterStartDate={(newValue) =>
              handleDateChange('startDate', newValue)
            }
            onFilterEndDate={(newValue) =>
              handleDateChange('endDate', newValue)
            }
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row.id)
                )
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {(isLoading ? [] : orders || []).map((row, index) =>
                    row ? (
                      <OrderTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                      />
                    ) : (
                      !isNotFound && (
                        <TableSkeleton
                          key={index}
                          sx={{ height: denseHeight }}
                        />
                      )
                    )
                  )}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || orders?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
