// @mui
import {
  Button,
  Card,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { deleteLocation, getLocations } from 'src/redux/slices/location';
import GenericTableToolbar from 'src/sections/@dashboard/GenericTableToolbar';
import LocationTableRow from 'src/sections/@dashboard/location/LocationTableRow';
import { IUser } from '../../../@types/user';
import { useAuthContext } from '../../../auth/useAuthContext';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { useSettingsContext } from '../../../components/settings';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// sections
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';

export default function LocationListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const { translate } = useLocales();

  const { themeStretch } = useSettingsContext();

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.locationList)) {
    navigate(PATH_PAGE.page403);
  }

  const TABLE_HEAD = [
    // { id: '' },
    { id: 'name', label: `${translate('details')}`, align: 'left' },
    {
      id: 'phone',
      label: `${translate('locationCreatePage.fields.phone')}`,
      align: 'left',
    },
    {
      id: 'address',
      label: `${translate('locationCreatePage.fields.address')}`,
      align: 'left',
    },
    { id: '' },
  ];

  // if (!hasPermission(user as IUser, PERMISSIONS.locationDelete)) {
  //   TABLE_HEAD.shift();
  // }
  if (
    !hasPermission(user as IUser, PERMISSIONS.locationEdit) &&
    !hasPermission(user as IUser, PERMISSIONS.locationDelete)
  ) {
    TABLE_HEAD.pop();
  }

  const dispatch = useDispatch();

  const { locations, isLoading, pagination } = useSelector(
    (state) => state.location
  );

  const [filterName, setFilterName] = useState('');

  const [openConfirm, setOpenConfirm] = useState(false);

  const [currentQuery, setCurrentQuery] = useState<any>({});

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      getLocations(currentQuery, Number(event.target.value), page + 1 || 1)
    );
    setRowsPerPage(Number(event.target.value));
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getLocations(currentQuery, rowsPerPage, newPage + 1 || 1));
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getLocations(currentQuery, rowsPerPage, page + 1 || 1));
  }, [dispatch]);

  const dataInPage = locations?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isFiltered = filterName !== '';

  const isNotFound = !locations?.length && !!filterName;
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = () => {
    setPage(0);

    const query = {
      _or: [
        {
          name: {
            _ilike: `%${filterName}%`,
          },
        },
        {
          phone: {
            _ilike: `%${filterName}%`,
          },
        },
      ],
    };

    console.log('qq', query);

    setCurrentQuery(query);

    dispatch(getLocations(query, rowsPerPage, page + 1 || 1));
  };

  const handleDeleteRow = async (id: string) => {
    const deletedLocation = locations.find((row) => row.id === id);
    setSelected([]);

    if (deletedLocation) {
      await deleteLocation(deletedLocation.id);
    }

    setOpenConfirm(false);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }

    const query = currentQuery || {};
    query._or = undefined;

    dispatch(getLocations(query, rowsPerPage, page + 1 || 1));
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    const deleteRows = locations.filter((row) => selectedRows.includes(row.id));
    setSelected([]);
    await Promise.all(
      deleteRows.map((location) => deleteLocation(location.id))
    );

    console.log('to delete', deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === locations.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage =
          Math.ceil((locations.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }

    const query = currentQuery || {};
    query._or = undefined;

    dispatch(getLocations(query, rowsPerPage, page + 1 || 1));
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.eCommerce.locations.edit(paramCase(id)));
  };

  const handleResetFilter = () => {
    setPage(0);
    setFilterName('');

    const query = currentQuery || {};
    query._or = undefined;

    dispatch(getLocations(query, rowsPerPage, page + 1 || 1));
  };

  return (
    <>
      <Helmet>
        <title> {`${translate('locationsPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('locationsPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('location')}s` },
            { name: `${translate('list')}` },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.eCommerce.locations.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              disabled={
                user?.role === 'CLIENT' ||
                !hasPermission(user, PERMISSIONS.locationCreate)
              }
            >
              {`${translate('locationsPage.newLocation')}`}
            </Button>
          }
        />

        <Card>
          <Divider />

          <GenericTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            onSearch={() => handleFilterName()}
            onFilterName={(event) => setFilterName(event.target.value)}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={locations?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  locations.map((row) => row.id)
                )
              }
              action={
                <Tooltip title={`${translate('delete')}`}>
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={locations?.length}
                  numSelected={selected.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      locations.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {(isLoading ? [] : locations || []).map((row) => (
                    <LocationTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                    />
                  ))}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || locations?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {`${translate('sureDelete')}`} <strong> {selected.length} </strong>{' '}
            {`${translate('sureDelete2')}`}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
