// @mui
import { Stack, Typography } from '@mui/material';
// components
import Logo from '../../components/logo';
//
import { useLocales } from 'src/locales';
import {
  StyledContent,
  StyledRoot,
  StyledSection,
  StyledSectionBg,
} from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  const { translate } = useLocales();

  return (
    <StyledRoot>
      <StyledContent sx={{ width: '600px' }}>
        <StyledSectionBg />
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
