// @mui
import { Button, Container } from '@mui/material';
// sections
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { ILocation } from 'src/@types/location';
import Iconify from 'src/components/iconify';
import { getLocationsRaw } from 'src/redux/slices/location';
import LocationNewEditForm from 'src/sections/@dashboard/location/LocationNewEditForm';
import { IUser } from '../../../@types/user';
import { useAuthContext } from '../../../auth/useAuthContext';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// components
import { useSettingsContext } from '../../../components/settings';
import { useLocales } from '../../../locales';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';

// ----------------------------------------------------------------------

export default function LocationEditPage() {
  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();

  const [currentLocation, setCurrentLocation] = useState<ILocation | undefined>(
    undefined
  );

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.locationEdit)) {
    navigate(PATH_PAGE.page403);
  }

  useEffect(() => {
    const fetchUser = async () => {
      if (!id) return;

      const locations = await getLocationsRaw({
        id: {
          _eq: id,
        },
      });
      const location = locations.find((location) => location.id === id);

      setCurrentLocation(location);
    };

    fetchUser();
  }, [id]);

  return (
    <>
      <Button
        color="inherit"
        startIcon={<Iconify width={16} icon={'eva:arrow-ios-back-fill'} />}
        sx={{
          height: 40,
          flexShrink: 0,
          borderRadius: 0,
          typography: 'overline',
          color: 'text.disabled',
          justifyContent: 'space-between',
          p: (theme) => theme.spacing(1, 1.5, 1, 2.5),
        }}
        onClick={() => navigate(-1)}
      >
        {`${translate('backward')}`}
      </Button>
      <Helmet>
        <title> {`${translate('locationsEditPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('locationsEditPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('customersPage.navTitle')}`,
              href: PATH_DASHBOARD.eCommerce.locations.list,
            },
            { name: `${currentLocation?.name}` },
          ]}
        />

        <LocationNewEditForm isEdit currentLocation={currentLocation} />
      </Container>
    </>
  );
}
