// @mui
import { Button, LinearProgress } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// sections
import type { IUser } from '../../@types/user';
import type { IProduct } from '../../@types/product';
import { useAuthContext } from '../../auth/useAuthContext';
import { fetchProductsByLocation } from 'src/redux/slices/product';
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useLocales } from '../../locales';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
import { hasPermission, PERMISSIONS } from 'src/utils/permissions';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import { LoadingButton } from '@mui/lab';

interface StockExportModalProps {
  filterLocation: string;
  query: any;
}

export function StockExportModal({
  filterLocation,
  query,
}: StockExportModalProps) {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [_data, setData] = useState<IProduct[]>([]);
  const [csvBlobUrl, setCsvBlobUrl] = useState<string | null>(null);
  const isCancelled = useRef(false);
  function setIsCancelled(value: boolean) {
    isCancelled.current = value;
  }
  const rowsPerPage = 100;
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { translate } = useLocales();

  if (!hasPermission(user as IUser, PERMISSIONS.stockList)) {
    navigate(PATH_PAGE.page403);
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    if (!loading) {
      setOpenModal(false);
    }
  };

  const handleCancel = () => {
    setIsCancelled(true);
    setLoading(false);
    setData([]);
    handleCloseModal();
  };

  const MAX_ROWS = 100000;

  const handleConfirm = async () => {
    setLoading(true);
    setIsCancelled(false);
    let allData: IProduct[] = [];
    let currentPage = 1;
    let totalPages = 1;

    try {
      while (currentPage <= totalPages && !isCancelled.current) {
        const response = await fetchProductsByLocation(
          filterLocation,
          query,
          rowsPerPage,
          currentPage
        );
        if (response.ok) {
          allData = [...allData, ...response.data];
          totalPages = response.pagination.totalPage;

          setProgress((currentPage / totalPages) * 100);
          setData(allData);

          currentPage += 1;
        } else {
          throw new Error('Failed to fetch data');
        }
      }

      if (!isCancelled.current) {
        const csvFormattedData = allData.map((item) => {
          return flattenObject(item);
        });

        const csvArrays = transformDataToStringArray(csvFormattedData);
        const csvFiles = splitDataByRows(csvArrays);

        csvFiles.forEach((csvData, index) => {
          const csvContent = generateCsv(csvData);
          const blob = new Blob([csvContent], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const fileName =
            csvFiles.length === 1
              ? 'exported_stock.csv'
              : `exported_stock_${index + 1}_${csvFiles.length}.csv`;
          downloadCsvFile(url, fileName);
        });

        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadCsvFile = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const splitDataByRows = (data: string[][]): string[][][] => {
    const chunks = [];
    const headers = data[0];
    let start = 1;

    while (start < data.length) {
      const chunk = data.slice(start, start + MAX_ROWS);
      chunks.push([headers, ...chunk]);
      start += MAX_ROWS;
    }

    return chunks;
  };

  const flattenObject = (obj: any, parentKey = '', res: any = {}) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const propName = parentKey ? `${parentKey}_${key}` : key;
        if (obj[key] === null) {
          res[propName] = '';
        } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          flattenObject(obj[key], propName, res);
        } else if (Array.isArray(obj[key])) {
          obj[key].forEach((item: any, index: any) => {
            if (typeof item === 'object') {
              flattenObject(item, `${propName}_${index}`, res);
            } else {
              res[`${propName}_${index}`] = String(item).replace(/\n/g, ' ');
            }
          });
        } else {
          res[propName] = String(obj[key]).replace(/\n/g, ' ');
        }
      }
    }

    return res;
  };

  const filterColumns = (headers: string[]): string[] => {
    return headers.filter(
      (header) =>
        header === 'title' ||
        header === 'available' ||
        header === 'variants_0_barcode' ||
        header === 'variants_0_sku'
    );
  };

  const transformDataToStringArray = (
    data: { [key: string]: any }[]
  ): string[][] => {
    const headersSet = new Set<string>();
    const flattenedData = data.map((item) => {
      const flattenedItem = flattenObject(item);
      Object.keys(flattenedItem).forEach((key) => headersSet.add(key));
      return flattenedItem;
    });

    let headers = Array.from(headersSet);

    // Filtrer les colonnes souhaitées
    headers = filterColumns(headers);

    // Renommer les colonnes
    const renamedHeaders = headers.map((header) => {
      switch (header) {
        case 'title':
          return 'Titre';
        case 'available':
          return 'Quantité';
        case 'variants_0_barcode':
          return 'Barecode';
        case 'variants_0_sku':
          return 'SKU';
        default:
          return header;
      }
    });

    const rows = flattenedData.map((item) =>
      headers.map((header) => item[header] || '')
    );

    return [renamedHeaders, ...rows];
  };

  const generateCsv = (data: string[][]) => {
    return data
      .map((row) =>
        row
          // .map((value) =>
          //   value.includes('~') || value.includes('\n') ? `"${value}"` : value
          // )
          .join('~')
      )
      .join('\n');
  };

  useEffect(() => {
    if (csvBlobUrl) {
      const a = document.createElement('a');
      a.href = csvBlobUrl;
      a.download = 'exported_stock.csv';
      a.click();

      URL.revokeObjectURL(csvBlobUrl);
      setCsvBlobUrl(null);
    }
  }, [csvBlobUrl]);

  return (
    <>
      <CustomBreadcrumbs
        heading={`${translate('stockListPage.heading')}`}
        links={[
          {
            name: `${translate('dashboardName')}`,
            href: PATH_DASHBOARD.root,
          },
          { name: `${translate('stockListPage.navTitle')}s` },
          { name: `${translate('list')}` },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:download-outline" />}
            disabled={
              user?.role === 'CLIENT' ||
              !hasPermission(user, PERMISSIONS.stockEdit)
            }
            onClick={handleOpenModal}
          >
            {translate('stockListPage.exportModalTitle').toString()}
          </Button>
        }
      />

      <ConfirmDialog
        open={openModal}
        onClose={handleCancel}
        title={`${translate('stockListPage.exportModalTitle')}`}
        content={
          <>
            {`${translate('stockListPage.exportModalDescription')}`} <br />
            {loading && (
              <LinearProgress
                sx={{ marginTop: '10px' }}
                variant="determinate"
                value={progress}
              />
            )}
          </>
        }
        action={
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirm();
            }}
          >
            {`${translate('stockListPage.export')}`}
          </LoadingButton>
        }
      />
    </>
  );
}
