// @mui
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// hooks
// _mock
// components
// assets
import { ComingSoonIllustration } from '../assets/illustrations';
import { useLocales } from '../locales';

// ----------------------------------------------------------------------

export default function ComingSoonPage() {
  const { translate } = useLocales();

  return (
    <>
      <Helmet>
        <title> {`${translate('comingSoon.title')}`} </title>
      </Helmet>

      <Typography variant="h3" paragraph>
        {`${translate('comingSoon.comingSoon')}`}
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        {`${translate('comingSoon.description')}`}
      </Typography>

      <ComingSoonIllustration sx={{ my: 10, height: 240 }} />
    </>
  );
}
