// @mui
import {
  Dialog,
  Grid,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
// @types
// components
import { IProduct } from 'src/@types/product';
import SearchNotFound from 'src/components/search-not-found';
import { useLocales } from 'src/locales';
import { searchProductsRaw } from 'src/redux/slices/product';
import Image from '../../../components/image';
// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  searchName: string;
  products: IProduct[];
  onClose: VoidFunction;
  onSelect: (customer: IProduct | undefined) => void;
};

export default function ProductsListDialog({
  open,
  onClose,
  searchName,
  products,
  onSelect,
}: Props) {
  const { translate } = useLocales();

  const isNotFound = !products?.length && !!searchName;

  const handleSelectProduct = (product: IProduct | undefined) => {
    onSelect(product);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2.5, px: 3 }}
      >
        <Typography variant="h6">
          {' '}
          {`${translate('productListDialog.title')}`}{' '}
        </Typography>
      </Stack>

      {isNotFound || !products?.length ? (
        <SearchNotFound query={searchName} sx={{ px: 3, pt: 5, pb: 10 }} />
      ) : (
        <Stack sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8, overflowX: 'hidden' }}>
          {products.map((product: IProduct) =>
            product?.variants?.map((variant) => (
              <ListItemButton
                key={product.id}
                onClick={() => handleSelectProduct(product)}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  '&.Mui-selected': {
                    bgcolor: 'action.selected',
                    '&:hover': {
                      bgcolor: 'action.selected',
                    },
                  },
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={2}>
                    <Image
                      disabledEffect
                      visibleByDefault
                      alt={product.title}
                      src={product?.images?.[0]?.src}
                      sx={{ borderRadius: 1.5, width: 48, height: 48 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Typography variant="subtitle2">{product.title}</Typography>
                    {variant.title !== 'Default Title' && (
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          my: 0.5,
                          color: 'info.main',
                          fontWeight: 'fontWeightMedium',
                        }}
                      >
                        {`${translate('productListDialog.variantTitle')}: ${
                          variant.title
                        }`}
                      </Typography>
                    )}

                    {variant.sku?.length > 0 && (
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          my: 0.5,
                          color: 'info.main',
                          fontWeight: 'fontWeightMedium',
                        }}
                      >
                        {`Sku: ${variant.sku}`}
                      </Typography>
                    )}
                    {variant.barcode?.length > 0 && (
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          my: 0.5,
                          color: 'info.main',
                          fontWeight: 'fontWeightMedium',
                        }}
                      >
                        {`${translate('productListDialog.barCode')}: ${
                          variant.barcode
                        }`}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{
                        my: 0.5,
                        color: 'info.main',
                        fontWeight: 'fontWeightMedium',
                      }}
                    >
                      {`${translate('quantity')}: ${
                        variant.inventory_quantity
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            ))
          )}
        </Stack>
      )}
    </Dialog>
  );
}
