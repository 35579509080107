// @mui
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// @types
// components
import { useLocales } from 'src/locales';
import * as Yup from 'yup';
// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  data: number;
  onSelect: (data: IRefundLine | undefined) => void;
};

export type IRefundLine = {
  cancel_amount: number | undefined;
};

export default function CancelDialog({ open, onClose, data, onSelect }: Props) {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const refundSchema = Yup.object().shape({
    cancel_amount: Yup.number()
      .max(data || 0, `${translate('cancelDialog.amountExceeded')}`)
      .typeError(`${translate('cancelDialog.amountRequired')}`),
  });

  const defaultValues = useMemo<IRefundLine>(
    () => ({
      cancel_amount: data,
    }),
    [data]
  );

  const methods = useForm<IRefundLine>({
    resolver: yupResolver(refundSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (formData: IRefundLine) => {
    // console.log(formData);
    try {
      onSelect({
        cancel_amount: formData.cancel_amount,
      });
      enqueueSnackbar(`${translate('cancelDialog.cancelSent')}`);
      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate('cancelDialog.title')}`}</DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pt: 2.5, px: 3 }}
          >
            {/* <Typography variant="h6">{`${translate(
            'deleteDialog.dialog'
          )}`}</Typography> */}

            <RHFTextField
              type="number"
              name="cancel_amount"
              placeholder="0.00"
              label={`${translate('cancelDialog.amount')}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="soft" onClick={() => onClose()}>
            {`${translate('cancel')}`}
          </Button>
          {/* <Button variant="soft" color="error">
          {`${translate('refundDialog.refund')}`}
        </Button> */}
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('cancelDialog.save')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
