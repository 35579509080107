// @mui
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  IconButton,
  Modal,
  Stack,
  TableBody,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
// _mock_
// components
import Iconify from 'src/components/iconify/Iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from 'src/components/table';
// sections
// routes
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { PERMISSIONS, ROLES, hasPermission } from 'src/utils/permissions';

import {
  Button,
  Container,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/useAuthContext';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';
import {
  createApeLink,
  deleteApeLink,
  getApeCodes,
  getApeLink,
} from 'src/redux/slices/ape';
import { IApeCodes, IApeLink } from 'src/@types/ape';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import { IUser } from 'src/@types/user';
import { getProductsChannelsTypes } from 'src/redux/slices/publications';

export default function ApeLinkPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openAddApeCode, setOpenAddApeCode] = React.useState(false);
  const handleOpenAddApeCode = () => setOpenAddApeCode(true);
  const handleCloseAddApeCode = () => setOpenAddApeCode(false);

  const [indexSelectedRow, setIndexSelectedRow] = useState<number>();
  const [publicationLink, setPublicationLink] = useState<
    Array<{ id: string; name: string }>
  >([]);
  // console.log('publicationLink', publicationLink);

  const handleOpenChangePublicationLink = (index: number) => {
    setIndexSelectedRow(index);
    handleOpenPublicationLink();
  };

  // console.log(indexSelectedRow);

  const [openAddPublicationLink, setOpenPublicationLink] =
    React.useState(false);
  const handleOpenPublicationLink = () => setOpenPublicationLink(true);
  const handleClosePublicationLink = () => {
    setPublicationLink([]);
    setIndexSelectedRow(undefined);
    setOpenPublicationLink(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const [publicationsTypeList, setPublicationsTypeList] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const [apeLink, setApeLink] = useState<IApeLink[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [apeCodesFullList, setApeCodesFullList] = useState<IApeCodes[]>([]);
  const [selectedApeCode, setSelectedApeCode] = useState<IApeCodes | null>(
    null
  );

  useEffect(() => {
    const fetchApeCodesFullList = async () => {
      setApeCodesFullList(await getApeCodes());
    };

    const fetchPublicationList = async () => {
      setPublicationsTypeList(await getProductsChannelsTypes());
    };

    const fetchApeLinkList = async () => {
      setApeLink(await getApeLink());
    };

    fetchApeLinkList();
    fetchPublicationList();
    fetchApeCodesFullList();
  }, []);

  // console.log(apeLink);

  const TABLE_HEAD = [
    {
      id: 'apeCode',
      label: `${translate('apeLinkPage.fields.apeCode')}`,
      align: 'left',
    },
    {
      id: 'publications',
      label: `${translate('apeLinkPage.fields.publications')}`,
      align: 'left',
    },
    {
      id: 'delete',
      align: 'right',
    },
  ];

  const { companies, pagination } = useSelector((state) => state.company);

  const isNotFound = !companies?.length;

  const handleOpenConfirm = (index: number) => {
    setIndexSelectedRow(index);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // dispatch(getCompaniesSlice(currentQuery, rowsPerPage, newPage + 1 || 1));
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(
    //   getCompaniesSlice(currentQuery, Number(event.target.value), page + 1 || 1)
    // );
    setRowsPerPage(Number(event.target.value));
  };

  const navigate = useNavigate();

  if (ROLES.ADMIN.label !== user?.role) {
    navigate(PATH_PAGE.page403);
  }

  const handleAddApeCode = () => {
    if (selectedApeCode) {
      const isDuplicate = apeLink.some(
        (link) => link.apeCode === selectedApeCode.id
      );

      if (!isDuplicate) {
        const newApeLink: IApeLink = {
          apeCode: selectedApeCode.id,
          publications: [],
        };

        setApeLink((prevApeLink) => [...prevApeLink, newApeLink]);
        handleCloseAddApeCode();
        setIsEdit(true);
      } else {
        alert('Ce code APE est déjà présent.');
      }
    }
  };

  const handlePublicationChange = () => {
    if (typeof indexSelectedRow !== 'undefined') {
      const updatedApeLink = [...apeLink];

      const publicationIds = publicationLink.map((item) => item.id);

      updatedApeLink[indexSelectedRow] = {
        ...updatedApeLink[indexSelectedRow],
        publications: publicationIds,
      };

      setApeLink(updatedApeLink);
    }

    setIndexSelectedRow(undefined);
    setPublicationLink([]);
    handleClosePublicationLink();
    setIsEdit(true);
  };

  const getPublicationNames = (publicationIds: string[]): string => {
    const names = publicationIds.map(
      (id) =>
        publicationsTypeList.find((pub) => pub.id === String(id))?.name ??
        'Unknown'
    );

    return names.join(', ');
  };

  useEffect(() => {
    if (indexSelectedRow !== undefined) {
      const selectedPublicationsIds =
        apeLink[indexSelectedRow]?.publications.map(String) || [];
      const selectedPublications = publicationsTypeList.filter((publication) =>
        selectedPublicationsIds.includes(publication.id)
      );
      setPublicationLink(selectedPublications);
    }
  }, [indexSelectedRow, apeLink]);

  const onSubmit = async (data: any) => {
    try {
      const pushData = data.map((item: any) => ({
        ape: item.apeCode,
        publications: item.publications,
      }));

      // console.log(pushData);

      await createApeLink(pushData);

      enqueueSnackbar(`${translate('companyCreatePage.updateSuccess')}`);

      window.location.reload();
      // navigate(PATH_DASHBOARD.companies.list);
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async (apeId: string) => {
    try {
      await deleteApeLink(apeId);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleDeleteApe = async () => {
    if (indexSelectedRow !== undefined && apeLink[indexSelectedRow]) {
      const deletedId = apeLink[indexSelectedRow].id;
      if (deletedId) {
        const deleteSuccess = await onDelete(deletedId);
        if (deleteSuccess) {
          setApeLink((prevApeLink) =>
            prevApeLink.filter((_, i) => i !== indexSelectedRow)
          );
          setIndexSelectedRow(undefined);
          setOpenConfirm(false);
        }
      } else {
        setApeLink((prevApeLink) =>
          prevApeLink.filter((_, i) => i !== indexSelectedRow)
        );
        setIndexSelectedRow(undefined);
        setOpenConfirm(false);
      }
    }
  };

  //   console.log('apeLink', apeLink);
  //   console.log('selectedApeCode', selectedApeCode);

  return (
    <>
      <Helmet>
        <title> {`${translate('upComingAppsPage.title')}`} </title>
      </Helmet>

      {/* <Button variant="contained">Ajouter un code APE</Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>Code APE</TableCell>
            <TableCell></TableCell>
            <TableCell>Méthode de vente</TableCell>
          </TableHead>

          <TableRow>
            <TableCell>8752Z - Vente de marchandise</TableCell>
            <TableCell>{`->`}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </Table>
      </TableContainer> */}

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('apeLinkPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('apeLinkPage.breadcrumbs')}` },
            { name: `${translate('list')}` },
          ]}
          action={
            <Button
              onClick={handleOpenAddApeCode}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {`${translate('apeLinkPage.addButton')}`}
            </Button>
          }
        />

        <Card>
          {/* <Divider /> */}

          {/* <GenericTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            onSearch={() => handleFilterName()}
            onFilterName={(event) => setFilterName(event.target.value)}
            onResetFilter={handleResetFilter}
          /> */}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            {/* <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={companies?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  companies.map((row) => row.id)
                )
              }
              action={
                <Tooltip title={`${translate('delete')}`}>
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            /> */}

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={apeLink.length}
                  numSelected={selected.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      companies.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {apeLink.length > 0 ? (
                    apeLink.map((link, index) => (
                      <TableRow
                        key={index}
                        sx={{ position: 'relative', zIndex: '999' }}
                      >
                        <TableCell>{link.apeCode}</TableCell>
                        <TableCell>
                          {getPublicationNames(link.publications)}
                          {hasPermission(user, ROLES.ADMIN) && (
                            <Button
                              sx={{ ml: '6px' }}
                              onClick={() => {
                                handleOpenChangePublicationLink(index);
                              }}
                            >
                              {link.publications.length >= 1
                                ? `${translate('apeLinkPage.edit')}`
                                : `${translate('apeLinkPage.add')}`}
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="right">
                          {hasPermission(user, ROLES.ADMIN) || !link.id ? (
                            <IconButton
                              color="error"
                              onClick={() => {
                                handleOpenConfirm(index);
                              }}
                            >
                              <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableNoData isNotFound={isNotFound} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || apeLink?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
        {hasPermission(user, ROLES.ADMIN) && (
          <Stack sx={{ marginTop: '10px' }}>
            <Button
              disabled={!isEdit}
              variant="contained"
              onClick={() => {
                onSubmit(apeLink);
              }}
            >
              {`${translate('apeLinkPage.save')}`}
            </Button>
          </Stack>
        )}
      </Container>

      <Modal
        open={openAddApeCode}
        onClose={handleCloseAddApeCode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4">{`${translate(
              'apeLinkPage.addModalTitle'
            )}`}</Typography>

            <Autocomplete
              disablePortal
              id="ape-code"
              options={apeCodesFullList}
              getOptionLabel={(option) => `${option.id} - ${option.label}`}
              onChange={(_event, newValue) => {
                setSelectedApeCode(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={`${translate('apeCode')}`} />
              )}
            />

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button onClick={handleCloseAddApeCode} size="large">
                {`${translate('apeLinkPage.cancel')}`}
              </Button>
              <Button
                onClick={handleAddApeCode}
                variant="contained"
                size="large"
              >
                {`${translate('apeLinkPage.add')}`}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openAddPublicationLink}
        onClose={handleClosePublicationLink}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4">{`${translate(
              'apeLinkPage.linkModalTitle'
            )}`}</Typography>

            <Autocomplete
              disablePortal
              id="publication-link"
              multiple
              options={publicationsTypeList}
              value={publicationLink}
              onChange={(_event, newValue) => {
                setPublicationLink(newValue);
              }}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${translate('apeLinkPage.fields.publications')}`}
                />
              )}
            />

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button onClick={handleClosePublicationLink} size="large">
                {`${translate('apeLinkPage.cancel')}`}
              </Button>
              <Button
                onClick={handlePublicationChange}
                variant="contained"
                size="large"
              >
                {`${translate('apeLinkPage.confirm')}`}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('apeLinkPage.deleteMessage')}`}
        action={
          <Button variant="contained" color="error" onClick={handleDeleteApe}>
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
