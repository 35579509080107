// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPaymentTerm } from 'src/@types/order';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { PaymentTerms, getPaymentTerms } from 'src/redux/slices/payment_terms';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

type PaymentTermForm = {
  payment_terms_template_id: string;
  issued_at: string;
  due_at: string;
};

type Props = {
  open: boolean;
  currentPaymentTerm: IPaymentTerm | undefined;
  onClose: VoidFunction;
  onSelect: (data: IPaymentTerm | undefined) => void;
};

export default function DelayedPaymentDialog({
  currentPaymentTerm,
  onClose,
  open,
  onSelect,
}: Props) {
  const { translate } = useLocales();
  const [paymentTerms, setPaymentTerms] = useState<PaymentTerms[]>(
    undefined as any
  );
  const [paymentTerm, setPaymentTerm] = useState<PaymentTerms>(
    undefined as any
  );

  const customProductSchema = Yup.object().shape({
    payment_terms_template_id: Yup.string(),
    due_at: Yup.string(),
    issued_at: Yup.string(),
  });

  useEffect(() => {
    const loadPaymentTerms = async () => {
      const data = await getPaymentTerms();

      setPaymentTerms(data);
    };

    if (currentPaymentTerm && paymentTerms?.length) {
      const paymentTerm = paymentTerms.find(
        (paymentTerm) =>
          paymentTerm.name === currentPaymentTerm.payment_terms_name
      );

      console.log('found PAYMENT TERM', paymentTerm);
      if (!paymentTerm) return;

      setPaymentTerm(paymentTerm);
      setValue('payment_terms_template_id', paymentTerm.external_id);
    }

    if (!paymentTerms?.length) loadPaymentTerms();
  }, [open]);

  const defaultValues = useMemo<PaymentTermForm>(
    () => ({
      payment_terms_template_id:
        currentPaymentTerm?.payment_terms_template_id || '',
      issued_at: (currentPaymentTerm?.payment_schedule?.issued_at
        ? new Date(currentPaymentTerm?.payment_schedule?.issued_at)
        : new Date()
      )
        .toISOString()
        .split('T')[0],
      due_at: (currentPaymentTerm?.payment_schedule?.due_at
        ? new Date(currentPaymentTerm?.payment_schedule?.due_at)
        : new Date()
      )
        .toISOString()
        .split('T')[0],
    }),
    []
  );

  const methods = useForm<PaymentTermForm>({
    resolver: yupResolver(customProductSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = methods;

  const onSubmit = handleSubmit(async (data: PaymentTermForm) => {
    if (!paymentTerm) return;
    const dueAt =
      paymentTerm.type === 'net'
        ? new Date(data.issued_at)
        : new Date(data.due_at);

    if (paymentTerm.type === 'net') {
      dueAt.setDate(dueAt.getDate() + paymentTerm.due_in_days);
    }

    onSelect({
      due_in_days: paymentTerm.due_in_days,
      payment_schedule: {
        due_at: dueAt.toISOString().split('T')[0],
        issued_at: data.issued_at.split('T')[0],
      },
      payment_terms_name: paymentTerm.name,
      payment_terms_type: paymentTerm.type,
      payment_terms_template_id: paymentTerm.external_id,
    });
    reset();
    onClose();
  });

  return (
    paymentTerms && (
      <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>{`${translate(
            'delayedPaymentDialog.title'
          )}`}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <RHFSelect
                  native
                  name="payment_terms_template_id"
                  label={`${translate('delayedPaymentDialog.paymentTerm')}`}
                  sx={{ mt: '1em' }}
                  onChange={(e) => {
                    const type = e.target.value as any;

                    const paymentTerm = paymentTerms.find(
                      (paymentTerm) => paymentTerm.external_id === type
                    );

                    setPaymentTerm(paymentTerm!);
                    setValue('payment_terms_template_id', type);

                    if (paymentTerm?.type === 'net') {
                      setValue(
                        'issued_at',
                        new Date().toISOString().split('T')[0]
                      );
                    } else if (paymentTerm?.type === 'fixed') {
                      setValue(
                        'due_at',
                        new Date().toISOString().split('T')[0]
                      );
                    }
                  }}
                >
                  <option value="" />
                  {paymentTerms.map((paymentTerm) => (
                    <option
                      key={paymentTerm.id}
                      value={paymentTerm.external_id}
                    >
                      {paymentTerm.type === 'receipt' &&
                        `${translate('delayedPaymentDialog.receipt')}`}
                      {paymentTerm.type === 'net' &&
                        `${translate('delayedPaymentDialog.net')} ${
                          paymentTerm.due_in_days
                        } ${translate('delayedPaymentDialog.days')}`}
                      {paymentTerm.type === 'fulfillment' &&
                        `${translate('delayedPaymentDialog.fulfillment')}`}
                      {paymentTerm.type === 'fixed' &&
                        `${translate('delayedPaymentDialog.fixed')}`}
                    </option>
                  ))}
                </RHFSelect>
                {paymentTerm?.type === 'net' && (
                  <RHFTextField
                    size="small"
                    name="issued_at"
                    label={`${translate('delayedPaymentDialog.issued_at')}`}
                    placeholder="0"
                    sx={{ mt: '1em' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      type: 'date',
                    }}
                  />
                )}
                {paymentTerm?.type === 'fixed' && (
                  <RHFTextField
                    size="small"
                    name="due_at"
                    label={`${translate('delayedPaymentDialog.due_at')}`}
                    placeholder="0"
                    sx={{ mt: '1em' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      type: 'date',
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              {`${translate('cancel')}`}
            </Button>
            {paymentTerm && (
              <Button
                variant="soft"
                color="error"
                onClick={() => {
                  onSelect(undefined);
                  onClose();
                }}
              >
                {`${translate('delete')}`}
              </Button>
            )}

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {`${translate('add')}`}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    )
  );
}
