// @mui
import { alpha, styled } from '@mui/material/styles';
// utils
import { bgGradient } from '../../utils/cssStyles';

// ----------------------------------------------------------------------

export const StyledSectionBgDash = styled('div')(({ theme }) => ({
    ...bgGradient({
      color: alpha(
        theme.palette.background.default,
        theme.palette.mode === 'light' ? 0.7 : 0.7
      ),
      imgUrl: '/assets/background/background_mdp.jpg',
    }),
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    transform: 'scaleX(-1)',
  }));