import { WORKERS } from 'src/config-global';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
export type ShippingRate = {
  id?: number;
  name: string;
  price: string;
  shipping_zone_id?: number;
  weight_low?: number;
  weight_high?: number;
  handle?: string;
};

export type ShippingZone = {
  id: number;
  name: string;
  shipping_countries: [
    {
      id: number;
      name: string;
      tax: number;
      code: string;
      shipping_zone_id: number;
    },
  ];
  shipping_rates: ShippingRate[];
};

export const getShippingZones = async () => {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.get(
    `${WORKERS.orders}/${siteId}/shipping_zones`
  );

  return response.data.data as ShippingZone[];
};
