// @mui
import {
  Autocomplete,
  Box,
  Card,
  Modal,
  Stack,
  TableBody,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
// _mock_
// components
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from 'src/components/table';
// sections
// routes
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { ROLES } from 'src/utils/permissions';

import {
  Button,
  Container,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/useAuthContext';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'src/redux/store';
import { getProductsChannels } from 'src/redux/slices/product';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import {
  createProductsChannelsTypesLink,
  deleteProductsChannelsTypesLink,
  getAllProductsChannelsTypesLinks,
  getProductsChannelsTypes,
} from 'src/redux/slices/publications';
import { useNavigate } from 'react-router';

export type IPublicationsLink = {
  publicationTypeId: string;
  publicationExternalId: string;
  id?: string;
};
export type IPublicationsType = {
  id: string;
  name: string;
};

export type IPublications = {
  publicationId: string;
  publicationName: string;
};

export default function PublicationsLinkPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [indexSelectedRow, setIndexSelectedRow] = useState<number>();
  // console.log('publicationLink', publicationLink);

  const handleOpenChangePublicationLink = (index: number) => {
    setIndexSelectedRow(index);
    handleOpenPublicationLink();
  };

  // console.log(indexSelectedRow);

  const [openAddPublicationLink, setOpenPublicationLink] =
    React.useState(false);
  const handleOpenPublicationLink = () => setOpenPublicationLink(true);
  const handleClosePublicationLink = () => setOpenPublicationLink(false);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [publicationsTypeList, setPublicationsTypeList] = useState<
    Array<IPublicationsType>
  >([]);
  const [publicationsList, setPublicationsList] = useState<
    Array<IPublications>
  >([]);
  const [publicationTypeLink, setPublicationTypeLink] = useState<
    Array<IPublicationsLink>
  >([]);

  const [publicationLink, setPublicationLink] = useState<string | undefined>(
    ''
  );

  const navigate = useNavigate();

  if (ROLES.ADMIN.label !== user?.role) {
    navigate(PATH_PAGE.page403);
  }

  useEffect(() => {
    const fetchPublicationsType = async () => {
      setPublicationsTypeList(await getProductsChannelsTypes());
    };

    const fetchPublicationList = async () => {
      setPublicationsList(await getProductsChannels());
    };

    const fetchPublicationTypeLink = async () => {
      const request = await getAllProductsChannelsTypesLinks();
      setPublicationTypeLink(request);
    };

    fetchPublicationsType();
    fetchPublicationList();
    fetchPublicationTypeLink();
  }, []);

  useEffect(() => {
    if (publicationsTypeList.length > 0) {
      const existingTypeIds = publicationTypeLink.map(
        (link) => link.publicationTypeId
      );
      const missingTypeLinks = publicationsTypeList
        .filter((type) => !existingTypeIds.includes(type.id))
        .map((type) => ({
          publicationTypeId: type.id,
          publicationExternalId: '',
        }));

      if (missingTypeLinks.length > 0) {
        setPublicationTypeLink((prevLinks) => [
          ...prevLinks,
          ...missingTypeLinks,
        ]);
      }
    }
  }, [publicationsTypeList, publicationTypeLink]);

  const TABLE_HEAD = [
    {
      id: 'publicationsType',
      label: `${translate('publicationsLinkPage.fields.publicationsType')}`,
      align: 'left',
    },
    {
      id: 'publications',
      label: `${translate('publicationsLinkPage.fields.publications')}`,
      align: 'left',
    },
  ];

  const { companies, pagination } = useSelector((state) => state.company);

  const handleOpenConfirm = (index: number) => {
    setIndexSelectedRow(index);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    // dispatch(getCompaniesSlice(currentQuery, rowsPerPage, newPage + 1 || 1));
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(
    //   getCompaniesSlice(currentQuery, Number(event.target.value), page + 1 || 1)
    // );
    setRowsPerPage(Number(event.target.value));
  };

  const handleChangePublicationLink = () => {
    if (indexSelectedRow !== undefined && publicationLink !== undefined) {
      setPublicationTypeLink((prevLinks) => {
        const updatedLinks = [...prevLinks];
        const selectedLink = updatedLinks[indexSelectedRow];
        if (selectedLink) {
          updatedLinks[indexSelectedRow] = {
            ...selectedLink,
            publicationExternalId: publicationLink,
          };
        }
        return updatedLinks;
      });
      setIndexSelectedRow(undefined);
      setPublicationLink(undefined);
      handleClosePublicationLink();
      setIsEdit(true);
    }
  };

  const findPublicationTypeNameById = (id: string): string | undefined => {
    const publication = publicationsTypeList.find((pub) => pub.id === id);
    return publication ? publication.name : undefined;
  };

  const findPublicationNameById = (id: string): string | undefined => {
    const publication = publicationsList.find(
      (pub) => pub.publicationId === id
    );
    return publication ? publication.publicationName : undefined;
  };

  const onSubmit = async (data: any) => {
    try {
      const filteredData = data.filter(
        (item: IPublicationsLink) => item.publicationExternalId.trim() !== ''
      );
      const pushData = filteredData.map((item: any) => ({
        publicationTypeId: item.publicationTypeId,
        publicationExternalId: item.publicationExternalId,
        id: item.id ? item.id : undefined,
      }));

      // console.log(pushData);

      await createProductsChannelsTypesLink(pushData);

      enqueueSnackbar(`${translate('companyCreatePage.updateSuccess')}`);

      window.location.reload();
      // navigate(PATH_DASHBOARD.companies.list);
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async (publicationTypeId: string) => {
    try {
      await deleteProductsChannelsTypesLink(publicationTypeId);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleDeletePublicationLink = async () => {
    if (indexSelectedRow !== undefined) {
      const deleteId = publicationTypeLink[indexSelectedRow].id;
      if (deleteId) {
        const deletedSuccess = await onDelete(deleteId);
        if (deletedSuccess) {
          const updatedPublicationTypeLink = [...publicationTypeLink];
          updatedPublicationTypeLink[indexSelectedRow] = {
            ...updatedPublicationTypeLink[indexSelectedRow],
            publicationExternalId: '',
          };
          setPublicationTypeLink(updatedPublicationTypeLink);
        }
      } else {
        const updatedPublicationTypeLink = [...publicationTypeLink];
        updatedPublicationTypeLink[indexSelectedRow] = {
          ...updatedPublicationTypeLink[indexSelectedRow],
          publicationExternalId: '',
        };
        setPublicationTypeLink(updatedPublicationTypeLink);
      }
      setIndexSelectedRow(undefined);
      setOpenConfirm(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> {`${translate('upComingAppsPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('publicationsLinkPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('publicationsLinkPage.breadcrumbs')}` },
            { name: `${translate('list')}` },
          ]}
        />

        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={publicationTypeLink.length}
                  numSelected={selected.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      companies.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {publicationTypeLink.length > 0 ? (
                    publicationTypeLink.map((data, index) => (
                      <TableRow
                        key={index}
                        sx={{ position: 'relative', zIndex: '999' }}
                      >
                        <TableCell>
                          {findPublicationTypeNameById(data.publicationTypeId)}
                        </TableCell>
                        <TableCell>
                          {findPublicationNameById(data.publicationExternalId)}
                          {data.publicationExternalId.length <= 0 ? (
                            <Button
                              sx={{ ml: '6px' }}
                              onClick={() => {
                                handleOpenChangePublicationLink(index);
                              }}
                            >
                              {`${translate('publicationsLinkPage.add')}`}
                            </Button>
                          ) : (
                            <Button
                              sx={{ ml: '6px' }}
                              color="error"
                              onClick={() => {
                                handleOpenConfirm(index);
                              }}
                            >
                              {`${translate('publicationsLinkPage.delete')}`}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || publicationTypeLink?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
        <Stack sx={{ marginTop: '10px' }}>
          <Button
            disabled={!isEdit}
            variant="contained"
            onClick={() => {
              onSubmit(publicationTypeLink);
            }}
          >
            {`${translate('publicationsLinkPage.save')}`}
          </Button>
        </Stack>
      </Container>

      <Modal
        open={openAddPublicationLink}
        onClose={handleClosePublicationLink}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4">{`${translate(
              'publicationsLinkPage.linkModalTitle'
            )}`}</Typography>

            <Autocomplete
              disablePortal
              id="publication-link"
              options={publicationsList}
              // value={publicationTypeLink}
              onChange={(_event, newValue) => {
                setPublicationLink(newValue?.publicationId);
              }}
              getOptionLabel={(option) => option.publicationName}
              renderOption={(props, option) => (
                <li {...props} key={option.publicationId}>
                  {option.publicationName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${translate(
                    'publicationsLinkPage.fields.publications'
                  )}`}
                />
              )}
            />

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button onClick={handleClosePublicationLink} size="large">
                {`${translate('publicationsLinkPage.cancel')}`}
              </Button>
              <Button
                onClick={handleChangePublicationLink}
                variant="contained"
                size="large"
              >
                {`${translate('publicationsLinkPage.confirm')}`}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('publicationsLinkPage.deleteMessage')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePublicationLink}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
