// @mui
import { Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { IOrder } from '../../../../@types/order';
import { useAuthContext } from '../../../../auth/useAuthContext';
// @types
// components
import Label from '../../../../components/label';
import { useLocales } from '../../../../locales';
import { fCurrency } from '../../../../utils/formatNumber';
// utils
import { PERMISSIONS, hasPermission } from '../../../../utils/permissions';
import lodash from 'lodash';

// ----------------------------------------------------------------------

type Props = {
  row: IOrder;
  selected: boolean;
  onViewRow: VoidFunction;
};

export default function OrderTableRow({ row, onViewRow }: Props) {
  const { translate } = useLocales();
  const { formatDate } = useLocales();
  const {
    customer: { email, first_name, last_name },
    shipping_line,
    status,
    amount,
    discount,
    order_lines,
    created_at: createdAt,
    paid,
    order_number,
  } = row;

  const { user } = useAuthContext();

  return (
    <>
      <TableRow
        hover
        onClick={
          hasPermission(user, PERMISSIONS.orderEdit) ? onViewRow : undefined
        }
        sx={{
          cursor: hasPermission(user, PERMISSIONS.orderEdit)
            ? 'pointer'
            : 'default',
        }}
      >
        <TableCell>{order_number || 'N/A'}</TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <div>
              <Typography variant="subtitle2" noWrap>
                {first_name} {last_name}
              </Typography>
              {email}
            </div>
          </Stack>
        </TableCell>

        <TableCell>{formatDate(createdAt)}</TableCell>

        <TableCell align="center">
          {lodash.sumBy(order_lines, 'quantity')}
        </TableCell>

        <TableCell align="center">
          <Label
            variant="soft"
            color={
              status === 'cancelled'
                ? 'error'
                : status === 'pending'
                ? 'warning'
                : 'success'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {`${translate(`statuses.${status}`)}`}
          </Label>
        </TableCell>

        <TableCell align="center">
          <Label
            variant="soft"
            color={!paid ? 'error' : 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {`${translate(paid ? 'paid' : 'unpaid')}`}
          </Label>
        </TableCell>

        <TableCell align="right">
          <Tooltip
            title={`
              ${row.taxes ? `Taxes: ${fCurrency(row.taxes)}` : ''}
              ${row.discount ? `Discount: ${fCurrency(row.discount)}` : ''}
          `}
          >
            <Label variant="soft">
              {fCurrency(
                amount === 0 && !discount
                  ? 0
                  : amount === 0 && discount
                  ? -discount
                  : amount
              )}
            </Label>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          {shipping_line?.type === 'collection'
            ? `${translate('shippingDialog.types.collection')}`
            : shipping_line?.title}
        </TableCell>
      </TableRow>
    </>
  );
}
