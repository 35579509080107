// @mui
import {
  Button,
  Dialog,
  InputAdornment,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
// @types
// components
import { ICustomer } from 'src/@types/order';
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';
import { useLocales } from 'src/locales';
import { createFilterNameQuery } from 'src/pages/dashboard/customers/CustomerListPage';
import { searchCustomersRaw } from 'src/redux/slices/customer';
import CustomerQuickEditForm from '../customer/CustomerQuickEditForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSelect: (customer: ICustomer | undefined) => void;
};

export default function CustomerListDialog({ open, onClose, onSelect }: Props) {
  const [searchName, setSearchName] = useState('');
  const [openCreateCustomer, setOpenCreateCustomer] = useState(false);
  const [customers, setCustomers] = useState<ICustomer[]>(undefined as any);

  const { translate } = useLocales();

  const isNotFound = !customers?.length && !!searchName;

  const loadClients = async (filter: string) => {
    const data = (
      await searchCustomersRaw(createFilterNameQuery(filter), 10, 1)
    )?.data;

    setCustomers(data);
  };

  const handleSearchCustomer = (name?: string) => {
    return loadClients(name || searchName);
  };

  const handleSelectCustomer = (customer: ICustomer | undefined) => {
    onSelect(customer);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2.5, px: 3 }}
      >
        <Typography variant="h6">
          {' '}
          {`${translate('customerListDialog.select')}`}{' '}
        </Typography>

        <Button
          size="small"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ alignSelf: 'flex-end' }}
          onClick={() => setOpenCreateCustomer(true)}
        >
          {`${translate('customerListDialog.addNew')}`}
        </Button>
        <CustomerQuickEditForm
          open={openCreateCustomer}
          onClose={(customer: ICustomer) => {
            setOpenCreateCustomer(false);

            if (customer) {
              handleSelectCustomer(customer);
            }
          }}
        />
      </Stack>

      <Stack sx={{ p: 2.5 }}>
        <TextField
          value={searchName}
          placeholder={`${translate('searchBar.search')}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchName(event.target.value);

            handleSearchCustomer(event.target.value);
          }}
          onKeyUp={(key) =>
            key.code === 'Enter' || key.code === 'NumpadEnter'
              ? handleSearchCustomer()
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {isNotFound || !customers?.length ? (
        <SearchNotFound query={searchName} sx={{ px: 3, pt: 5, pb: 10 }} />
      ) : (
        <Stack sx={{ p: 1.5, pt: 0, maxHeight: 80 * 8, overflowX: 'hidden' }}>
          {customers.map((customer: any) => (
            <ListItemButton
              key={customer.id}
              onClick={() => handleSelectCustomer(customer)}
              sx={{
                p: 1.5,
                borderRadius: 1,
                flexDirection: 'column',
                alignItems: 'flex-start',
                '&.Mui-selected': {
                  bgcolor: 'action.selected',
                  '&:hover': {
                    bgcolor: 'action.selected',
                  },
                },
              }}
            >
              <Typography variant="subtitle2">
                {customer.first_name} {customer.last_name}
              </Typography>

              <Typography
                variant="caption"
                component="div"
                sx={{
                  my: 0.5,
                  color: 'info.main',
                  fontWeight: 'fontWeightMedium',
                }}
              >
                {customer.email}
              </Typography>
            </ListItemButton>
          ))}
        </Stack>
      )}
    </Dialog>
  );
}
