import Container from '@mui/material/Container';
// @mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { useSettingsContext } from 'src/components/settings';
import { IOrder } from '../../@types/order';
import { PATH_DASHBOARD } from '../../routes/paths';
//
import OrderDetailsInfo from './order-details-info';
import OrderDetailsItems from './order-details-item';
import OrderDetailsToolbar from './order-details-toolbar';

// ----------------------------------------------------------------------

export default function OrderDetailsView({ order }: { order: IOrder }) {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <OrderDetailsToolbar
        backLink={PATH_DASHBOARD.orders.ordersList}
        orderNumber={order.external_id}
        createdAt={new Date(order.created_at)}
        status={order.status}
      />

      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <Stack spacing={3} direction={{ xs: 'column-reverse', md: 'column' }}>
            <OrderDetailsItems
              items={order.order_lines}
              taxes={order.taxes}
              discount={order.discount}
              subTotal={
                order.order_lines?.reduce(
                  (acc, item) => acc + item.amount,
                  0
                ) || 0
              }
              totalAmount={order.amount || 0}
            />
          </Stack>
        </Grid>

        <Grid xs={12} md={4}>
          <OrderDetailsInfo order={order} />
        </Grid>
      </Grid>
    </Container>
  );
}
