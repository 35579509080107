import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ICompany } from 'src/@types/company';
import { createCompany, updateCompany } from 'src/redux/slices/company';
import * as Yup from 'yup';
import { ICustomer } from '../../../@types/customer';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from '../../../components/hook-form';
// @types
// assets
// components
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';
// utils
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { getApeLink } from 'src/redux/slices/ape';

// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  currentCompany?: ICompany;
};

export default function CompanyNewEditForm({
  isEdit = false,
  currentCompany,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [apeLink, setApeLink] = useState<
    Array<{ id: string; apeCode: string }>
  >([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getApeLink();
        const formattedData = data.map((item: any) => ({
          id: item.id,
          apeCode: item.apeCode,
        }));
        setApeLink(formattedData);
      } catch (error) {
        console.error('Error fetching Ape Codes:', error);
      }
    }

    fetchData();
  }, []);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required(
      `${translate('companyCreatePage.requirements.name')}`
    ),
    email: Yup.string().required(
      `${translate('companyCreatePage.requirements.email')}`
    ),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentCompany?.name || '',
      email: currentCompany?.email || '',
      siret: currentCompany?.siret || '',
      tva: currentCompany?.tva || '',
      rcs: currentCompany?.rcs || '',
      ape_link: currentCompany?.apeLink?.apeCode || '',
    }),
    [currentCompany]
  );

  const methods = useForm<ICustomer>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentCompany) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentCompany]);

  const onSubmit = async (data: any) => {
    try {
      const pushData: any = {
        id: currentCompany?.id as string,
        name: data.name,
        email: data.email,
        tva: data.tva,
        siret: data.siret,
        rcs: data.rcs,
        ape_link: data.ape_link ? data.ape_link.value : null,
      };

      if (isEdit) {
        await updateCompany(pushData);
      } else {
        await createCompany(pushData);
      }

      reset();
      enqueueSnackbar(
        !isEdit
          ? `${translate('companyCreatePage.createSuccess')}`
          : `${translate('companyCreatePage.updateSuccess')}`
      );
      navigate(PATH_DASHBOARD.companies.list);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="name"
                label={`${translate('companyCreatePage.fields.name')}`}
              />

              <RHFTextField
                name="email"
                label={`${translate('companyCreatePage.fields.email')}`}
              />
              <RHFTextField
                name="siret"
                label={`${translate('companyCreatePage.fields.siret')}`}
              />
              <RHFTextField
                name="tva"
                label={`${translate('companyCreatePage.fields.tva')}`}
              />

              <RHFTextField
                name="rcs"
                label={`${translate('companyCreatePage.fields.rcs')}`}
              />
              <RHFAutocomplete
                name="ape_link"
                label={`${translate('companyCreatePage.fields.apeLink')}`}
                placeholder={`+ ${translate(
                  'companyCreatePage.fields.apeLink'
                )}`}
                freeSolo
                options={apeLink.map((link) => ({
                  label: link.apeCode,
                  value: link.apeCode,
                }))}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {!isEdit ? `${translate('create')}` : `${translate('update')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
