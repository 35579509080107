import { format, formatDistanceToNow, getTime } from 'date-fns';
import { fr } from 'date-fns/locale';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

// Function for useLocales formatDate
export function onlyDate(date: InputValue, dateFormat: string, lang: string) {
  const fm = dateFormat ;
  return date ? format(new Date(date), fm, lang === 'fr' ? { locale: fr } : undefined ) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy HH:mm:ss';

  return date ? format(new Date(date), fm) : '';
}

// Function for useLocales formatDateTime
export function dateTime(date: InputValue, dateFormat: string, timeFormat: string, lang: string) {
  const fm = dateFormat + " " + timeFormat
  return date ? format(new Date(date), fm, lang === 'fr' ? { locale: fr } : undefined ) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
