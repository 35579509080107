// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
// @types
// components
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onDelete: VoidFunction;
};

export default function DeleteDialog({ open, onClose, onDelete }: Props) {
  const { translate } = useLocales();

  const onDeleteOrder = () => {
    onClose();
    onDelete();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{`${translate('deleteDialog.title')}`}</DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pt: 2.5, px: 3 }}
        >
          <Typography variant="h6">{`${translate(
            'deleteDialog.dialog'
          )}`}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="soft" onClick={() => onClose()}>
          {`${translate('cancel')}`}
        </Button>
        <Button variant="soft" color="error" onClick={() => onDeleteOrder()}>
          {`${translate('delete')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
