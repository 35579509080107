import Avatar from '@mui/material/Avatar';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
// components
import Scrollbar from 'src/components/scrollbar';
import { ILine } from '../../@types/order';
import { useLocales } from '../../locales';
import { fCurrency } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

type Props = {
  taxes: number;
  discount: number;
  subTotal: number;
  totalAmount: number;
  items: ILine[];
};

export default function OrderDetailsItems({
  items,
  discount,
  taxes,
  subTotal,
  totalAmount,
}: Props) {
  const { translate } = useLocales();

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ my: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>{`${translate(
          'orderDetailsPage.subtotal'
        )}`}</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>
          {fCurrency(subTotal) || '-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>{`${translate('discount')}`}</Box>
        <Box
          sx={{
            width: 160,
            ...(discount && { color: 'error.main' }),
          }}
        >
          {discount ? `- ${fCurrency(discount)}` : '-'}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>{`${translate('taxes')}`}</Box>
        <Box sx={{ width: 160 }}>{taxes ? fCurrency(taxes) : '-'}</Box>
      </Stack>

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>{`${translate('total')}`}</Box>
        <Box sx={{ width: 160 }}>{fCurrency(totalAmount) || '-'}</Box>
      </Stack>
    </Stack>
  );

  return (
    <Card>
      <CardHeader title={`${translate('details')}`} />

      <Stack
        sx={{
          px: 3,
        }}
      >
        <Scrollbar>
          {items.map((item) => (
            <Stack
              key={item.id}
              direction="row"
              alignItems="center"
              sx={{
                py: 3,
                minWidth: 640,
                borderBottom: (theme) =>
                  `dashed 2px ${theme.palette.background.neutral}`,
              }}
            >
              <Avatar
                src={item?.product?.image?.src || ''}
                variant="rounded"
                sx={{ width: 48, height: 48, mr: 2 }}
              />

              <ListItemText
                primary={item?.product?.title || item.product_external_id}
                secondary={
                  item?.product?.variants?.[0]?.sku || item.product_external_id
                }
                primaryTypographyProps={{
                  typography: 'body2',
                }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                  mt: 0.5,
                }}
              />

              <Box sx={{ typography: 'body2' }}>x{item.quantity}</Box>

              <Box
                sx={{ width: 110, textAlign: 'right', typography: 'subtitle2' }}
              >
                {fCurrency(item.amount)}
              </Box>
            </Stack>
          ))}
        </Scrollbar>

        {renderTotal}
      </Stack>
    </Card>
  );
}
