// @mui
import { Button, Container } from '@mui/material';
// sections
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrder } from 'src/@types/order';
import Iconify from 'src/components/iconify';
import { SkeletonProductDetails } from 'src/components/skeleton';
import { getOrderById } from 'src/redux/slices/order';
import { OrderNewEditForm } from 'src/sections/@dashboard/order/OrderNewEditForm';
import { IUser } from '../../../@types/user';
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import { useLocales } from '../../../locales';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';

// ----------------------------------------------------------------------

export default function OrderEditPage() {
  const { translate } = useLocales();
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();

  const [currentOrder, setCurrentOrder] = useState<IOrder | undefined>(
    undefined
  );
  const [loading, setLoader] = useState<boolean>(false);

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.orderEdit)) {
    navigate(PATH_PAGE.page403);
  }

  useEffect(() => {
    const fetchOrder = async () => {
      if (!id) return;

      const order = await getOrderById(id);

      setCurrentOrder(order);
      setLoader(false);

      if (!order) {
        navigate(PATH_DASHBOARD.orders.ordersList);
      }
    };

    setLoader(true);

    fetchOrder();
  }, [id]);

  return (
    <>
      <Button
        color="inherit"
        startIcon={<Iconify width={16} icon={'eva:arrow-ios-back-fill'} />}
        sx={{
          height: 40,
          flexShrink: 0,
          borderRadius: 0,
          typography: 'overline',
          color: 'text.disabled',
          justifyContent: 'space-between',
          p: (theme) => theme.spacing(1, 1.5, 1, 2.5),
        }}
        onClick={() => navigate(-1)}
      >
        {`${translate('backward')}`}
      </Button>
      <Helmet>
        <title> {`${translate('ordersPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('orderCreatePage.headingEdit')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('order')}s`,
              href: PATH_DASHBOARD.orders.ordersList,
            },
            {
              name: `${currentOrder?.order_number}`,
            },
          ]}
        />

        {currentOrder && (
          <OrderNewEditForm isEdit currentOrder={currentOrder} />
        )}

        {loading && <SkeletonProductDetails />}
      </Container>
    </>
  );
}
