// utils

// ----------------------------------------------------------------------

import { Dispatch, createSlice } from '@reduxjs/toolkit';
import {
  IAvailableFulfillment,
  IOrderInvoiceArgs,
  IOrderState,
} from '../../@types/order';
import { WORKERS } from '../../config-global';
import axios from '../../utils/axios';

const initialState: IOrderState = {
  isLoading: false,
  error: null,
  orders: [],
  order: null,
  pagination: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.pagination = action.payload.pagination;
      state.orders = action.payload.data;
    },

    refreshOrders(state, action) {
      state.isLoading = false;
      state.pagination = action.payload.pagination;
      state.orders = action.payload.data;
    },

    // GET PRODUCT
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
  },
});

export default slice.reducer;

export function getOrders(limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const siteId = localStorage.getItem('siteId');
      const response = await axios.get(`${WORKERS.orders}/${siteId}/orders`, {
        params: { limit, page },
      });

      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getOrderById(id: string) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(`${WORKERS.orders}/${siteId}/search`, {
    id: {
      _eq: id,
    },
  });

  return response.data.data[0];
}

export async function searchOrdersRaw(query: any, limit = 100, page = 1) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.orders}/${siteId}/search`,
    query,
    {
      params: { limit, page },
    }
  );

  return response.data;
}

export function searchOrders(query: any, limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(
        slice.actions.getOrdersSuccess(
          await searchOrdersRaw(query, limit, page)
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refreshOrders(query: any, limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(
      slice.actions.refreshOrders(await searchOrdersRaw(query, limit, page))
    );
  };
}

export async function createOrder(order: any) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(`${WORKERS.orders}/${siteId}/order`, order);

  return response.data.data;
}

export async function updateOrder(orderId: string, order: any) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.put(
    `${WORKERS.orders}/${siteId}/order?id=${orderId}`,
    order
  );

  return response.data.data;
}

export async function sendInvoice(orderId: string, args: IOrderInvoiceArgs) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.orders}/${siteId}/invoice?id=${orderId}`,
    args
  );

  return response.data.data;
}

export async function fulfillOrder(
  fulfillOrderId: number,
  args: {
    fulfillmentOrderId: number;
    trackingNumber: string;
    trackingUrl: string;
    lines: {
      id: string;
      quantity: number;
      lineItemId: string;
    }[];
  }
) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.orders}/${siteId}/fulfillment?id=${fulfillOrderId}`,
    args
  );
  return response.data.data;
}

export async function cancelFulfillment(fulfillOrderId: number) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.delete(
    `${WORKERS.orders}/${siteId}/fulfillment_item?id=${fulfillOrderId}`,
    {}
  );

  return response.data.data;
}

export async function ordersAnalytics(query: any) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.orders}/${siteId}/analytics`,
    query
  );

  return response.data.data;
}

export async function getReturnables(orderId: string) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.orders}/${siteId}/return?external_id=${orderId}`
  );

  return response.data.data as {
    fulfillmentId: string;
    returnableFulfillmentLineItems: {
      fulfillmentLineItemId: string;
      lineItemId: string;
      quantity: number;
    }[];
  }[];
}

export async function getFulfillments(orderId: string) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.orders}/${siteId}/fulfillments?external_id=${orderId}`
  );

  return response.data.data as IAvailableFulfillment[];
}

export async function createReturn(
  orderId: string,
  fulfillmentId: string,
  lines: {
    id: string;
    quantity: number;
  }[]
) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.orders}/${siteId}/return?external_id=${orderId}&fulfillment_id=${fulfillmentId}`,
    {
      lines,
    }
  );

  return response.data.data as {
    fulfillmentId: string;
    returnableFulfillmentLineItems: {
      fulfillmentLineItemId: string;
      lineItemId: string;
      quantity: number;
    }[];
  }[];
}

export async function closeOrCancelReturn(
  returnId: string,
  status: 'close' | 'cancel' | 'reopen'
) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.orders}/${siteId}/return-state?id=${returnId}`,
    {
      status,
    }
  );

  return response.data.data;
}
