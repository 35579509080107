import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { WORKERS } from '../config-global';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from './types';
//
import { isValidToken, setSession } from './utils';
import { ISite } from 'src/@types/site';
import { getAllSites } from 'src/redux/slices/sites';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem('accessToken')
        : '';
      const siteId = storageAvailable ? localStorage.getItem('siteId') : '';
      const siteUrl = storageAvailable ? localStorage.getItem('siteUrl') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(siteId, accessToken, siteUrl);

        const response = await axios.get(`${WORKERS.login}/me`);

        const { data: user } = response.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await axios.post(`${WORKERS.login}/login`, {
        email,
        password,
      });
      const {
        data: { accessToken, user },
      } = response.data;

      console.log(user);

      if (!accessToken || !user) {
        throw new Error('Invalid login response');
      }

      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      let sites: ISite[] = [];
      try {
        console.log('Fetching sites from API...');
        sites = await getAllSites();
        console.log('Sites fetched successfully:', sites);
      } catch (error) {
        console.error('Error fetching sites:', error);
        return;
      }

      // Sélectionner le site et obtenir l'URL
      const selectedSite = sites.find((site) => site.id === user.site_ids?.[0]);
      const siteUrl = selectedSite ? selectedSite.display_url : '';
      console.log('Selected Site:', selectedSite);
      console.log('Site URL:', siteUrl);

      setSession(user.site_ids?.[0], accessToken, siteUrl);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error('Login error:', error);
      // Affichez une erreur utilisateur ou gérez l'erreur comme nécessaire
    }
  }, []);

  const update = useCallback(async (data: AuthUserType) => {
    const response = await axios.post(`${WORKERS.login}/update`, data);
    const { data: user } = response.data;

    dispatch({
      type: Types.INITIAL,
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      const response = await axios.post(`${WORKERS.login}/create`, {
        email,
        password,
        firstName,
        lastName,
      });
      const { accessToken, user } = response.data;

      localStorage.setItem('accessToken', accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null, null, null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      update,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      update,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
