// utils

// ----------------------------------------------------------------------

import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { ICustomer, ICustomerState } from '../../@types/customer';
import { WORKERS } from '../../config-global';
import axios from '../../utils/axios';

const initialState: ICustomerState = {
  isLoading: false,
  error: null,
  customers: [],
  customer: null,
  pagination: null,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.pagination = action.payload.pagination;
      state.customers = action.payload.data;
    },

    // GET PRODUCT
    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },
  },
});

export default slice.reducer;

export function getCustomers(limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const siteId = localStorage.getItem('siteId');
      const response = await axios.get(
        `${WORKERS.customers}/${siteId}/customers`,
        {
          params: { limit, page },
        }
      );

      dispatch(slice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function searchCustomersRaw(query: any, limit = 100, page = 1) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.customers}/${siteId}/search`,
    query,
    {
      params: { limit, page },
    }
  );

  return response.data;
}

export function searchCustomers(query: any, limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(
        slice.actions.getCustomersSuccess(
          await searchCustomersRaw(query, limit, page)
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updateCustomer = async (data: ICustomer) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.put(
      `${WORKERS.customers}/${siteId}/customer?id=${data.id}`,
      data
    )
  )?.data?.data;
};

export const createCustomer = async (data: ICustomer) => {
  const siteId = localStorage.getItem('siteId');

  return (await axios.post(`${WORKERS.customers}/${siteId}/customer`, data))
    ?.data?.data;
};

export const deleteCustomer = async (id: string) => {
  const siteId = localStorage.getItem('siteId');

  return (
    await axios.delete(`${WORKERS.customers}/${siteId}/customer`, {
      params: { id },
    })
  )?.data?.data;
};
