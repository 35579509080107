// @mui
import { MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ISite } from '../../../@types/site';
import { IconButtonAnimate } from '../../../components/animate';
// locales
// components
import MenuPopover from '../../../components/menu-popover';
import { getAllSites } from '../../../redux/slices/sites';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function SitePopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [currentSite, setCurrentSite] = useState<string | undefined | null>(
    localStorage.getItem('siteId')
  );

  const [sites, setSites] = useState<ISite[]>([]);

  useEffect(() => {
    const allSites = async () => {
      setSites(await getAllSites());
    };

    allSites();
  }, [setSites]);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const navigate = useNavigate();

  const handleChangeSite = (newSite: string) => {
    const selectedSite = sites.find((site) => site.id === newSite);
    if (selectedSite) {
      const baseUrl = new URL(selectedSite.display_url).origin;
      setCurrentSite(newSite);
      localStorage.setItem('siteId', newSite);
      localStorage.setItem('siteUrl', baseUrl);
      navigate(PATH_DASHBOARD.root);
      handleClosePopover();
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 50,
          height: 50,
          'font-size': '1em',
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        {sites.find((site) => site.id === currentSite)?.name || 'N/A'}
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180 }}
      >
        <Stack spacing={0.75}>
          {sites.map((site) => (
            <MenuItem
              key={site.id}
              selected={site.id === currentSite}
              onClick={() => handleChangeSite(site.id)}
            >
              {site.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
