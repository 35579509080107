import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Box, CardActionArea, Grid, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify/Iconify';
import { Stack, padding } from '@mui/system';
import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Icon } from '@iconify/react';
import { createOdooURL } from '../../routes/odooURL';

export default function ApplicationsPage() {
  const { translate } = useLocales();

  type IconifyIcon = string;

  interface CardData {
    icon: IconifyIcon;
    title: string;
    description: string;
    url?: string;
  }

  const cardsWeb: CardData[] = [
    {
      icon: 'mdi:chat-outline', // Icône représentant une discussion en direct
      title: `${translate('upComingAppsPage.web.liveChatApp.title')}`,
      description: `${translate(
        'upComingAppsPage.web.liveChatApp.description'
      )}`,
      url: createOdooURL('live'),
    },
    // {
    //   icon: 'mdi:post-outline', // Icône représentant un article de blog ou un post
    //   title: `${translate('upComingAppsPage.web.blogApp.title')}`,
    //   description: `${translate('upComingAppsPage.web.blogApp.description')}`,
    // },
    // {
    //   icon: 'mdi:forum-outline', // Icône représentant un forum de discussion
    //   title: `${translate('upComingAppsPage.web.forumApp.title')}`,
    //   description: `${translate('upComingAppsPage.web.forumApp.description')}`,
    // },
  ];

  return (
    <>
      <Helmet>
        <title> {`${translate('upComingAppsPage.title')}`} </title>
      </Helmet>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Typography
            variant="h3"
            paragraph
            align="center"
            sx={{ marginTop: '70px', marginBottom: '100px' }}
          >
            {`${translate('upComingAppsPage.appList')}`}
          </Typography>

          <Grid container spacing={12} sx={{ maxWidth: '1000px' }}>

            <Grid item>
              <Typography variant="h4" paragraph>
                {`${translate('upComingAppsPage.web.title')}`}
              </Typography>

              <Typography sx={{ marginBottom: '20px' }}>
                {`${translate('upComingAppsPage.web.description')}`}
              </Typography>

              <Grid container spacing={2}>
                {cardsWeb.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                    <CardActionArea component="a" href={card.url} target="_bank">
                            <CardContent
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <div>
                                <Icon
                                    icon={card.icon}
                                    width={32}
                                    style={{ marginRight: 16 }}
                                />
                                </div>
                                <div>
                                <Typography variant="h6">{card.title}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {card.description}
                                </Typography>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Typography variant="h4" sx={{ marginTop: '80px' }} align="center">
            {`${translate('upComingAppsPage.footer.line1')}`} <br />
            {`${translate('upComingAppsPage.footer.line2')}`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
