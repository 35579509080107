// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import { IOrder } from '../../@types/order';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  order: IOrder;
};

export default function OrderDetailsInfo({ order }: Props) {
  const { customer } = order;
  const { translate } = useLocales();

  const renderCustomer = (
    <>
      <CardHeader title={`${translate('orderDetailsPage.customerInfo')}`} />
      <Stack direction="row" sx={{ p: 3 }}>
        <Stack
          spacing={0.5}
          alignItems="flex-start"
          sx={{ typography: 'body2' }}
        >
          <Typography variant="subtitle2">
            {customer.first_name} {customer.last_name}
          </Typography>

          <Box sx={{ color: 'text.secondary' }}>{customer.email}</Box>

          <Box>
            {`${translate('phoneNumber')}`}:
            <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
              {customer.phone}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );

  const renderDelivery = (
    <>
      <CardHeader title={`${translate('orderDetailsPage.delivery')}`} />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box
            component="span"
            sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}
          >
            {`${translate('orderDetailsPage.shipBy')}:`}
          </Box>
          {order.location.name}
        </Stack>
      </Stack>
    </>
  );

  return (
    <Card>
      {renderCustomer}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderDelivery}
    </Card>
  );
}
