// @mui
import { Box, Stack } from '@mui/material';
// components
import LogoIcon from '../../../components/logo-icon';
import { NavSectionMini } from '../../../components/nav-section';
// config
import { NAV } from '../../../config-global';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
import NavToggleButton from './NavToggleButton';
//
import navConfig from './config-navigation';

// ----------------------------------------------------------------------

export default function NavMini() {
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          alignItems: 'center',
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
          bgcolor: '#ffffff80',
        }}
      >
        <LogoIcon />

        <NavSectionMini data={navConfig()} />
      </Stack>
    </Box>
  );
}
