import { Box, Link, Typography } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  return (
    <Link
      component={RouterLink}
      to={PATH_DASHBOARD.user.account}
      underline="none"
      color="inherit"
    >
      <StyledRoot>
        <CustomAvatar
          src={user?.avatarUrl}
          alt={user?.firstName}
          name={user?.firstName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.email}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
