import { forwardRef } from 'react';
//
import { CustomAvatarProps } from './types';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  ({ src }) => {
    return <img src={src} style={{ width: '45px' }} alt="" />;
  }
);

export default CustomAvatar;
