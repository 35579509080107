// @mui
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { IAddress } from 'src/@types/address';
import { ICustomer } from 'src/@types/customer';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useBoolean } from 'src/hooks/useBoolean';
import { useAuthContext } from '../../../auth/useAuthContext';
import ConfirmDialog from '../../../components/confirm-dialog';
// @types
// components
import Iconify from '../../../components/iconify';
import { useLocales } from '../../../locales';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';
import CustomerQuickEditForm from './CustomerQuickEditForm';

// ----------------------------------------------------------------------

type Props = {
  row: ICustomer;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CustomerTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { translate } = useLocales();

  const quickEdit = useBoolean();
  const popover = usePopover();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [_openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [data, setData] = useState<ICustomer & { address: IAddress }>({
    ...row,
    address:
      row.addresses.find((address) => address.default) || row.addresses[0],
  });

  const { user } = useAuthContext();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const _handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {hasPermission(user, PERMISSIONS.customerDelete) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.customerEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: hasPermission(user, PERMISSIONS.customerEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Avatar alt={data.first_name} src={''} sx={{ mr: 2 }} />

          <ListItemText
            primary={`${data.first_name} ${data.last_name}`}
            secondary={data.email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.customerEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.customerEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.address?.address1 || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.customerEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.customerEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.address?.city || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell
          onClick={
            hasPermission(user, PERMISSIONS.customerEdit)
              ? quickEdit.onTrue
              : undefined
          }
          sx={{
            cursor: hasPermission(user, PERMISSIONS.customerEdit)
              ? 'pointer'
              : 'default',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {data?.address?.country || 'N/A'}
            </Typography>
          </Stack>
        </TableCell>

        {(hasPermission(user, PERMISSIONS.customerEdit) ||
          hasPermission(user, PERMISSIONS.customerDelete)) && (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            {/* <Tooltip
              title={`${translate('customersPage.quickEdit')}`}
              placement="top"
              arrow
            >
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={quickEdit.onTrue}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip> */}

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
              sx={{ zIndex: 1300 }}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {hasPermission(user, PERMISSIONS.customerEdit) && (
        <CustomerQuickEditForm
          currentCustomer={data}
          open={quickEdit.value}
          onClose={(_data) => {
            if (!_data?.addresses) {
              quickEdit.onFalse();
              return;
            }

            setData({
              ..._data,
              address:
                _data.addresses.find((address: any) => address.default) ||
                _data.addresses[0],
            });
            quickEdit.onFalse();
          }}
        />
      )}

      {(hasPermission(user, PERMISSIONS.customerEdit) ||
        hasPermission(user, PERMISSIONS.customerDelete)) && (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          {hasPermission(user, PERMISSIONS.customerEdit) && (
            <MenuItem
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            >
              <Iconify icon="eva:edit-fill" />
              {`${translate('edit')}`}
            </MenuItem>
          )}
          {hasPermission(user, PERMISSIONS.customerDelete) && (
            <MenuItem
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="eva:trash-2-outline" />
              {`${translate('delete')}`}
            </MenuItem>
          )}
        </CustomPopover>
      )}

      {hasPermission(user, PERMISSIONS.customerDelete) && (
        <ConfirmDialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          title={`${translate('delete')}`}
          content={`${translate('sureAction')}`}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onDeleteRow();
                handleCloseConfirm();
              }}
            >
              {`${translate('delete')}`}
            </Button>
          }
        />
      )}
    </>
  );
}
