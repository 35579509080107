// @mui
import {
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { RHFMultiSelect } from 'src/components/hook-form';
// components
import Iconify from '../../../../components/iconify';
import { useLocales } from '../../../../locales';
import { ORDER_TAGS } from '../../order/OrderNewEditForm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

type Props = {
  filterName: string;
  isFiltered: boolean;
  filterEndDate: Date | null;
  onResetFilter: VoidFunction;
  filterStartDate: Date | null;
  tagsFilter: string[];
  tagsOptions: {
    value: string;
    label: string;
  }[];
  onTagFilter: (value: string[]) => void;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: VoidFunction;
  onFilterStartDate: (value: Date | null) => void;
  onFilterEndDate: (value: Date | null) => void;
};

export default function OrderTableToolbar({
  filterName,
  isFiltered,
  onFilterName,
  onSearch,
  filterEndDate,
  onResetFilter,
  filterStartDate,
  onFilterEndDate,
  onFilterStartDate,
  tagsFilter,
  tagsOptions,
  onTagFilter,
}: Props) {
  const { translate } = useLocales();

  const { currentLang } = useLocales();

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <LocalizationProvider adapterLocale={ currentLang.systemValue } dateAdapter={AdapterDateFns}>
        <DatePicker
          label={`${translate('startDate')}`}
          // inputFormat= {currentLang.inputDateFormat}
          value={filterStartDate}
          onChange={onFilterStartDate}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: `${currentLang.placeholderInputDateFormat}`
              }}
              fullWidth
              sx={{
                maxWidth: { md: INPUT_WIDTH },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <LocalizationProvider adapterLocale={currentLang.systemValue} dateAdapter={AdapterDateFns}>
        <DatePicker
          label={`${translate('endDate')}`}
          value={filterEndDate}
          onChange={onFilterEndDate}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: `${currentLang.placeholderInputDateFormat}`
              }}
              fullWidth
              sx={{
                maxWidth: { md: INPUT_WIDTH },
              }}
              
              
            />
          )}
        />
      </LocalizationProvider>

      <FormControl
        sx={{
          width: { xs: 1, md: 560 },
        }}
      >
        <InputLabel
          sx={{ '&.Mui-focused': { color: 'text.primary' } }}
        >{`${translate('tags')}`}</InputLabel>
        <Select
          multiple
          value={tagsFilter}
          onChange={(event) => onTagFilter(event.target.value as string[])}
          input={<OutlinedInput label={`${translate('tags')}`} />}
          renderValue={(selected) =>
            selected
              .map((tag) =>
              tagsOptions.find((item) => item.value === tag)?.label || tag
            )
              .join(', ')
          }
          
        >
          {tagsOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                p: 0,
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              <Checkbox
                disableRipple
                size="small"
                checked={tagsFilter.includes(option.value)}
              />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        onKeyUp={(key) =>
          key.code === 'Enter' || key.code === 'NumpadEnter' ? onSearch() : null
        }
        placeholder={`${translate('ordersPage.searchEmail')}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          {`${translate('searchBar.clear')}`}
        </Button>
      )}

      <Button
        sx={{ flexShrink: 0 }}
        onClick={onSearch}
        startIcon={<Iconify icon="eva:search-fill" />}
      >
        {`${translate('searchBar.search')}`}
      </Button>
    </Stack>
  );
}
