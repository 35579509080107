// @mui
import {
  Button,
  Card,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  deleteCustomer,
  getCustomers,
  searchCustomers,
} from 'src/redux/slices/customer';
import GenericTableToolbar from 'src/sections/@dashboard/GenericTableToolbar';
import CustomerTableRow from 'src/sections/@dashboard/customer/CustomerTableRow';
import { IUser } from '../../../@types/user';
import { useAuthContext } from '../../../auth/useAuthContext';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { useSettingsContext } from '../../../components/settings';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// sections
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
import { PERMISSIONS, hasPermission } from '../../../utils/permissions';

export const createFilterNameQuery = (filterName: string) =>
  filterName?.length
    ? {
        _or: [
          {
            email: {
              _ilike: `%${filterName}%`,
            },
          },
          {
            first_name: {
              _ilike: `%${filterName}%`,
            },
          },
          {
            last_name: {
              _ilike: `%${filterName}%`,
            },
          },
          {
            first_name: {
              _in: filterName.split(' '),
            },
          },
          {
            last_name: {
              _in: filterName.split(' '),
            },
          },
        ],
      }
    : {};

export default function CustomerListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const { translate } = useLocales();

  const { themeStretch } = useSettingsContext();

  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (!hasPermission(user as IUser, PERMISSIONS.customerList)) {
    navigate(PATH_PAGE.page403);
  }

  const TABLE_HEAD = [
    { id: '' },
    { id: 'information', label: `${translate('details')}`, align: 'left' },
    {
      id: 'address',
      label: `${translate('customerCreatePage.fields.address')}`,
      align: 'left',
    },
    {
      id: 'city',
      label: `${translate('customerCreatePage.fields.city')}`,
      align: 'left',
    },
    {
      id: 'country',
      label: `${translate('customerCreatePage.fields.country')}`,
      align: 'left',
    },
    { id: '' },
  ];

  if (!hasPermission(user as IUser, PERMISSIONS.customerDelete)) {
    TABLE_HEAD.shift();
  }
  if (
    !hasPermission(user as IUser, PERMISSIONS.customerEdit) &&
    !hasPermission(user as IUser, PERMISSIONS.customerDelete)
  ) {
    TABLE_HEAD.pop();
  }

  console.log(TABLE_HEAD);

  const dispatch = useDispatch();

  const { customers, isLoading, pagination } = useSelector(
    (state) => state.customer
  );

  const [filterName, setFilterName] = useState('');

  const [openConfirm, setOpenConfirm] = useState(false);

  const [currentQuery, setCurrentQuery] = useState<any>(undefined);

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentQuery) {
      dispatch(
        searchCustomers(currentQuery, Number(event.target.value), page + 1 || 1)
      );
    } else {
      dispatch(getCustomers(Number(event.target.value), page + 1 || 1));
    }
    setRowsPerPage(Number(event.target.value));
  };

  const onChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (currentQuery) {
      dispatch(searchCustomers(currentQuery, rowsPerPage, newPage + 1 || 1));
    } else {
      dispatch(getCustomers(rowsPerPage, newPage + 1 || 1));
    }
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getCustomers(rowsPerPage, page + 1 || 1));
  }, [dispatch]);

  const dataInPage = customers?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const _denseHeight = dense ? 52 : 72;

  const isFiltered = filterName !== '';

  const isNotFound = !customers.length && !!filterName;
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = () => {
    setPage(0);

    const query = createFilterNameQuery(filterName);

    setCurrentQuery(query);

    dispatch(searchCustomers(query, rowsPerPage, page || 1));
  };

  const handleDeleteRow = async (id: string) => {
    const deletedUser = customers.find((row) => row.id === id);
    setSelected([]);

    if (deletedUser) {
      await deleteCustomer(deletedUser.id);
    }

    setOpenConfirm(false);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }

    const query = currentQuery || {};
    query._or = undefined;

    dispatch(searchCustomers(query, rowsPerPage, page || 1));
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    const deleteRows = customers.filter((row) => selectedRows.includes(row.id));
    setSelected([]);
    await Promise.all(
      deleteRows.map((customer) => deleteCustomer(customer.id))
    );

    console.log('to delete', deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === customers.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage =
          Math.ceil((customers.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }

    const query = currentQuery || {};
    query._or = undefined;

    dispatch(searchCustomers(query, rowsPerPage, page || 1));
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.customers.edit(paramCase(id)));
  };

  const handleResetFilter = () => {
    setPage(0);
    setFilterName('');

    const query = currentQuery || {};
    query._or = undefined;

    dispatch(searchCustomers(query, rowsPerPage, page || 1));
  };

  return (
    <>
      <Helmet>
        <title> {`${translate('customersPage.title')}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${translate('customersPage.heading')}`}
          links={[
            {
              name: `${translate('dashboardName')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('customer')}s` },
            { name: `${translate('list')}` },
          ]}
          action={
            <Button
              component={RouterLink}
              to={PATH_DASHBOARD.customers.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              disabled={!hasPermission(user, PERMISSIONS.customerCreate)}
            >
              {`${translate('customersPage.newCustomer')}`}
            </Button>
          }
        />

        <Card>
          <Divider />

          <GenericTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            onSearch={() => handleFilterName()}
            onFilterName={(event) => setFilterName(event.target.value)}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={customers.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  customers.map((row) => row.id)
                )
              }
              action={
                <Tooltip title={`${translate('delete')}`}>
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={customers.length}
                  numSelected={selected.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      customers.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {(isLoading ? [] : customers || []).map((row) => (
                    <CustomerTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                    />
                  ))}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination?.total || customers?.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {`${translate('sureDelete')}`} <strong> {selected.length} </strong>{' '}
            {`${translate('sureDelete2')}`}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
