import { type Dispatch, createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import type {
  ICheckoutCartItem,
  IProduct,
  IProductState,
} from '../../@types/product';
import { WORKERS } from '../../config-global';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: IProductState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  pagination: null,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.pagination = action.payload.pagination;
      state.products = action.payload.data;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart: ICheckoutCartItem[] = action.payload;

      const totalItems = sum(cart.map((product) => product.quantity));
      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      state.checkout.cart = cart;
      state.checkout.discount = state.checkout.discount || 0;
      state.checkout.shipping = state.checkout.shipping || 0;
      state.checkout.billing = state.checkout.billing || null;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - state.checkout.discount;
      state.checkout.totalItems = totalItems;
    },

    addToCart(state, action) {
      const newProduct = action.payload;
      const isEmptyCart = !state.checkout.cart.length;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct];
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.id === newProduct.id;

          if (isExisted) {
            return {
              ...product,
              colors: uniq([...product.colors, ...newProduct.colors]),
              quantity: product.quantity + 1,
            };
          }

          return product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'id');
      state.checkout.totalItems = sum(
        state.checkout.cart.map((product) => product.quantity)
      );
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter(
        (product) => product.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.cart = [];
      state.checkout.billing = null;
      state.checkout.activeStep = 0;
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.totalItems = 0;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.checkout.activeStep = step;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;

      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addToCart, gotoStep } = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const siteId = localStorage.getItem('siteId');
      const response = await axios.get(
        `${WORKERS.products}/${siteId}/products`,
        {
          params: { limit, page },
        }
      );

      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchProducts(query: any, limit = 100, page = 1) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await searchProductsRaw(query, limit, page);

      dispatch(slice.actions.getProductsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchProductsByLocation(
  locationId: string,
  query: any,
  limit = 100,
  page = 1
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const siteId = localStorage.getItem('siteId');
      const response = await axios.post(
        `${WORKERS.products}/${siteId}/productsByLocation`,
        query,
        {
          params: { limit, page, id: locationId },
        }
      );

      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function fetchProductsByLocation(
  locationId: string,
  query: any,
  limit = 100,
  page = 1
) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.products}/${siteId}/productsByLocation`,
    query,
    {
      params: { limit, page, id: locationId },
    }
  );
  return response.data; 
}


// ----------------------------------------------------------------------

export async function searchProductsRaw(
  query: any,
  limit = 100,
  page = 1,
  heavy = false
) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.products}/${siteId}/search?heavy=${heavy}`,
    query,
    {
      params: { limit, page },
    }
  );

  return response.data;
}

export async function searchProductsByBarcode(ean: string, heavy = false) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.post(
    `${WORKERS.products}/${siteId}/search?heavy=${heavy}&limit=1&page=1`,
    { variants: { barcode: { _eq: ean } } } //{ variants: { barcode: { _in: eans } } },
  );

  return response.data;
}

export async function getProductById(id: string) {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(`${WORKERS.products}/${siteId}/product`, {
    params: { id },
  });

  return response.data.data;
}

export async function getProductTypes() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/product-types`
  );

  return response.data.data;
}

export async function createProduct(data: IProduct) {
  const siteId = localStorage.getItem('siteId');

  return await axios.post(`${WORKERS.products}/${siteId}/product`, data);
}

export async function createProductQueue(data: IProduct) {
  const siteId = localStorage.getItem('siteId');

  return await axios.post(
    `${WORKERS.products}/${siteId}/product?queue=true`,
    data
  );
}

export async function createProductViaTrigger(data: IProduct, userId: string) {
  const siteId = localStorage.getItem('siteId');

  return await axios.post(WORKERS.trigger, {
    taskId: 'products/create/multi',
    payload: {
      returnProducts: true,
      siteId,
      productDtos: [data],
      userId,
    },
  });
}

export async function getProductsInQueueViaTrigger() {
  const siteId = localStorage.getItem('siteId');

  const response = await axios.get(
		`${WORKERS.trigger}?taskId=products/create&siteId=${siteId}`,
  );

  return response.data
}

export async function updateProduct(productId: string, data: IProduct) {
  const siteId = localStorage.getItem('siteId');

  return await axios.put(
    `${WORKERS.products}/${siteId}/product?id=${productId}`,
    data
  );
}

export async function updateProductViaTrigger(data: IProduct, userId: string) {
  const siteId = localStorage.getItem('siteId');

  return await axios.post(WORKERS.trigger, {
    taskId: 'products/update/multi',
    payload: {
      siteId,
      productDtos: [data],
      userId,
    },
  });
}

export async function updateProductQueue(data: IProduct) {
  const siteId = localStorage.getItem('siteId');

  return await axios.put(
    `${WORKERS.products}/${siteId}/product?queue=true`,
    data
  );
}

export async function getProductsInQueue() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/products-in-queue`
  );
  return response.data.data;
}

export async function deleteProduct(productId: string) {
  const siteId = localStorage.getItem('siteId');

  return await axios.delete(
    `${WORKERS.products}/${siteId}/product?id=${productId}`
  );
}

export async function getProductsChannels() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/publications`
  );

  return response.data.data;
}
export async function getProductsChannelsTypes() {
  const siteId = localStorage.getItem('siteId');
  const response = await axios.get(
    `${WORKERS.products}/${siteId}/publication-types`
  );

  return response.data.data;
}
