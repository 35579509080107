import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import calendarReducer from './slices/calendar';
import chatReducer from './slices/chat';
import customerReduce from './slices/customer';
import kanbanReducer from './slices/kanban';
import locationReduce from './slices/location';
import companyReduce from './slices/company';
// slices
import mailReducer from './slices/mail';
import orderReduce from './slices/order';
import productReducer from './slices/product';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  order: persistReducer(productPersistConfig, orderReduce),
  customer: persistReducer(productPersistConfig, customerReduce),
  product: persistReducer(productPersistConfig, productReducer),
  location: persistReducer(productPersistConfig, locationReduce),
  company: persistReducer(productPersistConfig, companyReduce),
});

export default rootReducer;
