// @types
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPaymentTerm } from 'src/@types/order';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { PaymentTerms, getPaymentTerms } from 'src/redux/slices/payment_terms';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  currentDate: string | undefined;
  onClose: VoidFunction;
  onSelect: (data: string | undefined) => void;
};

export default function ReserveInventoryDialog({
  currentDate,
  onClose,
  open,
  onSelect,
}: Props) {
  const { translate } = useLocales();

  const timeSchema = Yup.object().shape({
    date: Yup.string(),
  });

  const defaultValues = useMemo<{ date: string }>(
    () => ({
      date: (currentDate ? new Date(currentDate) : new Date())
        .toISOString()
        .split('T')[0],
    }),
    [currentDate]
  );

  console.log('DR', defaultValues);

  const methods = useForm<{ date: string }>({
    resolver: yupResolver(timeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: { date: string }) => {
    onSelect(data.date);
    onClose();
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{`${translate(
          'reserveInventoryDialog.title'
        )}`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <RHFTextField
                size="small"
                name="date"
                label={`${translate('reserveInventoryDialog.date')}`}
                placeholder="0"
                sx={{ mt: '1em' }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  type: 'date',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            {`${translate('cancel')}`}
          </Button>

          {currentDate && (
            <Button
              variant="soft"
              color="error"
              onClick={() => {
                onSelect(undefined);
                onClose();
              }}
            >
              {`${translate('delete')}`}
            </Button>
          )}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('add')}`}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
