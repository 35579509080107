// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
// lightbox
import 'yet-another-react-lightbox/styles.css';

import 'mapbox-gl/dist/mapbox-gl.css';

// distributor
import 'react-quill/dist/quill.snow.css';

import 'slick-carousel/slick/slick-theme.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MotionLazyContainer } from './components/animate';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider } from './components/settings';
import SnackbarProvider from './components/snackbar';
// locales
import ThemeLocalization, { useLocales } from './locales';
// redux
import { persistor, store } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

import { AuthProvider } from './auth/JwtContext';
import axiosInstance from './utils/axios';
import { useState } from 'react';
import { useSnackbar } from './components/snackbar';

// ----------------------------------------------------------------------

const RootComponent: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorMessage = error.response?.data || 'Something went wrong';
      setError(errorMessage);
      enqueueSnackbar(`${translate('axiosError')}`, { variant: 'error' });
      return Promise.reject(error);
    }
  );
  return <div>{/* Votre contenu du RootComponent ici */}</div>;
};

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter>
                  <ScrollToTop />
                  <MotionLazyContainer>
                    <ThemeProvider>
                      <ThemeLocalization>
                        <SnackbarProvider>
                          <RootComponent />
                          <StyledChart />
                          <Router />
                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeProvider>
                  </MotionLazyContainer>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
